import { useBorrowMutation } from '@ui/hooks/mutation/useBorrowMutation';
import type { EthersError } from 'ethers';
import { useCallback } from 'react';

import { TxStatus, useSupplyAndBorrowModal } from '..';

export const useBorrow = () => {
  const borrowMutation = useBorrowMutation();
  const [amount, setTxStatus, setTxHash, setTxError, borrowTokenData] =
    useSupplyAndBorrowModal((s) => [
      s.borrowAmount,
      s.setTxStatus,
      s.setTxHash,
      s.setTxError,
      s.borrowTokenData,
    ]);
  return useCallback(async () => {
    try {
      setTxStatus(TxStatus.Loading);

      if (!amount) throw new Error('Amount is required');
      if (!borrowTokenData) throw new Error('Borrow token is required');

      const tx = await borrowMutation.mutateAsync({
        amount,
        tokenData: borrowTokenData,
      });
      setTxHash(tx?.hash ?? '');

      setTxStatus(TxStatus.Success);
    } catch (e: unknown) {
      setTxStatus(TxStatus.Failed);
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setTxError(e as EthersError);
    }
  }, [
    setTxStatus,
    amount,
    borrowMutation,
    borrowTokenData,
    setTxHash,
    setTxError,
  ]);
};

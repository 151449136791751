import { BraveWallet } from '@react-web3-wallet/brave-wallet';
import { DeFiWallet } from '@react-web3-wallet/defiwallet';
import { MetaMask } from '@react-web3-wallet/metamask';
import { WalletConnect } from '@react-web3-wallet/walletconnect';
import { getChainConfig } from './chainConfig';
import { rabbyWalletIcon } from './icons';
export const RABBY_WALLET_TITLE = 'Rabby Wallet';
const walletConnectProviderOptions = {
    projectId: process.env.WALLET_CONNECT_PROJECT_ID ?? '',
    rpcMap: { [getChainConfig().chainId]: getChainConfig().rpcUrls[0] },
    chains: [getChainConfig().chainId],
    showQrModal: true,
    optionalMethods: ['eth_signTypedData', 'eth_signTypedData_v4', 'eth_sign'],
    qrModalOptions: {
        themeMode: 'light',
        themeVariables: {
            /**
             * @see packages/chakra/src/zIndices.ts
             */
            '--wcm-z-index': '1900',
        },
    },
};
export const walletConfigs = [
    {
        title: 'MetaMask',
        name: MetaMask.walletName,
        icon: MetaMask.walletIcon,
        connector: new MetaMask({
            // p.isTrust is to enable using MetaMask to connect on DeFi Wallet
            // Brave wallet and MetaMask can't co-exist
            providerFilter: (p) => (!!p.isMetaMask || !!p.isTrust) && !p.isBraveWallet,
        }),
        getDownloadLink: () => {
            if (typeof window === 'undefined')
                return undefined;
            return 'https://metamask.io';
        },
    },
    {
        title: 'Crypto.com DeFi Wallet',
        name: DeFiWallet.walletName,
        icon: DeFiWallet.walletIcon,
        connector: new DeFiWallet(),
        getDownloadLink: (isMobile) => {
            if (isMobile)
                return 'https://crypto.com/defi-wallet';
            return 'https://chrome.google.com/webstore/detail/cryptocom-wallet-extensio/hifafgmccdpekplomjjkcfgodnhcellj';
        },
    },
    {
        title: 'Brave Wallet',
        name: BraveWallet.walletName,
        icon: BraveWallet.walletIcon,
        connector: new BraveWallet(),
        getDownloadLink: () => 'https://brave.com/wallet',
    },
    // Rabby Wallet uses the same ethereum object as MetaMask and Brave
    {
        title: RABBY_WALLET_TITLE,
        name: BraveWallet.walletName,
        icon: rabbyWalletIcon,
        connector: new BraveWallet(),
        getDownloadLink: () => 'https://rabby.io/',
    },
    {
        title: 'WalletConnect',
        name: WalletConnect.walletName,
        icon: WalletConnect.walletIcon,
        // https://web3-wallet.github.io/web3-wallet/wallets/walletconnect
        connector: new WalletConnect({
            providerOptions: walletConnectProviderOptions,
        }),
        getDownloadLink: () => undefined,
    },
];
// this function should not return undefined
export const getConfigByName = (name) => {
    // eslint-disable-next-line
    // @ts-expect-error
    return walletConfigs.find((v) => v.name === name);
};

import { CardBorder, CardLargeBorder } from '@amply-app/icons';
import type { BoxProps } from '@chakra-ui/react';
import { Box, Icon } from '@chakra-ui/react';
import type { PropsWithChildren } from 'react';
import React from 'react';

interface Props extends BoxProps {
  isError?: boolean;
  isLargeBorder?: boolean;
}

const CardWithBorder = ({
  isError,
  isLargeBorder,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <Box {...props} pos="relative">
      <Box
        pos="absolute"
        pointerEvents={'none'}
        right={'-3px'}
        top={'-2px'}
        zIndex={1}
      >
        {isLargeBorder ? (
          <Icon
            as={CardLargeBorder}
            w="103px"
            h="91px"
            color={isError ? 'primary.error' : 'primary.cta'}
          />
        ) : (
          <Icon
            as={CardBorder}
            w="103px"
            h="81px"
            color={isError ? 'primary.error' : 'primary.cta'}
          />
        )}
      </Box>
      {children}
      <Box
        pos="absolute"
        pointerEvents={'none'}
        left={'-3px'}
        bottom={'-2px'}
        transform={'rotate(180deg)'}
        zIndex={1}
      >
        {isLargeBorder ? (
          <Icon
            as={CardLargeBorder}
            w="103px"
            h="91px"
            color={isError ? 'primary.error' : 'primary.cta'}
          />
        ) : (
          <Icon
            as={CardBorder}
            w="103px"
            h="81px"
            color={isError ? 'primary.error' : 'primary.cta'}
          />
        )}
      </Box>
    </Box>
  );
};

export default CardWithBorder;

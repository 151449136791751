import type { FlexProps } from '@chakra-ui/react';
import { Flex, Skeleton, Text } from '@chakra-ui/react';
import { getFractionUnit } from '@ui/utils';
import type { FractionIsh } from 'bi-fraction';
import { Fraction } from 'bi-fraction';
import { forwardRef, useMemo } from 'react';

/**
 * Generic component for rendering number
 *
 * @component
 * @param {object} props The properties object.
 * @param {FractionIsh} props.value The FractionIsh value to display. If not provided, defaultDisplayValue will be used.
 * @param {string} props.defaultDisplayValue The value to display when the 'value' prop is not provided. Default is an empty string.
 * @param {number} props.decimalPlace The number of decimal places to format the value. Default is 2.
 * @param {string} props.groupSeparator The character used to separate groups of numbers, such as thousands from hundreds.
 * @param {React.ReactNode} props.prefix The element to display before the value.
 * @param {React.ReactNode} props.suffix The element to display after the value.
 * @param {React.Ref<HTMLDivElement>} props.ref The ref to be forwarded to the Flex component.
 * @param {boolean} props.isShowUnit The boolean to display unit.
 * @param {number} props.maxValue The max value to display.
 * @returns {React.ElementType} Returns a Flex component with the formatted number and optional prefix and suffix.
 * Wrapped in a Skeleton component which shows a loading state until the 'value' prop is not `undefined`.
 */
export type NumProps = Omit<FlexProps, 'prefix'> & {
  value?: FractionIsh;
  defaultDisplayValue?: string;
  decimalPlaces?: number;
  groupSeparator?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  isShowUnit?: boolean;
  maxValue?: number;
  ref?: React.Ref<HTMLDivElement>;
};

export const Num = forwardRef<HTMLDivElement, NumProps>(
  (
    {
      value,
      defaultDisplayValue,
      decimalPlaces = 2,
      groupSeparator = ',',
      prefix,
      suffix,
      isShowUnit,
      maxValue,
      ...props
    },
    ref,
  ) => {
    const [displayValue, unit] = useMemo(() => {
      if (value === undefined) return [defaultDisplayValue, undefined];

      const fraction = new Fraction(value);

      if (maxValue && fraction.gt(maxValue)) return [`>${maxValue}`, undefined];

      let nextFraction = fraction;
      let unit;
      if (isShowUnit) [nextFraction, unit] = getFractionUnit(fraction);

      const str = nextFraction.toFormat({
        decimalPlaces,
        trailingZeros: false,
        format: {
          groupSeparator,
        },
      });

      return [str, unit];
    }, [
      value,
      defaultDisplayValue,
      maxValue,
      isShowUnit,
      decimalPlaces,
      groupSeparator,
    ]);

    const isShowSkeleton = !!value || !!displayValue;

    return (
      <Skeleton as={props.as} isLoaded={isShowSkeleton}>
        <Flex align="center" gap="4px" {...props} ref={ref}>
          {/* need a placeholder here otherwise Skeleton won't show up when loading */}
          {!isShowSkeleton && '--'}
          {prefix}
          <Text as="span">
            {displayValue}
            {unit}
          </Text>
          {suffix}
        </Flex>
      </Skeleton>
    );
  },
);

Num.displayName = 'Num';

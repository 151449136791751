import { ExpandRight } from '@amply-app/icons';
import {
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { type UseAllPoolsData,useIncentiveAPY } from '@ui/hooks/data';
import React, { useMemo } from 'react';

import { Boosted } from '../Boosted';
import CardWithBorder from '../CardWithBorder';
import { ChakraNextLink } from '../ChakraNextLink';
import TokenImage from '../Markets/IndividualMarket/TokenImage';
import { tokenAddressMap } from '../TokenInput/constants';
import { BorrowPanel, Divider, SupplyPanel } from '.';

interface TokenCard {
  tokenData: UseAllPoolsData;
}

export const TokenCard = ({ tokenData }: TokenCard) => {
  const { data: incentiveData } = useIncentiveAPY();
  const hasIncentiveData = useMemo(() => {
    const incentiveTokenIndex = incentiveData?.tokenAddress.findIndex(
      (item) => tokenAddressMap[item.toLowerCase()] === tokenData.symbol,
    );

    const supplyIncentiveData =
      incentiveData?.supplyIncentiveData[incentiveTokenIndex!];
    const borrowIncentiveData =
      incentiveData?.borrowIncentiveData[incentiveTokenIndex!];

    return (
      supplyIncentiveData &&
      supplyIncentiveData.length > 0 &&
      borrowIncentiveData &&
      borrowIncentiveData.length > 0
    );
  }, [incentiveData, tokenData]);
  return (
    <CardWithBorder isLargeBorder mb={6}>
      <Card minW={'343px'}>
        <CardBody>
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Flex gap={2.5}>
              <TokenImage symbol={tokenData.symbol} w={'36px'} />
              <Box>
                <Text textStyle={'body1'}>{tokenData.label}</Text>
                <Text textStyle={'body3'} color={'primary.text2'}>
                  {tokenData.symbol}
                </Text>
              </Box>
            </Flex>
            <HStack>
              {hasIncentiveData && <Boosted />}

              <ChakraNextLink
                href={`/markets/token/?symbol=${tokenData.symbol}`}
              >
                <Icon
                  as={ExpandRight}
                  boxSize={7}
                  bg={'primary.icon_bg'}
                  color={'primary.cta'}
                  borderRadius={'50%'}
                />
              </ChakraNextLink>
            </HStack>
          </Flex>
          <Divider />
          <Tabs>
            <TabList>
              <Tab flex={1}>Supply info</Tab>
              <Tab flex={1}>Borrow info</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <SupplyPanel tokenData={tokenData} />
              </TabPanel>
              <TabPanel>
                <BorrowPanel tokenData={tokenData} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </CardWithBorder>
  );
};

import { useCallback, useEffect, useRef, useState } from 'react';

const THRESHOLD = 50;

export const useDragController = ({
  onDrag,
  onOpen,
  onClose,
  onReset,
}: {
  onDrag?: (
    ele: HTMLDivElement,
    offsetY: number,
    callback: (succeed: boolean) => void,
  ) => void;
  onOpen?: (ele: HTMLDivElement) => void;
  onClose?: (ele: HTMLDivElement) => void;
  onReset?: (ele: HTMLDivElement) => void;
}) => {
  const ref = useRef<HTMLDivElement>();
  const triggerRef = useRef<HTMLDivElement>(null);
  const [isTouching, setIsTouching] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const startYRef = useRef<number | undefined>();
  const offsetYRef = useRef(0);

  const resetOffset = useCallback((pageY?: number) => {
    startYRef.current = pageY;
    offsetYRef.current = 0;
  }, []);
  const onTouchStart = useCallback(
    (e: globalThis.TouchEvent) => {
      setIsTouching(true);
      resetOffset(e.touches[0].pageY);
    },
    [resetOffset],
  );
  const onTouchMove = useCallback(
    (e: globalThis.TouchEvent) => {
      if (!ref.current || startYRef.current === undefined) return;
      offsetYRef.current = e.touches[0].pageY - startYRef.current;
      onDrag?.(ref.current, offsetYRef.current, (succeed) => {
        if (succeed) {
          // prevent browser pull-to-refresh behavior
          if (e.cancelable) e.preventDefault();
        } else {
          resetOffset(e.touches[0].pageY);
        }
      });
      setIsMoving(offsetYRef.current !== 0);
    },
    [onDrag, resetOffset],
  );
  const onTouchEnd = useCallback(() => {
    if (ref.current) {
      const _threshold = Math.min(ref.current.clientHeight / 2, THRESHOLD);
      if (offsetYRef.current >= _threshold) {
        onClose?.(ref.current); // down
      } else if (offsetYRef.current <= -_threshold) {
        onOpen?.(ref.current); // up
      } else {
        onReset?.(ref.current);
      }
    }
    resetOffset();
    setIsTouching(false);
    setIsMoving(false);
  }, [onClose, onOpen, onReset, resetOffset]);

  useEffect(() => {
    const ele = triggerRef.current ?? ref.current;
    if (!ele) return;
    ele.addEventListener('touchstart', onTouchStart);
    ele.addEventListener('touchmove', onTouchMove);
    ele.addEventListener('touchend', onTouchEnd);

    return () => {
      ele.removeEventListener('touchstart', onTouchStart);
      ele.removeEventListener('touchmove', onTouchMove);
      ele.removeEventListener('touchend', onTouchEnd);
    };
  }, [onTouchEnd, onTouchMove, onTouchStart]);

  return {
    ref,
    triggerRef,
    isTouching,
    isMoving,
  };
};

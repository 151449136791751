import Script from 'next/script';

export const GoogleTagManager = () => {
  if (!process.env.GTAG_ID) return null;
  return (
    <Script
      strategy="afterInteractive"
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GTAG_ID}`}
    />
  );
};

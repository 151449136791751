import { Close } from '@amply-app/icons';
import {
  Flex,
  Icon,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { DefaultContent } from './components/DefaultContent';
import { useAccountModal } from './useAccountModal';

export const AccountModal = () => {
  const isOpen = useAccountModal((s) => s.isOpen);
  const closeModal = useAccountModal((s) => s.closeModal);

  return (
    <Modal
      size={{
        base: 'md',
        desktop: 'lg',
      }}
      scrollBehavior="inside"
      onClose={closeModal}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent
        bgColor={'primary.background'}
        background={'center / cover url(/images/bg-full.svg)'}
      >
        <ModalHeader p={5} textStyle="body3" justifyContent="center">
          <Flex alignItems="center" justifyContent="center" position="relative">
            <Icon
              as={Close}
              onClick={closeModal}
              color="primary.cta"
              position="absolute"
              right={0}
              cursor="pointer"
              w={6}
              h={6}
            />
            Wallet
          </Flex>
        </ModalHeader>
        <DefaultContent />
      </ModalContent>
    </Modal>
  );
};

import { Drawer, Menu, Modal } from '@chakra-ui/react';
Modal.defaultProps = {
    ...Modal.defaultProps,
    autoFocus: false,
};
Menu.defaultProps = {
    ...Menu.defaultProps,
    autoSelect: false,
};
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
Drawer.defaultProps = {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    ...Drawer.defaultProps,
    autoFocus: false,
};

import {
  braveWallet,
  currentWallet,
  getAddEthereumChainParameter,
  getWallet,
  RABBY_WALLET_TITLE,
  useDesiredChain,
  walletConfigs,
} from '@amply-app/wallet';
import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { getIsMobile } from '@ui/utils';
import type { FC } from 'react';
import { Fragment } from 'react';
import { create } from 'zustand';

import { useIsShowBraveWallet } from './useIsShowBraveWallet';
import { WalletCard } from './WalletCard';

interface UseWalletModal {
  isOpen: boolean;
  closeModal: () => void;
  openModal: () => void;
  currentWalletName?: string;
  setCurrentWalletName: (currentWalletName: string) => void;
}

export const useWalletModal = create<UseWalletModal>((set) => ({
  isOpen: false,
  closeModal: () => {
    set({ isOpen: false });
  },
  openModal: () => {
    set({ isOpen: true });
  },
  currentWalletName: undefined,
  setCurrentWalletName: (currentWalletName) => {
    set({ currentWalletName });
  },
}));

export const WalletModal: FC<unknown> = () => {
  const [isOpen, closeModal, setCurrentWalletName] = useWalletModal((s) => [
    s.isOpen,
    s.closeModal,
    s.setCurrentWalletName,
  ]);
  const chainId = useDesiredChain();
  const isShowBraveWallet = useIsShowBraveWallet();

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Connect Wallet</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex direction="column" gap={2}>
            {walletConfigs
              .filter(
                (v) =>
                  v.name !== braveWallet.getWalletName() ||
                  isShowBraveWallet ||
                  v.title === RABBY_WALLET_TITLE,
              )
              .map((v) => (
                <Fragment key={v.name}>
                  <WalletCard
                    onClick={async () => {
                      closeModal();
                      const wallet = getWallet(v.name);
                      try {
                        await wallet.detectProvider();
                      } catch {
                        const link = v.getDownloadLink(getIsMobile());
                        if (link) {
                          window.open(link);
                        }
                        return;
                      }
                      currentWallet
                        .connectAsCurrentWallet(
                          v.name,
                          getAddEthereumChainParameter(chainId),
                        )
                        .then(() => {
                          setCurrentWalletName(v.title);
                        })
                        .catch((e) => {
                          // closing walletconnect modal will throw an error
                          console.error(e);
                        });
                    }}
                    walletConfig={v}
                  />
                  <Divider />
                </Fragment>
              ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

import { ExpandDown } from '@amply-app/icons';
import type { IUiIncentiveDataProviderV3 } from '@amply-app/sdk/dist/esm/types/contracts/UiIncentiveDataProviderV3';
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';
import { Num, Percent } from '@ui/components/Num';
import { Info } from '@ui/components/TextBlocks';
import { SECONDS_PER_DAY } from '@ui/hooks/data/constants';
import { filterRewardData } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import React from 'react';

const AprSection = ({
  type = 'supply',
  tokenAPY,
  incentiveDetails,
}: {
  tokenAPY?: Fraction;
  incentiveDetails?: IUiIncentiveDataProviderV3.RewardInfoStructOutput[];
  type?: 'supply' | 'borrow';
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <Info
        title={
          <Text textStyle={'body3'}>
            {type === 'supply' ? 'Supply APR' : 'Borrow APR'}
          </Text>
        }
        content={
          <>
            <Percent value={tokenAPY} />
            <Icon
              as={ExpandDown}
              boxSize={'24px'}
              color="primary.cta"
              onClick={() => setIsExpanded(!isExpanded)}
              transform={isExpanded ? 'rotate(180deg)' : 'none'}
              transition={'all 0.15s'}
            />
          </>
        }
      />
      {isExpanded ? (
        <Flex justifyContent={'flex-end'}>
          <Box textAlign={'right'}>
            <Percent
              value={tokenAPY}
              textStyle={'body1'}
              justifyContent={'flex-end'}
              mt={-2}
              prefix={<Text>{'Base:'} &nbsp;</Text>}
            />
            {filterRewardData(incentiveDetails)
              .map((details) => {
                return (
                  <Num
                    key={details[0]}
                    justifyContent={'flex-end'}
                    value={new Fraction(details[2])
                      .shr(Number(details[6]))
                      .mul(SECONDS_PER_DAY)}
                    textStyle={'body1'}
                    prefix={
                      <>
                        <TokenImage w={'16px'} h={'16px'} symbol={details[0]} />
                        <Text> ${details[0]}:</Text>
                      </>
                    }
                    suffix={'/day'}
                  />
                );
              })}
          </Box>
        </Flex>
      ) : null}
    </>
  );
};

export default AprSection;

import type { ContractAddressMap } from '@amply-app/sdk';
import { getContractAddress } from '@amply-app/sdk';
import { currentWallet } from '@amply-app/wallet';
import { useMutation } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getGasOverrides, getPaymasterParams, getSDK } from '@ui/utils';
import type { Fraction } from 'bi-fraction';
import { MaxUint256 } from 'ethers';
import { BrowserProvider } from 'zksync-ethers';

import { useAllPoolsData } from '../data/useAllPoolsData';
import { approveIfNeeded } from '../data/utils';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useWithdrawMutation = () => {
  const account = useAccount();
  const provider = useProvider();
  const chainId = useChainId();
  const connectorProvider = getConnector().provider;
  const { data: allPoolsData } = useAllPoolsData();
  const gasTokenSymbol = usePaymaster((s) => s.token);
  const gasTokenAddress =
    allPoolsData?.find((token) => token.symbol === gasTokenSymbol)
      ?.tokenAddress ?? '';
  return useMutation(
    async ({
      amount,
      tokenSymbol,
      isMaxAmount,
    }: {
      amount: Fraction;
      tokenSymbol: keyof ContractAddressMap | 'zkCRO';
      isMaxAmount: boolean;
    }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;
      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();
      const sdk = getSDK();

      const assetAddress = getContractAddress(
        chainId,
        tokenSymbol === 'zkCRO' ? 'wzkCRO' : tokenSymbol,
      );
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();

      const token = sdk.getERC20(assetAddress, browserSigner);
      const decimals = await token.decimals();

      let overrides = {};
      if (gasTokenSymbol !== tokenMap.zkCRO) {
        const paymasterParams = getPaymasterParams({ gasTokenAddress });
        let gasLimit;
        if (tokenSymbol === tokenMap.zkCRO) {
          // https://docs.aave.com/developers/periphery-contracts/wethgateway#withdraweth
          // The WETHGateway contract must have an approved token allowance to spend aWETH on behalf of the user
          const provider = sdk
            .getContractAddress('PoolAddressesProvider')
            .toLowerCase();
          const reservesData = await sdk
            .getUiPoolDataProviderV3Abi()
            .getReservesData(provider);
          const wzkCROAddress = getContractAddress(chainId, 'wzkCRO');
          const wzkACROAddress = reservesData[0].find(
            (item) => item[0] === wzkCROAddress,
          )?.aTokenAddress;
          const wzkACRO = sdk.getERC20(wzkACROAddress!, browserSigner);
          const gatewayAddress = getContractAddress(
            chainId,
            'WrappedTokenGatewayV3',
          );

          await approveIfNeeded({
            account,
            spender: gatewayAddress,
            amount: isMaxAmount
              ? amount.add(1).shl(Number(decimals)).quotient
              : amount.shl(Number(decimals)).quotient,
            token: wzkACRO,
            gasTokenAddress,
            gasTokenSymbol,
          });
          gasLimit = await sdk
            .getWrappedTokenGatewayV3(browserSigner)
            .withdrawETH.estimateGas(
              poolAddress,
              isMaxAmount ? MaxUint256 : amount.shl(Number(decimals)).quotient,
              account,
            );
        } else {
          gasLimit = await sdk
            .getL2Pool(poolAddress, browserSigner)
            ['withdraw(address,uint256,address)'].estimateGas(
              assetAddress,
              isMaxAmount
                ? amount.add(1).shl(Number(decimals)).quotient
                : amount.shl(Number(decimals)).quotient,
              account,
            );
        }
        overrides = await getGasOverrides({ gasLimit, paymasterParams });
      }

      let tx;

      if (tokenSymbol === tokenMap.zkCRO) {
        if (gasTokenSymbol === tokenMap.zkCRO) {
          // https://docs.aave.com/developers/periphery-contracts/wethgateway#withdraweth
          // The WETHGateway contract must have an approved token allowance to spend aWETH on behalf of the user
          const provider = sdk
            .getContractAddress('PoolAddressesProvider')
            .toLowerCase();
          const reservesData = await sdk
            .getUiPoolDataProviderV3Abi()
            .getReservesData(provider);
          const wzkCROAddress = getContractAddress(chainId, 'wzkCRO');
          const wzkACROAddress = reservesData[0].find(
            (item) => item[0] === wzkCROAddress,
          )?.aTokenAddress;
          const wzkACRO = sdk.getERC20(wzkACROAddress!, browserSigner);
          const gatewayAddress = getContractAddress(
            chainId,
            'WrappedTokenGatewayV3',
          );

          await approveIfNeeded({
            account,
            spender: gatewayAddress,
            amount: isMaxAmount
              ? amount.add(1).shl(Number(decimals)).quotient
              : amount.shl(Number(decimals)).quotient,
            token: wzkACRO,
            gasTokenAddress,
            gasTokenSymbol,
          });
        }
        tx = await sdk
          .getWrappedTokenGatewayV3(browserSigner)
          .withdrawETH(
            poolAddress,
            isMaxAmount ? MaxUint256 : amount.shl(Number(decimals)).quotient,
            account,
            overrides,
          );
      } else {
        tx = await sdk
          .getL2Pool(poolAddress, browserSigner)
          ['withdraw(address,uint256,address)'](
            assetAddress,
            isMaxAmount ? MaxUint256 : amount.shl(Number(decimals)).quotient,
            account,
            overrides,
          );
      }

      return await tx.wait();
    },
  );
};

import { useReservesData } from '@ui/components/Dashboard/Overview';
import { Fraction } from 'bi-fraction';

export const useUserTotalSuppliedUSD = () => {
  const userReservesData = useReservesData((s) => s.data);

  const totalSuppliedUSD = userReservesData?.reduce((a, b) => {
    return a.add(b.aTokenBalance.mul(b.tokenUSDPrice ?? Fraction.ZERO));
  }, Fraction.ZERO);

  return totalSuppliedUSD;
};

export const useUserTotalBorrowedUSD = () => {
  const userReservesData = useReservesData((s) => s.data);

  const totalBorrowedUSD = userReservesData?.reduce((a, b) => {
    return a.add(b.vDebtTokenBalance.mul(b.tokenUSDPrice ?? Fraction.ZERO));
  }, Fraction.ZERO);

  return totalBorrowedUSD;
};

import { currentWallet } from '@amply-app/wallet';
import { useQuery } from '@tanstack/react-query';
import { RAY_UNIT } from '@ui/config/sc';
import { RefetchInterval } from '@ui/config/ui';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

export interface UserReserveData {
  tokenAddress: string;
  aTokenBalance: Fraction;
  asCollateral: boolean;
  vDebtTokenBalance: Fraction;
  tokenUSDPrice: Fraction;
}

export const useUserReservesData = () => {
  const sdk = getSDK();
  const provider = sdk
    .getContractAddress('PoolAddressesProvider')
    .toLowerCase();

  const account = currentWallet.useAccount();

  return useQuery(
    ['useUserReservesData', provider, account],
    async () => {
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();
      const data = await sdk
        .getUiPoolDataProviderV3Abi()
        .getUserReservesData(provider, account!);

      const reservesData = await Promise.all(
        data[0].map(async (item) => {
          const address = item[0];
          const reserveData = await sdk
            .getL2Pool(poolAddress)
            .getReserveData(address);
          return {
            liquidityIndex: new Fraction(reserveData[1]).shr(RAY_UNIT),
            variableBorrowIndex: new Fraction(reserveData[3]).shr(RAY_UNIT),
          };
        }),
      );

      const formattedData: UserReserveData[] = await Promise.all(
        data[0].map(async (item, index) => ({
          tokenAddress: item[0],
          aTokenBalance: new Fraction(item[1])
            .shr(Number(await sdk.getAToken(item[0]).decimals()))
            .mul(reservesData[index].liquidityIndex),
          asCollateral: item[2],
          vDebtTokenBalance: new Fraction(item[3])
            .shr(Number(await sdk.getVariableDebtToken(item[0]).decimals()))
            .mul(reservesData[index].variableBorrowIndex),
          tokenUSDPrice: new Fraction(
            await sdk.getAmplyOracle().getAssetPrice(item[0]),
          ).shr(8),
        })),
      );
      return formattedData;
    },
    { enabled: !!account, refetchInterval: RefetchInterval.Normal },
  );
};

import { currentWallet } from '@amply-app/wallet';
import { useMutation } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getGasOverrides, getPaymasterParams, getSDK } from '@ui/utils';
import type { Fraction } from 'bi-fraction';
import { BrowserProvider } from 'zksync-ethers';

import { REFERRAL_CODE } from '../data/constants';
import { type UseAllPoolsData, useAllPoolsData } from '../data/useAllPoolsData';
import { approveIfNeeded, checkZKCROAddress } from '../data/utils';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useSupplyMutation = () => {
  const account = useAccount();
  const chainId = useChainId();
  const provider = useProvider();
  const connectorProvider = getConnector().provider;
  const { data: allPoolsData } = useAllPoolsData();
  const gasTokenSymbol = usePaymaster((s) => s.token);
  const gasTokenAddress =
    allPoolsData?.find((token) => token.symbol === gasTokenSymbol)
      ?.tokenAddress ?? '';
  return useMutation(
    async ({
      amount,
      tokenData,
    }: {
      amount: Fraction;
      tokenData: UseAllPoolsData;
    }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;

      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();
      const sdk = getSDK();
      const tokenAddress = checkZKCROAddress(tokenData.tokenAddress);
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();

      const token = sdk.getERC20(tokenAddress!, browserSigner);
      const decimals = await token.decimals();

      // Native currency does not need approval
      if (tokenData.symbol !== tokenMap.zkCRO) {
        await approveIfNeeded({
          account,
          spender: poolAddress,
          amount: amount.shl(Number(decimals)).quotient,
          token,
          gasTokenAddress,
          gasTokenSymbol,
        });
      }

      let overrides = {};
      if (gasTokenSymbol !== tokenMap.zkCRO) {
        const paymasterParams = getPaymasterParams({ gasTokenAddress });
        let gasLimit;
        if (tokenData.symbol === tokenMap.zkCRO) {
          gasLimit = await sdk
            .getWrappedTokenGatewayV3(browserSigner)
            .depositETH.estimateGas(poolAddress, account, REFERRAL_CODE, {
              value: amount.shl(Number(decimals)).quotient,
            });
        } else {
          gasLimit = await sdk
            .getL2Pool(poolAddress, browserSigner)
            ['supply(address,uint256,address,uint16)'].estimateGas(
              tokenAddress!,
              amount.shl(Number(decimals)).quotient,
              account,
              REFERRAL_CODE,
            );
        }
        overrides = await getGasOverrides({ gasLimit, paymasterParams });
      }

      let tx;
      if (tokenData.symbol === tokenMap.zkCRO) {
        tx = await sdk
          .getWrappedTokenGatewayV3(browserSigner)
          .depositETH(poolAddress, account, REFERRAL_CODE, {
            value: amount.shl(Number(decimals)).quotient,
            ...overrides,
          });
      } else {
        tx = await sdk
          .getL2Pool(poolAddress, browserSigner)
          ['supply(address,uint256,address,uint16)'](
            tokenAddress!,
            amount.shl(Number(decimals)).quotient,
            account,
            REFERRAL_CODE,
            overrides,
          );
      }

      return await tx.wait();
    },
  );
};

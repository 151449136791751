import { useQuery } from '@tanstack/react-query';
import { RefetchInterval } from '@ui/config/ui';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

export const useTokenBorrow = (variableDebtTokenAddress?: string) => {
  const sdk = getSDK();
  return useQuery(
    ['useTokenBorrow', variableDebtTokenAddress],
    async () => {
      const tokenBorrow = await sdk
        .getVariableDebtToken(variableDebtTokenAddress!)
        .totalSupply();
      const tokenDecimal = await sdk
        .getVariableDebtToken(variableDebtTokenAddress!)
        .decimals();

      return new Fraction(tokenBorrow).shr(Number(tokenDecimal));
    },
    {
      enabled: !!variableDebtTokenAddress,
      refetchInterval: RefetchInterval.Normal,
    },
  );
};

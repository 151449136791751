import { Box, Text } from '@chakra-ui/react';
import { UsdValue } from '@ui/components/Num/UsdValue';
import { useAmplyPrice, useTokenPrice } from '@ui/hooks/data';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import type { ReactNode } from 'react';

import type { TokenInputContextProps } from '../types';

interface AmplyUsdValueProps {
  value: string;
  errorMsg: ReactNode;
}

const AmplyUsdValue: React.FC<AmplyUsdValueProps> = ({ value, errorMsg }) => {
  const { data: amplyPrice } = useAmplyPrice();

  return (
    <UsdValue
      color={errorMsg ? 'primary.error' : 'primary.text2'}
      textStyle={'body3'}
      value={new Fraction(value)}
      price={amplyPrice}
      decimalPlaces={4}
    />
  );
};

export const InputMessage = ({
  value,
  selectedTokenData,
  errorMsg,
  warningMsg,
}: Pick<
  TokenInputContextProps,
  'value' | 'selectedTokenData' | 'errorMsg' | 'warningMsg'
>) => {
  const amplyAddress = getSDK().getContractAddress('Amply').toLowerCase();
  const { data: tokenPrice } = useTokenPrice(selectedTokenData?.tokenAddress);
  if (!value && !errorMsg && !warningMsg) return <Box mb={4} />;
  return (
    <>
      {value &&
        (selectedTokenData?.tokenAddress === amplyAddress ? (
          <AmplyUsdValue value={value} errorMsg={errorMsg} />
        ) : (
          <UsdValue
            color={errorMsg ? 'primary.error' : 'primary.text2'}
            textStyle={'body3'}
            value={new Fraction(value)}
            price={tokenPrice}
            decimalPlaces={4}
          />
        ))}

      {errorMsg && (
        <Text color="primary.error" textStyle={'body3'}>
          {errorMsg}
        </Text>
      )}
      {warningMsg && (
        <Text color="primary.caution" textStyle={'body3'}>
          {warningMsg}
        </Text>
      )}
    </>
  );
};

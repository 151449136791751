import { Button, Center, Flex, Stack } from '@chakra-ui/react';
import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';
import {
  ModalOrDrawerBody,
  ModalOrDrawerFooter,
} from '@ui/components/ModalOrDrawer';
import { Num } from '@ui/components/Num';
import { Paymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { useUserReservesIncentivesData } from '@ui/hooks/data/useUserReservesIncentivesData';

import { useClaimRewards } from '../hooks/useClaimRewards';
import { useClaimModal } from '.';

export const DefaultContent: React.FC = () => {
  const { data: userReservesIncentivesData } = useUserReservesIncentivesData();
  const claim = useClaimRewards(userReservesIncentivesData?.allAAndVTokens);

  const isPreAmpCompleted = useClaimModal((s) => s.isPreAmpCompleted);
  const tokenAmount = isPreAmpCompleted
    ? userReservesIncentivesData?.amplyTokenAmount
    : userReservesIncentivesData?.preAmplyTokenAmount;

  const handleConfirm = () => {
    claim();
  };
  return (
    <>
      <ModalOrDrawerBody>
        <Flex direction={'column'} gap={'3'}>
          <Center textStyle={'body1'}>Amount to claim</Center>
          <Center gap={1}>
            <TokenImage
              w="24px"
              symbol={isPreAmpCompleted ? 'AMPLY' : 'preAMP'}
            />
            <Num textStyle={'h2'} value={tokenAmount} />
          </Center>
        </Flex>
      </ModalOrDrawerBody>
      <ModalOrDrawerFooter>
        <Stack w={'full'} spacing={4}>
          <Paymaster />
          <Button
            isDisabled={!tokenAmount || tokenAmount.lte(0)}
            onClick={handleConfirm}
          >
            {'Confirm'}
          </Button>
        </Stack>
      </ModalOrDrawerFooter>
    </>
  );
};

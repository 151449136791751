import { fontFamily } from './fontFamily';
export const styles = {
    global: {
        body: {
            fontSize: '16px',
            fontFamily: fontFamily.default,
            bg: '#444',
            color: 'white',
        },
        a: {
            textDecoration: 'none !important',
        },
        'd1,d2,h1,h2,h3': {
            fontFamily: `${fontFamily.heading}`,
        },
        // no scrollbar
        '.no-scrollbar::-webkit-scrollbar': {
            display: 'none',
        },
        '.no-scrollbar': {
            scrollbarWidth: 'none',
        },
    },
};

import { SupplyAndBorrow } from '@amply-app/icons';
import { Icon, Stack, VStack } from '@chakra-ui/react';
import HealthFactor from '@ui/components/HealthFactor';
import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';
import { Num, Percent } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import { PercentageSelect } from '@ui/components/PercentageSelect';
import { Info } from '@ui/components/TextBlocks';
import { TokenInput } from '@ui/components/TokenInput';
import { Fraction } from 'bi-fraction';

import { useSupplyAndBorrowModal } from '..';

export const BorrowAndSupply = () => {
  const [
    borrowValue,
    supplyValue,
    setBorrowValue,
    setSupplyValue,
    setSelectedBorrowTokenData,
    setSelectedSupplyTokenData,
    borrowTokenData,
    supplyTokenData,
  ] = useSupplyAndBorrowModal((s) => [
    s.borrowAmountStr,
    s.supplyAmountStr,
    s.setBorrowAmountStr,
    s.setSupplyAmountStr,
    s.setBorrowTokenData,
    s.setSupplyTokenData,
    s.borrowTokenData,
    s.supplyTokenData,
  ]);

  // TODO: Implement these values
  const maxBorrowable = Fraction.ZERO;
  const usdPrice = Fraction.ZERO;
  const apr = Fraction.ZERO;
  const healthScore = Fraction.ONE;

  return (
    <>
      <Stack spacing={4}>
        <Info
          title="Collateral supplied"
          content={
            <>
              <Num value={Fraction.ZERO} />
              <TokenImage symbol={'zkCRO'} />
            </>
          }
          usdContent={<UsdValue value={Fraction.ZERO} price={Fraction.ZERO} />}
        />
        <TokenInput
          selectedTokenData={supplyTokenData}
          setSelectedTokenData={setSelectedSupplyTokenData}
          value={supplyValue}
          onChange={setSupplyValue}
        />
        <PercentageSelect
          value={supplyValue}
          maxValue={maxBorrowable}
          decimalPlaces={18}
          onSelect={setSupplyValue}
        />
        <VStack>
          <Icon as={SupplyAndBorrow} boxSize={5} color={'primary.text2'} />
        </VStack>
        <TokenInput
          errorMsg={
            healthScore.lt(new Fraction(2))
              ? 'Lower your borrowing amount to put the Health Factor above minimum'
              : undefined
          }
          warningMsg={
            healthScore.lt(new Fraction(3)) && healthScore.gt(Fraction.ONE)
              ? 'We recommend borrowing less or supplying more to increase your Health Factor'
              : undefined
          }
          setSelectedTokenData={setSelectedBorrowTokenData}
          value={borrowValue}
          selectedTokenData={borrowTokenData}
          onChange={setBorrowValue}
          topLabel={
            <UsdValue
              prefix="Max borrowable amount: "
              value={maxBorrowable}
              price={usdPrice}
              color="primary.text2"
              textStyle="body1"
              mb={1}
            />
          }
        />
        <Info title="Supply APR" content={<Percent value={apr} />} />
        <Info title="Borrow APR" content={<Percent value={apr} />} />
        <HealthFactor value={healthScore} />
      </Stack>
    </>
  );
};

import { Question } from '@amply-app/icons';
import { Button, Flex, Icon, Stack, Text } from '@chakra-ui/react';
import HealthFactor from '@ui/components/HealthFactor';
import InfoTooltip from '@ui/components/InfoTooltip';
import {
  ModalOrDrawerBody,
  ModalOrDrawerFooter,
} from '@ui/components/ModalOrDrawer';
import { Num } from '@ui/components/Num';
import {
  Paymaster,
  usePaymaster,
} from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { Info } from '@ui/components/TextBlocks';
import { HealthFactorType, useHealthFactor } from '@ui/hooks/data';
import {
  AvailableBorrowType,
  useAvailableBorrow,
} from '@ui/hooks/data/useAvailableBorrow';

import { useCollateralDrawer, useSetAsCollateral } from '../hooks';

export const DefaultContent: React.FC = () => {
  const [collateralUSDValue, tokenAddress] = useCollateralDrawer((s) => [
    s.tokenUSDValue,
    s.tokenAddress,
  ]);
  const isPaymasterError = usePaymaster((s) => s.isError);
  const enableAsCollateral = useSetAsCollateral(true);

  const healthFactor = useHealthFactor(
    HealthFactorType.EnableAsCollateral,
    collateralUSDValue,
    tokenAddress,
  );
  const availableToBorrowUSD = useAvailableBorrow(
    AvailableBorrowType.EnableAsCollateral,
    collateralUSDValue,
    tokenAddress,
  );

  return (
    <>
      <ModalOrDrawerBody>
        <Flex direction={'column'} gap={'3'}>
          <Info
            title={
              <>
                {'Available to borrow'}
                <InfoTooltip
                  title={'Available to borrow'}
                  label="Maximum amount available for you to borrow, assuming that there is no borrow cap"
                />
              </>
            }
            content={<Num value={availableToBorrowUSD} />}
          />
          <HealthFactor value={healthFactor} />
          <Flex justifyContent={'center'} color={'primary.caution'} gap={2}>
            <Icon as={Question} color={'primary.caution'} w={6} h={6} />
            <Text textStyle={'body3'}>
              You will now be able to borrow against this asset.
            </Text>
          </Flex>
        </Flex>
      </ModalOrDrawerBody>
      <ModalOrDrawerFooter>
        <Stack w={'full'} spacing={4}>
          <Paymaster />
          <Button isDisabled={isPaymasterError} onClick={enableAsCollateral}>
            {'Confirm'}
          </Button>
        </Stack>
      </ModalOrDrawerFooter>
    </>
  );
};

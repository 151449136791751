import { HEALTH_FACTOR_UNIT } from '@ui/config/sc';
import { Fraction } from 'bi-fraction';
import { MaxUint256 } from 'ethers';
import { useMemo } from 'react';

import { useUserAccountData } from './useUserAccountData';
import { useUserReservesData } from './useUserReservesData';
import { useWeightedAverageCurrentLTV } from './useWeightedAverageCurrentLTV';
import { compareZKCROAddress } from './utils';

export enum HealthFactorType {
  Withdraw,
  Repay,
  Borrow,
  Supply,
  EnableAsCollateral,
  DisableAsCollateral,
}

export const useHealthFactor = (
  type: HealthFactorType,
  USD: Fraction = Fraction.ZERO,
  tokenAddress?: string,
) => {
  const { data } = useUserAccountData();
  const { data: userReservesData } = useUserReservesData();

  const currentLTV = useWeightedAverageCurrentLTV(type, USD, tokenAddress);
  const totalCollateralBase = data?.totalCollateralBase;
  const totalDebtBase = useMemo(() => {
    return type === HealthFactorType.Borrow
      ? data?.totalDebtBase.add(USD)
      : data?.totalDebtBase;
  }, [data, USD, type]);

  // HF = SUM[(Collateral USD * current LTV)] / SUM(debt USD)
  const healthFactor = useMemo(() => {
    if (
      totalDebtBase?.eq(Fraction.ZERO) ||
      (type === HealthFactorType.Repay &&
        totalDebtBase?.sub(USD).lte(Fraction.ZERO))
    )
      return new Fraction(MaxUint256).shr(HEALTH_FACTOR_UNIT);

    if (totalCollateralBase && currentLTV && totalDebtBase) {
      if (
        type === HealthFactorType.Withdraw ||
        type === HealthFactorType.DisableAsCollateral
      ) {
        return totalCollateralBase.sub(USD).mul(currentLTV).div(totalDebtBase);
      }
      if (type === HealthFactorType.Repay) {
        return totalCollateralBase.mul(currentLTV).div(totalDebtBase.sub(USD));
      }

      if (type === HealthFactorType.EnableAsCollateral) {
        return totalCollateralBase.add(USD).mul(currentLTV).div(totalDebtBase);
      }
      if (type === HealthFactorType.Supply) {
        const userReserveData = userReservesData?.find(
          (userReserveData) =>
            userReserveData.tokenAddress === compareZKCROAddress(tokenAddress),
        );

        if (
          userReserveData?.asCollateral ||
          (userReserveData?.tokenAddress ===
            compareZKCROAddress(tokenAddress) &&
            userReserveData?.aTokenBalance.eq(0))
        ) {
          return totalCollateralBase
            .add(USD)
            .mul(currentLTV)
            .div(totalDebtBase);
        } else {
          return totalCollateralBase.mul(currentLTV).div(totalDebtBase);
        }
      }

      // borrow
      return totalCollateralBase.mul(currentLTV).div(totalDebtBase);
    }
    return Fraction.ZERO;
  }, [
    totalDebtBase,
    totalCollateralBase,
    currentLTV,
    type,
    USD,
    userReservesData,
    tokenAddress,
  ]);
  return healthFactor;
};

import { Fraction } from 'bi-fraction';

/**
 * https://docs.aave.com/developers/core-contracts/pool#supply
 * unique code for 3rd party referral program integration. Use 0 for no referral.
 */
export const REFERRAL_CODE = 0;

/**
 * https://github.com/cronos-labs/amply-finance-core/blob/c7966a40662aed8bdf81d1df9f7d0a4b9729eb04/contracts/protocol/libraries/types/DataTypes.sol#L78
 * Stable mode deprecated, use Variable, i.e. 1: enum InterestRateMode {NONE, VARIABLE}
 */
export const INTEREST_RATE_MODE = 1;

export const MOCK_ZKCRO_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';

/**
 * As now don't have AMPLY token in the zkEVM network, so we use the USD price as 1,
 * and have confirmed with SC team that they removed the rewardPriceFeed in getUserReservesIncentivesData function,
 * so we can use this constant, but still need to confirm with PM
 */
export const AMPLY_USD_PRICE = new Fraction('0.05');

export const SECONDS_PER_YEAR = 31536000;

export const SECONDS_PER_DAY = 86400;

export const WEI_DECIMALS = 18;

export const MAX_WITHDRAWAL_HF = new Fraction(1.01);

export const WITHDRAWAL_HF_WARNING_THRESHOLD = new Fraction(1.02);

export type LockingPeriod =
  | '1 Month'
  | '3 Months'
  | '12 Months'
  | '24 Months'
  | '48 Months'
  | '20 Seconds'
  | '40 Seconds'
  | '1 Minute'
  | '2 Minutes'
  | '3 Minutes'
  | '4 Minutes'
  | '3.3 Minutes';

export const secondsToPeriod: Record<number, LockingPeriod> = {
  20: '20 Seconds',
  40: '40 Seconds',
  60: '1 Minute',
  120: '2 Minutes',
  180: '3 Minutes',
  240: '4 Minutes',
  200: '3.3 Minutes',
  2592000: '1 Month',
  7776000: '3 Months',
  31104000: '12 Months',
  62208000: '24 Months',
  124416000: '48 Months',
};

export const periodInSeconds: Record<LockingPeriod, number> = {
  '20 Seconds': 20,
  '40 Seconds': 40,
  '1 Minute': 60,
  '2 Minutes': 120,
  '3 Minutes': 180,
  '4 Minutes': 240,
  '3.3 Minutes': 200,
  '1 Month': 2592000,
  '3 Months': 7776000,
  '12 Months': 31104000,
  '24 Months': 62208000,
  '48 Months': 124416000,
};
export const INTEREST_RATE_SCALED_DECIMALS = 20;

export const INTEREST_RATE_DISPLAY_DECIMALS = 18;

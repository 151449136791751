import { Box, Flex, Text } from '@chakra-ui/react';
import {
  RenderInDesktop,
  RenderInMobile,
} from '@ui/components/MobileOrDesktop';
import { Fraction } from 'bi-fraction';
import React from 'react';

import NoBorrowedOrSuppliedGuild from '../NoBorrowedOrSuppliedGuild';
import { useReservesData } from '../Overview';
import BorrowCards from './BorrowCards';
import BorrowTable from './BorrowTable';
import SupplyCards from './SupplyCards';
import SupplyTable from './SupplyTable';

const Portfolio = () => {
  const data = useReservesData((s) => s.data);

  const supplyData = data
    ?.filter((item) => item.aTokenBalance.gt(Fraction.ZERO))
    .map((item) => ({
      ...item,
    }));

  const borrowData = data
    ?.filter((item) => item.vDebtTokenBalance.gt(Fraction.ZERO))
    .map((item) => ({
      ...item,
    }));

  const hasBorrowedOrSupplied = data?.some(
    (item) =>
      item.aTokenBalance.gt(Fraction.ZERO) ||
      item.vDebtTokenBalance.gt(Fraction.ZERO),
  );

  return (
    <>
      <RenderInMobile>
        {!hasBorrowedOrSupplied ? (
          <NoBorrowedOrSuppliedGuild />
        ) : (
          <>
            <Box mb={8}>
              <Text color="primary.text2" textStyle={'body2'} mb={3}>
                Tokens supplied
              </Text>
              <SupplyCards data={supplyData} />
            </Box>
            <Box mb={8}>
              <Text color="primary.text2" textStyle={'body2'} mb={3}>
                Tokens Borrowed
              </Text>
              <BorrowCards data={borrowData} />
            </Box>
          </>
        )}
      </RenderInMobile>
      <RenderInDesktop>
        <Flex direction={'column'} gap={8}>
          <SupplyTable data={supplyData} />
          <BorrowTable data={borrowData} />
        </Flex>
      </RenderInDesktop>
    </>
  );
};

export default Portfolio;

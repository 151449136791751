/**
 * Card theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/card.ts
 */
import { cardAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const { definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);
// define custom variant
const variants = {
    default: definePartsStyle({
        container: {},
    }),
};
export const Card = {
    parts: parts.keys,
    baseStyle() {
        return {
            container: {
                background: 'rgba(5, 18, 26, 0.30)',
                backdropFilter: 'blur(2px)',
                color: 'primary.white',
                borderRadius: 0,
            },
            body: {
                p: 3,
            },
        };
    },
    sizes: {
        md: definePartsStyle({
            container: {},
            header: {
            // py: '16px',
            },
        }),
        // define custom styles for xl size
        xl: definePartsStyle({
            container: {
            // padding: '40px',
            },
        }),
    },
    variants,
    defaultProps: {},
};

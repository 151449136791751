import { textStyles } from '../textStyles';
export const Button = {
    baseStyle() {
        return {};
    },
    variants: {
        primary() {
            return {
                ...textStyles?.cta,
                background: 'primary.cta',
                rounded: '0px',
                color: 'primary.layer1',
                px: 6,
                clipPath: 'polygon(14px 0, 100% 0, calc(100% - 14px) 100%, 0 100%)',
                _active: {
                    background: 'primary.active_state',
                },
                _disabled: {
                    background: 'primary.disabled',
                    color: 'primary.white',
                    opacity: 1,
                },
                _loading: {
                    background: 'primary.cta',
                    color: 'primary.layer1',
                },
                _hover: {
                    background: 'primary.active_state',
                    _disabled: {
                        background: 'primary.disabled',
                        color: 'primary.white',
                        opacity: 1,
                    },
                    _loading: {
                        background: 'primary.cta',
                        color: 'primary.layer1',
                    },
                },
            };
        },
        secondary() {
            return {
                ...textStyles?.cta,
                color: 'primary.cta',
                borderColor: 'primary.cta',
                borderWidth: '1px',
                rounded: 'full',
                backgroundColor: 'transparent',
                _active: {
                    background: 'primary.active_state',
                    color: 'primary.layer1',
                },
                _disabled: {
                    borderColor: 'primary.disabled',
                    color: 'primary.disabled',
                    opacity: 1,
                },
                _loading: {
                    background: 'transparent',
                    borderColor: 'primary.cta',
                    color: 'primary.cta',
                },
                _hover: {
                    background: 'primary.active_state',
                    color: 'primary.layer1',
                    _disabled: {
                        borderColor: 'primary.disabled',
                        color: 'primary.disabled',
                        opacity: 1,
                    },
                    _loading: {
                        background: 'transparent',
                        borderColor: 'primary.cta',
                        color: 'primary.cta',
                    },
                },
            };
        },
        outline() {
            return {
                ...textStyles?.cta,
                color: 'primary.cta',
                borderColor: 'primary.cta',
                borderWidth: '1px',
                rounded: 0,
                background: 'gradient.blur',
                _active: {
                    background: 'primary.active_state',
                    color: 'primary.layer1',
                },
                _disabled: {
                    borderColor: 'primary.disabled',
                    color: 'primary.disabled',
                    opacity: 1,
                },
                _loading: {
                    background: 'gradient.blur',
                    borderColor: 'primary.cta',
                    color: 'primary.cta',
                },
                _hover: {
                    _disabled: {
                        borderColor: 'primary.disabled',
                        color: 'primary.disabled',
                        opacity: 1,
                    },
                    _loading: {
                        background: 'gradient.blur',
                        borderColor: 'primary.cta',
                        color: 'primary.cta',
                    },
                },
            };
        },
        link() {
            return {
                ...textStyles?.cta,
                color: 'primary.cta',
                _active: {
                    textDecoration: 'none',
                    color: 'primary.active_state',
                },
                _disabled: {
                    color: 'primary.disabled',
                    opacity: 1,
                },
                _hover: {
                    textDecoration: 'none',
                    color: 'primary.active_state',
                    _disabled: {
                        color: 'primary.disabled',
                        opacity: 1,
                    },
                },
            };
        },
        linkLarge() {
            return {
                ...textStyles?.h4,
                color: 'primary.cta',
                p: 0,
                _active: {
                    textDecoration: 'none',
                    color: 'primary.active_state',
                },
                _disabled: {
                    color: 'primary.disabled',
                    opacity: 1,
                },
                _hover: {
                    textDecoration: 'none',
                    color: 'primary.active_state',
                    _disabled: {
                        color: 'primary.disabled',
                        opacity: 1,
                    },
                },
            };
        },
        fab() {
            return {
                ...textStyles?.fab,
                color: 'primary.layer1',
                background: 'linear-gradient(to bottom, #98ff7e, #589d47)',
                position: 'fixed',
                rounded: 'full',
                _hover: {
                    opacity: 0.8,
                },
            };
        },
        details() {
            return {
                ...textStyles?.cta,
                color: 'primary.cta',
                borderRadius: 0,
                background: 'gradient.detail',
                border: '0.5px solid',
                _hover: {
                    bg: 'primary.layer1',
                    color: 'primary.white',
                    borderColor: 'primary.cta',
                },
            };
        },
        percentage() {
            return {
                ...textStyles?.body3,
                color: 'primary.white',
                px: 8,
                borderRadius: 0,
                background: 'gradient.blur',
                border: '0.5px solid',
                borderColor: 'primary.cta',
                _hover: {
                    bg: 'primary.layer1',
                    color: 'primary.white',
                    borderColor: 'primary.cta',
                },
                _disabled: {
                    background: 'primary.disabled',
                    borderColor: 'primary.disabled',
                    color: 'primary.white',
                    opacity: 1,
                },
            };
        },
        claim() {
            return {
                ...textStyles?.cta,
                background: 'primary.cta',
                rounded: '0px',
                color: 'primary.layer1',
                _active: {
                    background: 'primary.active_state',
                },
                _disabled: {
                    background: 'primary.disabled',
                    color: 'primary.white',
                    opacity: 1,
                },
                _loading: {
                    background: 'primary.cta',
                    color: 'primary.layer1',
                },
                _hover: {
                    background: 'primary.active_state',
                    _disabled: {
                        background: 'primary.disabled',
                        color: 'primary.white',
                        opacity: 1,
                    },
                    _loading: {
                        background: 'primary.cta',
                        color: 'primary.layer1',
                    },
                },
            };
        },
    },
    sizes: {},
    defaultProps: {
        variant: 'primary',
    },
};

import type { ChainId } from '@amply-app/sdk';
import { explorers } from '@amply-app/wallet';

import { getChainId } from './getChainId';

export function getExplorerLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainId: ChainId = getChainId(),
): string {
  switch (type) {
    case 'transaction': {
      return `${explorers[chainId]}/tx/${data}`;
    }
    case 'token': {
      return `${explorers[chainId]}/token/${data}`;
    }
    case 'block': {
      return `${explorers[chainId]}/block/${data}`;
    }
    default: {
      return `${explorers[chainId]}/address/${data}`;
    }
  }
}

import { currentWallet } from '@amply-app/wallet';
import { useQuery } from '@tanstack/react-query';
import { PIONEER_PROGRAM_URL } from '@ui/config/api';
import { RefetchInterval } from '@ui/config/ui';

export const usePoineerProgram = () => {
  const account = currentWallet.useAccount();
  return useQuery(
    ['usePoineerProgram', account],
    async () => {
      const data = await fetch(
        `${PIONEER_PROGRAM_URL}?page=0&pageSize=0&walletAddress=${account}`,
      ).then((res) => res.json());
      return data?.userRanking;
    },
    { refetchInterval: RefetchInterval.Normal },
  );
};

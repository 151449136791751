import { tokenAddressMap } from '@ui/components/TokenInput/constants';
import type { UseAllPoolsData } from '@ui/hooks/data';
import {
  useAmplyPrice,
  useBorrowAPY,
  useBorrowCap,
  useIncentiveAPY,
  useTokenBorrow,
  useTokenPrice,
  useTokenUtilization,
} from '@ui/hooks/data';
import type { ReserveInfoResponse } from '@ui/hooks/data/useReservesHistory';
import useReserveHistory from '@ui/hooks/data/useReservesHistory';
import { filterRewardData } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import { isBefore } from 'date-fns';
import React, { useMemo } from 'react';

import { calculateAPY } from '../utils';
import { Information } from './Information';

interface BorrowPanelProps {
  tokenData: UseAllPoolsData;
}

export const BorrowPanel = ({ tokenData }: BorrowPanelProps) => {
  const { data: reserves } = useReserveHistory(tokenData.symbol);
  const { data: tokenBorrow } = useTokenBorrow(tokenData.variableDebtToken);
  const { data: tokenPrice } = useTokenPrice(tokenData.tokenAddress);
  const { data: amplyPrice } = useAmplyPrice();
  const { data: borrowAPY } = useBorrowAPY(tokenData.tokenAddress);
  const tokenUtilization = useTokenUtilization(tokenData);
  const isBorrowCap = useBorrowCap(tokenData);
  const { data: incentiveData } = useIncentiveAPY();

  const chartData = useMemo(
    () =>
      reserves
        ?.filter((info: ReserveInfoResponse) =>
          isBefore(
            new Date().setDate(new Date().getDate() - 7),
            new Date(info.timestamp),
          ),
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ?.map((r: any) => ({
          date: new Date(r.timestamp),
          value: r.borrowApyPercent.toFixed(2),
        })),
    [reserves],
  );

  const incentiveBorrowDataWithAPY = useMemo(() => {
    const incentiveTokenIndex = incentiveData?.tokenAddress.findIndex(
      (item) => tokenAddressMap[item.toLowerCase()] === tokenData.symbol,
    );

    const incentiveBorrowData =
      incentiveData?.borrowIncentiveData[incentiveTokenIndex!];

    return incentiveBorrowData && incentiveBorrowData.length > 0
      ? filterRewardData(incentiveBorrowData).map((item) => ({
          symbol: item[0],
          apy: calculateAPY(
            item,
            tokenData,
            amplyPrice,
            tokenBorrow,
            tokenPrice,
          ),
        }))
      : undefined;
  }, [incentiveData, tokenData, amplyPrice, tokenBorrow, tokenPrice]);

  const totalIncentiveDetailsAPY = incentiveBorrowDataWithAPY?.reduce(
    (total, item) => (item.apy ? total.add(item.apy) : total),
    Fraction.ZERO,
  );

  return (
    <Information
      data={{
        symbol: tokenData.symbol,
        tokenAPY: borrowAPY,
        netAPY: totalIncentiveDetailsAPY
          ? borrowAPY?.sub(totalIncentiveDetailsAPY)
          : borrowAPY,
        incentiveDetails: incentiveBorrowDataWithAPY,
        chartData,
        tokenAmount: tokenBorrow,
        tokenUSDValue: tokenPrice,
        utilization: tokenUtilization,
        isCapReached: isBorrowCap,
      }}
      type="borrow"
    />
  );
};

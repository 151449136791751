/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, Interface } from "ethers";
const _abi = [
    {
        inputs: [
            {
                internalType: "contract IPoolAddressesProvider",
                name: "provider",
                type: "address",
            },
            {
                internalType: "address[]",
                name: "assets",
                type: "address[]",
            },
            {
                internalType: "address[]",
                name: "sources",
                type: "address[]",
            },
            {
                internalType: "address",
                name: "fallbackOracle",
                type: "address",
            },
            {
                internalType: "address",
                name: "baseCurrency",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "baseCurrencyUnit",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "asset",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "source",
                type: "address",
            },
        ],
        name: "AssetSourceUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "baseCurrency",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "baseCurrencyUnit",
                type: "uint256",
            },
        ],
        name: "BaseCurrencySet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "fallbackOracle",
                type: "address",
            },
        ],
        name: "FallbackOracleUpdated",
        type: "event",
    },
    {
        inputs: [],
        name: "ADDRESSES_PROVIDER",
        outputs: [
            {
                internalType: "contract IPoolAddressesProvider",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "BASE_CURRENCY",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "BASE_CURRENCY_UNIT",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "asset",
                type: "address",
            },
        ],
        name: "getAssetPrice",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "assets",
                type: "address[]",
            },
        ],
        name: "getAssetsPrices",
        outputs: [
            {
                internalType: "uint256[]",
                name: "",
                type: "uint256[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getFallbackOracle",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "asset",
                type: "address",
            },
        ],
        name: "getSourceOfAsset",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "assets",
                type: "address[]",
            },
            {
                internalType: "address[]",
                name: "sources",
                type: "address[]",
            },
        ],
        name: "setAssetSources",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "fallbackOracle",
                type: "address",
            },
        ],
        name: "setFallbackOracle",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
export class AmplyOracle__factory {
    static createInterface() {
        return new Interface(_abi);
    }
    static connect(address, runner) {
        return new Contract(address, _abi, runner);
    }
}
AmplyOracle__factory.abi = _abi;

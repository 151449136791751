import { Button, Link, Text, VStack } from '@chakra-ui/react';
import {
  ModalOrDrawerBody,
  ModalOrDrawerFooter,
} from '@ui/components/ModalOrDrawer';
import { Num } from '@ui/components/Num';
import { getExplorerLink } from '@ui/utils/getExplorerLink';

import { useCollateralDrawer } from './hooks/useCollateralDrawer';

export const AsCollateralSuccessContent = ({
  type,
}: {
  type: 'enableASCollateral' | 'disableAsCollateral';
}) => {
  const isEnableAsCollateral = type === 'enableASCollateral';

  const [closeModal, txHash, tokenSymbol, amount] = useCollateralDrawer((s) => [
    s.close,
    s.txHash,
    s.tokenSymbol,
    s.amount,
  ]);

  return (
    <>
      <ModalOrDrawerBody>
        <VStack>
          <Text textStyle={'h3'} mt="24px">
            Transaction complete
          </Text>
          <Text
            as="div"
            mb={5}
            width={'250px'}
            textAlign={'center'}
            textStyle="body1"
            color={'primary.text2'}
          >
            You {isEnableAsCollateral ? 'enable' : 'disabled'} collateral{' '}
            <Text display={'inline-block'} as="div">
              <Num value={amount} suffix={tokenSymbol} />
            </Text>
          </Text>
          <Link
            href={getExplorerLink(txHash ?? '', 'transaction')}
            isExternal
            color="primary.cta"
            target="_blank"
            textStyle={'cta'}
          >
            View on CronosZK Explorer
          </Link>
        </VStack>
      </ModalOrDrawerBody>
      <ModalOrDrawerFooter>
        <Button width={'full'} onClick={closeModal}>
          Done
        </Button>
      </ModalOrDrawerFooter>
    </>
  );
};

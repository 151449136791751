export enum Platform {
  Ios,
  Android,
  OtherMobile,
  Desktop,
}

interface Window {
  opera?: string;
}

declare const window: Window;

export const getPlatform = (): Platform | undefined => {
  if (typeof window === 'undefined') return undefined;

  const ua = (navigator.userAgent || navigator.vendor || window.opera) ?? '';

  if (/android/i.test(ua)) {
    return Platform.Android;
  }

  if (
    /iPhone|iPad|iPod/i.test(ua) ||
    // iPad on iOS 13 detection
    (/Mac/i.test(ua) && 'ontouchend' in document)
  ) {
    return Platform.Ios;
  }

  if (/BlackBerry|Opera Mini|IEMobile|WPDesktop/i.test(ua)) {
    return Platform.OtherMobile;
  }

  return Platform.Desktop;
};

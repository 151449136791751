import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';

export const Divider = ({ ...props }: BoxProps) => {
  return (
    <Box {...props} pos={'relative'} h={'1px'} bg="primary.divider" my={2.5}>
      <Box
        pos={'absolute'}
        bg="primary.divider"
        h="3px"
        w="10px"
        left={0}
        top={'-1px'}
      />
      <Box
        pos={'absolute'}
        bg="primary.divider"
        h={'3px'}
        w="3px"
        left={'50%'}
        transform={'translateX(-50%)'}
        top={'-1px'}
      />
    </Box>
  );
};

// smart contracts related configurations/constants
export const USD_DECIMALS = 18;
export const CRO_DECIMALS = 18;
export const vETH_DECIMALS = 18;
export const vUSD_DECIMALS = 18;
export const wzkCRO_DECIMALS = 18;

// https://docs.aave.com/developers/v/2.0/guides/apy-and-apr
export const RAY_UNIT = 27;

/**
 * uint256 public constant HEALTH_FACTOR_LIQUIDATION_THRESHOLD = 1e18;
 */
export const HEALTH_FACTOR_UNIT = 18;
export const LTV_UNIT = 4;

import { Onboarding } from '@amply-app/icons';
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { useUserReservesIncentivesData } from '@ui/hooks/data/useUserReservesIncentivesData';
import { Fraction } from 'bi-fraction';
import React from 'react';

import { InDesktop } from '../MobileOrDesktop';
import { useOnboardingModal } from '../OnboardingModal/useOnboardingModal';
import Earnings from './Panels/Earnings';

const NoBorrowedOrSuppliedGuild = () => {
  const openModal = useOnboardingModal((s) => s.openModal);
  const { data: userReservesIncentivesData } = useUserReservesIncentivesData();
  const totalAmount = userReservesIncentivesData?.preAmplyTokenAmount;
  return (
    <Flex
      gap={4}
      direction={{
        base: 'column',
        md: 'row',
      }}
    >
      <Flex flexDir={'column'} gap={6} alignItems={'center'} px={4}>
        <Text color="primary.text2" textStyle={'body1'} textAlign={'center'}>
          {
            "Haven't borrowed or supplied any assets yet? Click on the button below to learn how to do it"
          }
        </Text>
        <Icon
          role="button"
          as={Onboarding}
          color={'primary.cta'}
          boxSize="65px"
          onClick={openModal}
        />
      </Flex>
      <InDesktop>
        {totalAmount?.gt(Fraction.ZERO) && (
          <Box px={4}>
            <Earnings />
          </Box>
        )}
      </InDesktop>
    </Flex>
  );
};

export default NoBorrowedOrSuppliedGuild;

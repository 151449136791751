import { useSupplyAndBorrowModal } from '@ui/components/SupplyAndBorrowModal';
import { Fraction } from 'bi-fraction';
import { useMemo } from 'react';

import type { UseAllPoolsData } from '../data';
import { useBorrowCap, useTokenPrice } from '../data';
import { useUserAccountData } from '../data/useUserAccountData';
import { checkNewHealthFactor } from './utils';

export const useValidationBorrow = (
  borrowAmount: Fraction,
  tokenData?: UseAllPoolsData,
): string | undefined => {
  const isBorrowCapReached = useBorrowCap(tokenData, borrowAmount);
  const { data: tokenPrice } = useTokenPrice(tokenData?.tokenAddress);
  const { data: userAccountData } = useUserAccountData();
  const [isMaxAmount, isBorrowRiskAgreed] = useSupplyAndBorrowModal((s) => [
    s.isMaxAmount,
    s.isBorrowRiskAgreed,
  ]);

  const setSupplyAndBorrowModal = (msg: string) => {
    useSupplyAndBorrowModal.setState(() => ({
      errorMsg: msg,
    }));
  };
  return useMemo(() => {
    if (
      !tokenData ||
      borrowAmount.lte(Fraction.ZERO) ||
      !userAccountData ||
      !tokenPrice
    ) {
      setSupplyAndBorrowModal('no data');
      return;
    }
    const newHealthFactorError = checkNewHealthFactor(
      borrowAmount.mul(tokenPrice),
      userAccountData,
    );
    if (newHealthFactorError) {
      setSupplyAndBorrowModal(newHealthFactorError);
      return newHealthFactorError;
    }

    if (isBorrowCapReached) {
      setSupplyAndBorrowModal('borrow cap reached');
      return 'borrow cap reached';
    }

    if (borrowAmount.mul(tokenPrice).gt(userAccountData.availableBorrowsBase)) {
      setSupplyAndBorrowModal('exceeds max borrowable amount');
      return 'exceeds max borrowable amount';
    }

    if (borrowAmount.gt(tokenData.availableLiquidity)) {
      setSupplyAndBorrowModal(
        `exceeds max market liquidity: ${tokenData.availableLiquidity.toFormat({
          decimalPlaces: 4,
        })}`,
      );
      return `exceeds max market liquidity: ${tokenData.availableLiquidity.toFormat(
        { decimalPlaces: 4 },
      )}`;
    }

    if (isMaxAmount && !isBorrowRiskAgreed) {
      setSupplyAndBorrowModal('Should agree to the risk acknowledgment');
      return;
    }

    setSupplyAndBorrowModal('');
    return undefined;
  }, [
    tokenData,
    borrowAmount,
    userAccountData,
    tokenPrice,
    isBorrowCapReached,
    isMaxAmount,
    isBorrowRiskAgreed,
  ]);
};

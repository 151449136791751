import type { FlexProps } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

export type InfoProps = Omit<FlexProps, 'content' | 'title'> & {
  title: ReactNode;
  content: ReactNode;
  usdContent?: ReactNode;
  linkContent?: ReactNode;
};
export const Info = forwardRef<HTMLDivElement, InfoProps>(
  ({ title, content, usdContent, linkContent, ...props }, ref) => {
    return (
      <Flex ref={ref} direction="column" gap="4px" {...props}>
        <Flex justify="space-between">
          <Flex
            gap="4px"
            alignItems="center"
            textStyle={{
              base: 'body',
              desktop: 'body2',
            }}
          >
            {title}
          </Flex>
          <Flex
            gap="4px"
            alignItems="center"
            textStyle={{
              base: 'body1Bold',
              desktop: 'h4',
            }}
          >
            {content}
          </Flex>
        </Flex>
        {usdContent && (
          <Flex
            gap="4px"
            alignItems="center"
            justifyContent="flex-end"
            textStyle="body"
            color="primary.text2"
            _dark={{
              color: '#C9D0E6',
            }}
          >
            {usdContent}
          </Flex>
        )}
        {linkContent && (
          <Flex
            gap="4px"
            alignItems="center"
            justifyContent="flex-end"
            textStyle="cta"
          >
            {linkContent}
          </Flex>
        )}
      </Flex>
    );
  },
);

Info.displayName = 'Info';

import type { Fraction } from 'bi-fraction';

export enum WithdrawOrRepay {
  Withdraw = 'Withdraw',
  Repay = 'Repay',
}

export interface UseWithdrawAndRepayModal {
  tokenSymbol?: string;
  tokenAddress?: string;
  amount?: Fraction;
  amountStr?: string;
  isCollateral: boolean;
  type: WithdrawOrRepay;
  tokenBalance?: Fraction;
  tokenUSDPrice?: Fraction;
  inputError?: string;
  inputWarning?: string;
  isMaxAmount: boolean;
  isAcknowledged?: boolean;

  setMaxAmount: (isMaxAmount: boolean) => void;
  setTokenSymbol: (tokenSymbol: string) => void;
  setTokenAddress: (tokenAddress: string) => void;
  setAmountStr: (amountStr: string) => void;
  setIsCollateral: (isCollateral: boolean) => void;
  setType: (type: WithdrawOrRepay) => void;
  setTokenBalance: (tokenBalance: Fraction) => void;
  setTokenUSDPrice: (tokenUSDPrice: Fraction) => void;
  setInputError: (inputError?: string) => void;
  setInputWarning: (inputWarning?: string) => void;
}

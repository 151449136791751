import {
  Box,
  Button,
  Card,
  HStack,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { usePoineerProgram } from '@ui/hooks/data';
import Image from 'next/image';

export const PioneerProgram = () => {
  const { data: userRanking } = usePoineerProgram();
  return (
    <Card
      background={
        'linear-gradient(239deg, rgba(217, 217, 217, 0.10) 0.93%, rgba(255, 255, 255, 0.00) 45.88%, rgba(255, 255, 255, 0.08) 99.71%)'
      }
      px={4}
      py={3}
      my={3}
    >
      <VStack gap={0.5} alignItems={'flex-start'}>
        <Text textStyle={'cta'}>
          Cronos zkEVM Pioneer Program (Total Points Earned)
        </Text>
        <HStack>
          <Text textStyle={'body2'} borderBottom={'dashed'}>
            {userRanking?.points ?? 0}
          </Text>

          <Image
            src="/images/points.svg"
            width={'24'}
            height={'24'}
            alt="points"
          />
          {userRanking?.rank && (
            <Box bg={'gradient.h2Gradient'} borderRadius={'5'} p={1}>
              <Text color={'primary.h2_gray'} textStyle={'captionBold'}>
                {userRanking?.rank}th
              </Text>
            </Box>
          )}
        </HStack>

        <Button
          as={Link}
          variant={'link'}
          href={EXTERNAL_LINK.poinnerMissons}
          isExternal
        >
          Learn more
        </Button>
      </VStack>
    </Card>
  );
};

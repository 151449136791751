import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { getSDK } from '@ui/utils';

export const usePaymasterAllowedTokens = (): UseQueryResult<string[]> => {
  const sdk = getSDK();
  return useQuery(['usePaymasterAllowedTokens'], async () => {
    const allowedTokens = await sdk.getPaymaster().getAllowedTokens();
    return allowedTokens;
  });
};

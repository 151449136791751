import type { Fraction } from 'bi-fraction';

/**
 * Enumeration for representing units in thousands (K), millions (M), and billions (B).
 *
 * @readonly
 * @enum {string}
 */
export enum Unit {
  K = 'K',
  M = 'M',
  B = 'B',
}

export const getFractionUnit = (value: Fraction): [Fraction, Unit?] => {
  if (value.lt(1e3)) return [value];
  if (value.gte(1e3) && value.lt(1e6)) return [value.div(1e3), Unit.K];
  if (value.gte(1e6) && value.lt(1e9)) return [value.div(1e6), Unit.M];

  return [value.div(1e9), Unit.B];
};

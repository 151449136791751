import { currentWallet } from '@amply-app/wallet';
import { useMutation } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getGasOverrides, getPaymasterParams, getSDK } from '@ui/utils';
import { BrowserProvider } from 'zksync-ethers';

import { useAllPoolsData } from './useAllPoolsData';
import { useUserReservesIncentivesData } from './useUserReservesIncentivesData';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useClaimAllRewardsToSelf = () => {
  const refetchUserReservesIncentivesData =
    useUserReservesIncentivesData().refetch;
  const account = useAccount();
  const provider = useProvider();
  const chainId = useChainId();
  const connectorProvider = getConnector().provider;
  const { data: allPoolsData } = useAllPoolsData();
  const gasTokenSymbol = usePaymaster((s) => s.token);
  const gasTokenAddress =
    allPoolsData?.find((token) => token.symbol === gasTokenSymbol)
      ?.tokenAddress ?? '';

  return useMutation(
    async ({ tokenAddresses }: { tokenAddresses: string[] }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;

      const sdk = getSDK();
      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();

      let overrides = {};
      if (gasTokenSymbol !== tokenMap.zkCRO) {
        const paymasterParams = getPaymasterParams({ gasTokenAddress });
        const gasLimit = await sdk
          .getRewardsController(browserSigner)
          .claimAllRewardsToSelf.estimateGas(tokenAddresses);
        overrides = await getGasOverrides({ gasLimit, paymasterParams });
      }

      const tx = await sdk
        .getRewardsController(browserSigner)
        .claimAllRewardsToSelf(tokenAddresses, overrides);

      return await tx.wait();
    },
    {
      onSuccess: () => refetchUserReservesIncentivesData(),
    },
  );
};

import type { ContractAddressMap } from '@amply-app/sdk';
import { getContractAddress } from '@amply-app/sdk';
import { currentWallet } from '@amply-app/wallet';
import { useMutation } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import {
  getGasOverrides,
  getPaymasterParams,
  getSDK,
} from '@ui/utils';
import type { Fraction } from 'bi-fraction';
import { BrowserProvider } from 'zksync-ethers';

import { INTEREST_RATE_MODE } from '../data/constants';
import { useAllPoolsData } from '../data/useAllPoolsData';
import { approveIfNeeded } from '../data/utils';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useRepayMutation = () => {
  const account = useAccount();
  const provider = useProvider();
  const chainId = useChainId();
  const connectorProvider = getConnector().provider;
  const { data: allPoolsData } = useAllPoolsData();
  const gasTokenSymbol = usePaymaster((s) => s.token);
  const gasTokenAddress =
    allPoolsData?.find((token) => token.symbol === gasTokenSymbol)
      ?.tokenAddress ?? '';
  return useMutation(
    async ({
      amount,
      tokenSymbol,
      isMaxAmount,
    }: {
      amount: Fraction;
      tokenSymbol: keyof ContractAddressMap | 'zkCRO';
      isMaxAmount: boolean;
    }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;
      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();
      const sdk = getSDK();

      const assetAddress = getContractAddress(
        chainId,
        tokenSymbol === 'zkCRO' ? 'wzkCRO' : tokenSymbol,
      );
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();

      const token = sdk.getERC20(assetAddress, browserSigner);
      const decimals = await token.decimals();

      let overrides = {};
      if (gasTokenSymbol !== tokenMap.zkCRO) {
        const paymasterParams = getPaymasterParams({ gasTokenAddress });
        let gasLimit;
        const txAmount = isMaxAmount
          ? amount.gte(0.005)
            ? amount.add(amount.mul(0.001))
            : amount.add(0.01)
          : amount;
        if (tokenSymbol === tokenMap.zkCRO) {
          const poolAddressesProvider = sdk.getContractAddress(
            'PoolAddressesProvider',
          );

          gasLimit = await sdk
            .getWrappedTokenGatewayV3(browserSigner)
            .repayETH.estimateGas(
              poolAddressesProvider,
              txAmount.shl(Number(decimals)).quotient,
              INTEREST_RATE_MODE,
              account,
              {
                value: txAmount.shl(Number(decimals)).quotient,
              },
            );
        } else {
          await approveIfNeeded({
            account,
            spender: poolAddress,
            amount: txAmount.shl(Number(decimals)).quotient,
            token,
            gasTokenAddress,
            gasTokenSymbol,
          });
          gasLimit = await sdk
            .getL2Pool(poolAddress, browserSigner)
            ['repay(address,uint256,uint256,address)'].estimateGas(
              assetAddress,
              txAmount.shl(Number(decimals)).quotient,
              INTEREST_RATE_MODE,
              account,
            );
        }
        overrides = await getGasOverrides({ gasLimit, paymasterParams });
      }

      let tx;

      const txAmount = isMaxAmount
        ? amount.gte(0.005)
          ? amount.add(amount.mul(0.001))
          : amount.add(0.01)
        : amount;
      if (tokenSymbol === 'zkCRO') {
        const poolAddressesProvider = sdk.getContractAddress(
          'PoolAddressesProvider',
        );
        tx = await sdk
          .getWrappedTokenGatewayV3(browserSigner)
          .repayETH(
            poolAddressesProvider,
            txAmount.shl(Number(decimals)).quotient,
            INTEREST_RATE_MODE,
            account,
            {
              value: txAmount.shl(Number(decimals)).quotient,
              ...overrides,
            },
          );
      } else {
        await approveIfNeeded({
          account,
          spender: poolAddress,
          amount: txAmount.shl(Number(decimals)).quotient,
          token,
          gasTokenAddress,
          gasTokenSymbol,
        });
        tx = await sdk
          .getL2Pool(poolAddress, browserSigner)
          ['repay(address,uint256,uint256,address)'](
            assetAddress,
            txAmount.shl(Number(decimals)).quotient,
            INTEREST_RATE_MODE,
            account,
            overrides,
          );
      }
      return await tx.wait();
    },
  );
};

import type { BoxProps } from '@chakra-ui/react';
import { Box, useBreakpointValue } from '@chakra-ui/react';

interface InMobileProps {
  children: BoxProps['children'];
}

/**
 * using css instead of JS, avoid the UI flickering
 */
export const InMobile = ({ children }: InMobileProps) => {
  return (
    <Box
      display={{
        // using `contents` to ignore this <Box>'s layout
        base: 'contents',
        desktop: 'none',
      }}
    >
      {children}
    </Box>
  );
};

interface InDesktopProps {
  children: BoxProps['children'];
}

/**
 * using css instead of JS, avoid the UI flickering
 */
export const InDesktop = ({ children }: InDesktopProps) => {
  return (
    <Box
      display={{
        base: 'none',
        // using `contents` to ignore this <Box>'s layout
        desktop: 'contents',
      }}
    >
      {children}
    </Box>
  );
};

/**
 * using <RenderInMobile /> only if it won't cause UI flickering
 */
export const RenderInMobile = ({ children }: InMobileProps) => {
  return <>{useIsDesktop() === false ? children : undefined}</>;
};

/**
 * using <RenderInDesktop /> only if it won't cause UI flickering
 */
export const RenderInDesktop = ({ children }: InDesktopProps) => {
  return <>{useIsDesktop() === true ? children : undefined}</>;
};

export const useIsDesktop = (): undefined | boolean => {
  return useBreakpointValue(
    {
      base: false,
      desktop: true,
    },
    {
      // ssr: typeof window === 'undefined',
    },
  );
};

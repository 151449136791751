import { getContractAddress } from '@amply-app/sdk';
import { currentWallet } from '@amply-app/wallet';
import { useMutation } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getGasOverrides, getPaymasterParams, getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import { MaxInt256 } from 'ethers';
import { BrowserProvider } from 'zksync-ethers';

import { INTEREST_RATE_MODE, REFERRAL_CODE } from '../data/constants';
import { type UseAllPoolsData, useAllPoolsData } from '../data/useAllPoolsData';
import { checkZKCROAddress } from '../data/utils';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useBorrowMutation = () => {
  const account = useAccount();
  const chainId = useChainId();
  const provider = useProvider();
  const connectorProvider = getConnector().provider;
  const { data: allPoolsData } = useAllPoolsData();
  const gasTokenSymbol = usePaymaster((s) => s.token);
  const gasTokenAddress =
    allPoolsData?.find((token) => token.symbol === gasTokenSymbol)
      ?.tokenAddress ?? '';
  return useMutation(
    async ({
      amount,
      tokenData,
    }: {
      amount: Fraction;
      tokenData: UseAllPoolsData;
    }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;

      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();
      const sdk = getSDK();
      const tokenAddress = checkZKCROAddress(tokenData.tokenAddress);
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();

      const token = sdk.getERC20(tokenAddress!, browserSigner);
      const decimals = await token.decimals();

      let overrides = {};
      if (gasTokenSymbol !== tokenMap.zkCRO) {
        const paymasterParams = getPaymasterParams({ gasTokenAddress });
        let gasLimit;
        if (tokenData.symbol === tokenMap.zkCRO) {
          const gatewayAddress = getContractAddress(
            chainId,
            'WrappedTokenGatewayV3',
          );
          const wzkCROVarDebtToken = tokenData.variableDebtToken;

          const borrowAllowance = await sdk
            .getVariableDebtToken(wzkCROVarDebtToken, browserSigner)
            .borrowAllowance(account, gatewayAddress);

          if (new Fraction(borrowAllowance).lt(amount)) {
            const approvalGasLimit = await sdk
              .getVariableDebtToken(wzkCROVarDebtToken, browserSigner)
              .approveDelegation.estimateGas(gatewayAddress, MaxInt256);
            const approvalOverrides = await getGasOverrides({
              gasLimit: approvalGasLimit,
              paymasterParams,
            });
            const approvalTx = await sdk
              .getVariableDebtToken(wzkCROVarDebtToken, browserSigner)
              .approveDelegation(gatewayAddress, MaxInt256, approvalOverrides);
            await approvalTx.wait();
          }

          gasLimit = await sdk
            .getWrappedTokenGatewayV3(browserSigner)
            .borrowETH.estimateGas(
              poolAddress,
              amount.shl(Number(decimals)).quotient,
              INTEREST_RATE_MODE,
              REFERRAL_CODE,
            );
        } else {
          gasLimit = await sdk
            .getL2Pool(poolAddress, browserSigner)
            ['borrow(address,uint256,uint256,uint16,address)'].estimateGas(
              tokenAddress!,
              amount.shl(Number(decimals)).quotient,
              INTEREST_RATE_MODE,
              REFERRAL_CODE,
              account,
            );
        }
        overrides = await getGasOverrides({ gasLimit, paymasterParams });
      }

      let tx;

      if (tokenData.symbol === tokenMap.zkCRO) {
        // if gasTokenSymbol is not zkCRO, it has been approved when obtaining the gas limit
        if (gasTokenSymbol === tokenMap.zkCRO) {
          // Borrow via gateway needs approveDelegation
          const gatewayAddress = getContractAddress(
            chainId,
            'WrappedTokenGatewayV3',
          );
          const wzkCROVarDebtToken = tokenData.variableDebtToken;

          const borrowAllowance = await sdk
            .getVariableDebtToken(wzkCROVarDebtToken, browserSigner)
            .borrowAllowance(account, gatewayAddress);

          if (new Fraction(borrowAllowance).lt(amount)) {
            const approvalTx = await sdk
              .getVariableDebtToken(wzkCROVarDebtToken, browserSigner)
              .approveDelegation(gatewayAddress, MaxInt256);
            await approvalTx.wait();
          }
        }

        tx = await sdk
          .getWrappedTokenGatewayV3(browserSigner)
          .borrowETH(
            poolAddress,
            amount.shl(Number(decimals)).quotient,
            INTEREST_RATE_MODE,
            REFERRAL_CODE,
            overrides,
          );
      } else {
        tx = await sdk
          .getL2Pool(poolAddress, browserSigner)
          ['borrow(address,uint256,uint256,uint16,address)'](
            tokenAddress!,
            amount.shl(Number(decimals)).quotient,
            INTEREST_RATE_MODE,
            REFERRAL_CODE,
            account,
            overrides,
          );
      }
      return await tx.wait();
    },
  );
};

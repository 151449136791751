import type { ContractAddressMap } from '@amply-app/sdk';
import { TxStatus } from '@ui/commonStateController';
import {
  useWithdrawAndRepayModal,
  WithdrawOrRepay,
} from '@ui/components/WithdrawAndRepayModal';
import { useRepayMutation } from '@ui/hooks/mutation/useRepayMutation';
import { useWithdrawMutation } from '@ui/hooks/mutation/useWithdrawMutation';
import { type EthersError } from 'ethers';
import { useCallback } from 'react';

export const useWithdrawOrRepay = (type: WithdrawOrRepay) => {
  const withdrawMutation = useWithdrawMutation();
  const repayMutation = useRepayMutation();
  const mutation =
    type === WithdrawOrRepay.Repay ? repayMutation : withdrawMutation;
  const [amount, tokenSymbol, isMaxAmount, setTxHash, setTxStatus, setTxError] =
    useWithdrawAndRepayModal((s) => [
      s.amount,
      s.tokenSymbol,
      s.isMaxAmount,
      s.setTxHash,
      s.setTxStatus,
      s.setTxError,
    ]);

  return useCallback(async () => {
    try {
      setTxStatus(TxStatus.Loading);

      if (!amount) throw new Error('Amount is required');
      if (!tokenSymbol)
        throw new Error(
          `${
            type === WithdrawOrRepay.Withdraw ? 'Withdraw' : 'Repay'
          } token is required`,
        );

      const tx = await mutation.mutateAsync({
        amount,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        tokenSymbol: tokenSymbol as keyof ContractAddressMap,
        isMaxAmount,
      });
      setTxHash(tx?.hash ?? '');

      setTxStatus(TxStatus.Success);
    } catch (e: unknown) {
      setTxStatus(TxStatus.Failed);
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setTxError(e as EthersError);
    }
  }, [
    setTxStatus,
    amount,
    tokenSymbol,
    type,
    mutation,
    setTxHash,
    setTxError,
    isMaxAmount,
  ]);
};

import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { ModalOrDrawerBody } from '@ui/components/ModalOrDrawer';

export const TransactionLoadingContent = () => {
  return (
    <>
      <ModalOrDrawerBody>
        <Flex ml={5} mr={5} direction="column" height="100%" align="center">
          <Box mb={10} mt={10}>
            <Spinner thickness="6px" size={'xl'} color="primary.cta" />
          </Box>
          <Text mb={5}>Signing transaction in wallet</Text>
        </Flex>
      </ModalOrDrawerBody>
    </>
  );
};

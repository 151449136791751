import { Fraction } from 'bi-fraction';
import { useMemo } from 'react';

import type { UseAllPoolsData } from './useAllPoolsData';
import { useAllPoolsData } from './useAllPoolsData';
import { useUserAccountData } from './useUserAccountData';
import type { UserReserveData } from './useUserReservesData';
import { useUserReservesData } from './useUserReservesData';
import {
  compareZKCROAddress,
  computeDisableAsCollateralLTV,
  computeEnableAsCollateralLTV,
  computeSupplyLTV,
  computeWithdrawLTV,
  LTVType,
} from './utils';

export enum AvailableBorrowType {
  OverAll,
  Withdraw,
  Supply,
  EnableAsCollateral,
  DisableAsCollateral,
}
export const useAvailableBorrow = (
  type: AvailableBorrowType,
  USD: Fraction = Fraction.ZERO,
  tokenAddress?: string,
) => {
  const { data } = useUserAccountData();
  const totalCollateralBase = data?.totalCollateralBase;
  const { data: userReservesData } = useUserReservesData();
  const { data: poolsData } = useAllPoolsData();
  const availableBorrow = useMemo(() => {
    if (
      type === AvailableBorrowType.Supply &&
      userReservesData &&
      poolsData &&
      tokenAddress
    ) {
      const avgMaxLTV = computeSupplyLTV(
        tokenAddress,
        userReservesData,
        poolsData,
        USD,
        LTVType.AvailableBorrow,
      );
      const userReserveData = userReservesData.find(
        (userReserveData) =>
          userReserveData.tokenAddress === compareZKCROAddress(tokenAddress),
      );

      if (
        userReserveData?.asCollateral ||
        (userReserveData?.tokenAddress === compareZKCROAddress(tokenAddress) &&
          userReserveData?.aTokenBalance.eq(0))
      ) {
        return totalCollateralBase?.add(USD).mul(avgMaxLTV);
      } else {
        return totalCollateralBase?.mul(avgMaxLTV);
      }
    } else if (
      type === AvailableBorrowType.DisableAsCollateral &&
      userReservesData &&
      poolsData &&
      tokenAddress
    ) {
      const avgMaxLTV = computeDisableAsCollateralLTV(
        tokenAddress,
        userReservesData,
        poolsData,
        LTVType.AvailableBorrow,
      );
      return totalCollateralBase?.sub(USD).mul(avgMaxLTV);
    } else if (
      type === AvailableBorrowType.EnableAsCollateral &&
      userReservesData &&
      poolsData &&
      tokenAddress
    ) {
      const avgMaxLTV = computeEnableAsCollateralLTV(
        tokenAddress,
        userReservesData,
        poolsData,
        LTVType.AvailableBorrow,
      );
      return totalCollateralBase?.add(USD).mul(avgMaxLTV);
    } else if (
      type === AvailableBorrowType.Withdraw &&
      userReservesData &&
      poolsData &&
      tokenAddress &&
      USD
    ) {
      const avgMaxLTV = computeWithdrawLTV(
        tokenAddress,
        userReservesData,
        poolsData,
        USD,
        LTVType.AvailableBorrow,
      );
      return totalCollateralBase?.sub(USD).mul(avgMaxLTV);
    }
    const avgMaxLTV = computeAvailableBorrow(userReservesData, poolsData);
    return totalCollateralBase?.mul(avgMaxLTV);
  }, [
    tokenAddress,
    userReservesData,
    poolsData,
    USD,
    totalCollateralBase,
    type,
  ]);
  return availableBorrow;
};

export const computeAvailableBorrow = (
  userReservesData?: UserReserveData[],
  poolsData?: UseAllPoolsData[],
) => {
  let numerator = Fraction.ZERO;
  let denominator = Fraction.ZERO;
  if (!userReservesData || !poolsData) return Fraction.ZERO;
  userReservesData.map((userReserveData) => {
    if (userReserveData.asCollateral) {
      const totalSupplyUSD = userReserveData.aTokenBalance.mul(
        userReserveData.tokenUSDPrice,
      );

      const currentLTV = poolsData.find(
        (pool) =>
          compareZKCROAddress(pool.tokenAddress) ===
          userReserveData.tokenAddress,
      )?.baseLTVasCollateral;

      numerator = currentLTV
        ? currentLTV.mul(totalSupplyUSD).add(numerator)
        : Fraction.ZERO;
      denominator = totalSupplyUSD.add(denominator);
    }
  });
  return denominator.gt(Fraction.ZERO)
    ? numerator.div(denominator)
    : Fraction.ZERO;
};

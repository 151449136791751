import type { FlexProps } from '@chakra-ui/react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { type Fraction, RoundingMode } from 'bi-fraction';

const getPercentageItems = () => {
  return [
    { value: 25, label: '25%' },
    { value: 50, label: '50%' },
    { value: 75, label: '75%' },
    { value: 100, label: 'MAX' },
  ] as const;
};

export type PercentageSelectProps = Omit<FlexProps, 'onSelect'> & {
  value: string;
  decimalPlaces: number;
  maxValue: Fraction | undefined;
  repayMaxValue?: Fraction;
  onSelect: (value: string) => void;
  setMaxAmount?: (value: boolean) => void;
  isDisabled?: boolean;
};

export const PercentageSelect: React.FC<PercentageSelectProps> = ({
  value,
  onSelect,
  decimalPlaces,
  maxValue,
  repayMaxValue,
  setMaxAmount,
  isDisabled = false,
  ...props
}) => {
  const percentageItems = getPercentageItems();

  return (
    <Flex
      justifyContent={'space-around'}
      gap={{
        base: '10px',
        desktop: '24px',
      }}
      width={'full'}
      {...props}
    >
      {percentageItems.map((item) => {
        let selectValue: string | undefined;
        if (item.value === 100 && repayMaxValue) {
          // Use repayMaxValue for the MAX button if provided
          selectValue = repayMaxValue.toFixed(decimalPlaces, {
            roundingMode: RoundingMode.ROUND_DOWN,
            trailingZeros: false,
          });
        } else {
          // Calculate the percentage of maxValue for other buttons
          selectValue = maxValue
            ?.mul(item.value)
            .div(100)
            .toFixed(decimalPlaces, {
              roundingMode: RoundingMode.ROUND_DOWN,
              trailingZeros: false,
            });
        }
        const isActive = Number(value) !== 0 && value === selectValue;
        const bgColor = isActive ? 'primary.cta' : undefined;
        return (
          <Button
            key={item.value}
            variant={'percentage'}
            bg={bgColor}
            _hover={{
              bg: bgColor,
            }}
            onClick={() => {
              if (isDisabled || !selectValue) return;
              setMaxAmount?.(item.value === 100);
              onSelect(selectValue);
            }}
            isDisabled={isDisabled}
          >
            <Text textStyle="cta">{item.label}</Text>
          </Button>
        );
      })}
    </Flex>
  );
};

import { getContractAddress } from '@amply-app/sdk';
import { Fraction } from 'bi-fraction';
import { MaxUint256 } from 'ethers';
import { utils } from 'zksync-ethers';
import type { PaymasterParams } from 'zksync-ethers/build/types';

import { getChainId } from './getChainId';

export const getPaymasterParams = ({
  gasTokenAddress,
}: {
  gasTokenAddress: string;
}) => {
  const chainId = getChainId();
  const paymasterAddress = getContractAddress(chainId, 'Paymaster');
  const paymasterParams = utils.getPaymasterParams(paymasterAddress, {
    type: 'ApprovalBased',
    token: gasTokenAddress,
    minimalAllowance: MaxUint256,
    innerInput: new Uint8Array(),
  });
  return paymasterParams;
};

export const getCustomData = ({
  paymasterParams,
}: {
  paymasterParams: PaymasterParams;
}) => {
  return {
    customData: {
      gasPerPubdata: utils.DEFAULT_GAS_PER_PUBDATA_LIMIT,
      paymasterParams,
    },
  };
};

export const getGasOverrides = async ({
  gasLimit,
  paymasterParams,
}: {
  gasLimit: bigint;
  paymasterParams: PaymasterParams;
}) => {
  const scaledGasLimit = new Fraction(gasLimit)
    .add(new Fraction(60000))
    .mul(new Fraction(11000))
    .div(new Fraction(10000));
  const newGasLimit = BigInt(
    scaledGasLimit.numerator / scaledGasLimit.denominator,
  );

  return {
    maxPriorityFeePerGas: BigInt(1),
    gasLimit: newGasLimit,
    ...getCustomData({ paymasterParams }),
  };
};

import { currentWallet } from '@amply-app/wallet';
import { Box } from '@chakra-ui/react';
import type { UserReserveData } from '@ui/hooks/data/useUserReservesData';
import React from 'react';
import { create } from 'zustand';

import UserPortfolio from './UserPortfolio';

export interface CardData extends UserReserveData {
  label?: string;
  symbol: string;
}

export const useReservesData = create<{
  data?: CardData[];
  setData: (data: CardData[]) => void;
}>((set) => ({
  data: undefined,
  setData: (data) => {
    set({ data });
  },
}));

const DashboardOverview = () => {
  const isConnectedToSupportedChain =
    currentWallet.useIsConnectedToSupportedChain();

  if (!isConnectedToSupportedChain) return null;

  return (
    <Box mt={{ base: 2, desktop: 8 }}>
      <UserPortfolio />
    </Box>
  );
};

export default DashboardOverview;

import type {
  ModalController,
  ModalOverride,
  TxStatusController,
  TxStatusOverride,
} from '@ui/commonStateController';
import {
  createModalController,
  createTxStatusController,
  TxStatus,
} from '@ui/commonStateController';
import type { Fraction } from 'bi-fraction';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';

export enum CollateralDrawerType {
  Enable,
  Disable,
}

export interface CollateralDrawer {
  type: CollateralDrawerType;
  amount?: Fraction;
  tokenSymbol?: string;
  tokenAddress?: string;
  tokenUSDValue?: Fraction;

  setAmount: (amount: Fraction) => void;
  setTokenSymbol: (tokenSymbol: string) => void;
  setTokenAddress: (tokenAddress: string) => void;
  setTokenUSDValue: (tokenUSDValue: Fraction) => void;
  setType: (type: CollateralDrawerType) => void;
}

export const createOverride: StateCreator<
  CollateralDrawer & ModalOverride & TxStatusOverride
> = (set) => ({
  setAmount(amount) {
    set({
      amount,
    });
  },
  setTokenSymbol(tokenSymbol) {
    set({
      tokenSymbol,
    });
  },
  setTokenAddress(tokenAddress) {
    set({
      tokenAddress,
    });
  },
  setTokenUSDValue(tokenUSDValue) {
    set({ tokenUSDValue });
  },
  setType(type) {
    set({ type });
  },

  isOpen: false,
  type: CollateralDrawerType.Enable,
  txStatus: TxStatus.Default,
  close() {
    set({
      txStatus: TxStatus.Default,
      isOpen: false,
      txError: undefined,
      txHash: undefined,
    });
  },
});

export const useCollateralDrawer = create<
  ModalController & TxStatusController & CollateralDrawer
>()((...a) => ({
  ...createModalController(...a),
  ...createTxStatusController(...a),
  ...createOverride(...a),
}));

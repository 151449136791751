import { Center, Collapse, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { NoSSR } from '../NoSSR';
import { FpsMonitor } from './FpsMonitor';
import { GlobalAccountInput } from './GlobalAccountInput';
import { GlobalChainSelect } from './GlobalChainSelect';
import { MemoryMonitor } from './MemoryMonitor';

export const DevUtils = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(getMonitorVisibility());
  }, []);
  if (process.env.APP_ENV === 'prod') return null;
  return (
    <NoSSR>
      <Flex
        flexDir="column"
        onClick={() => {
          setShow(!show);
          setMonitorVisibility(!show);
        }}
        position="fixed"
        bottom={0}
        right={0}
        fontSize="10px"
        zIndex="tooltip"
        opacity={0.7}
        background="black"
        rounded="2px"
        px="2px"
        textAlign="right"
        fontFamily="mono"
      >
        <Collapse in={show} animateOpacity>
          <GlobalChainSelect />
          <GlobalAccountInput />
          <MemoryMonitor />
          <FpsMonitor />
        </Collapse>
        {!show && (
          <Center cursor="pointer" color="white" boxSize={6}>
            DEV
          </Center>
        )}
      </Flex>
    </NoSSR>
  );
};

const KEY = '__MONITOR_VISIBILITY__';
const getMonitorVisibility = () => localStorage.getItem(KEY) === 'true';
const setMonitorVisibility = (visible: boolean) =>
  localStorage.setItem(KEY, String(visible));

/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, Interface } from "ethers";
const _abi = [
    {
        inputs: [
            {
                internalType: "address",
                name: "emissionManager",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "asset",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "reward",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "assetIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "userIndex",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "rewardsAccrued",
                type: "uint256",
            },
        ],
        name: "Accrued",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "asset",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "reward",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "oldEmission",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newEmission",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "oldDistributionEnd",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newDistributionEnd",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "assetIndex",
                type: "uint256",
            },
        ],
        name: "AssetConfigUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "claimer",
                type: "address",
            },
        ],
        name: "ClaimerSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "reward",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "rewardOracle",
                type: "address",
            },
        ],
        name: "RewardOracleUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "reward",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "claimer",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "RewardsClaimed",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "reward",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "transferStrategy",
                type: "address",
            },
        ],
        name: "TransferStrategyInstalled",
        type: "event",
    },
    {
        inputs: [],
        name: "EMISSION_MANAGER",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "REVISION",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "assets",
                type: "address[]",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
        ],
        name: "claimAllRewards",
        outputs: [
            {
                internalType: "address[]",
                name: "rewardsList",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "claimedAmounts",
                type: "uint256[]",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "assets",
                type: "address[]",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
        ],
        name: "claimAllRewardsOnBehalf",
        outputs: [
            {
                internalType: "address[]",
                name: "rewardsList",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "claimedAmounts",
                type: "uint256[]",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "assets",
                type: "address[]",
            },
        ],
        name: "claimAllRewardsToSelf",
        outputs: [
            {
                internalType: "address[]",
                name: "rewardsList",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "claimedAmounts",
                type: "uint256[]",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "assets",
                type: "address[]",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
        ],
        name: "claimRewards",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "assets",
                type: "address[]",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
        ],
        name: "claimRewardsOnBehalf",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "assets",
                type: "address[]",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
        ],
        name: "claimRewardsToSelf",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "uint88",
                        name: "emissionPerSecond",
                        type: "uint88",
                    },
                    {
                        internalType: "uint256",
                        name: "totalSupply",
                        type: "uint256",
                    },
                    {
                        internalType: "uint32",
                        name: "distributionEnd",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "asset",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "reward",
                        type: "address",
                    },
                    {
                        internalType: "contract ITransferStrategyBase",
                        name: "transferStrategy",
                        type: "address",
                    },
                    {
                        internalType: "contract IEACAggregatorProxy",
                        name: "rewardOracle",
                        type: "address",
                    },
                ],
                internalType: "struct RewardsDataTypes.RewardsConfigInput[]",
                name: "config",
                type: "tuple[]",
            },
        ],
        name: "configureAssets",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "assets",
                type: "address[]",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "getAllUserRewards",
        outputs: [
            {
                internalType: "address[]",
                name: "rewardsList",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "unclaimedAmounts",
                type: "uint256[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "asset",
                type: "address",
            },
        ],
        name: "getAssetDecimals",
        outputs: [
            {
                internalType: "uint8",
                name: "",
                type: "uint8",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "asset",
                type: "address",
            },
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
        ],
        name: "getAssetIndex",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "getClaimer",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "asset",
                type: "address",
            },
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
        ],
        name: "getDistributionEnd",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getEmissionManager",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
        ],
        name: "getRewardOracle",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "asset",
                type: "address",
            },
        ],
        name: "getRewardsByAsset",
        outputs: [
            {
                internalType: "address[]",
                name: "",
                type: "address[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "asset",
                type: "address",
            },
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
        ],
        name: "getRewardsData",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getRewardsList",
        outputs: [
            {
                internalType: "address[]",
                name: "",
                type: "address[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
        ],
        name: "getTransferStrategy",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
        ],
        name: "getUserAccruedRewards",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "address",
                name: "asset",
                type: "address",
            },
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
        ],
        name: "getUserAssetIndex",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "assets",
                type: "address[]",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
        ],
        name: "getUserRewards",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "totalSupply",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "userBalance",
                type: "uint256",
            },
        ],
        name: "handleAction",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "address",
                name: "caller",
                type: "address",
            },
        ],
        name: "setClaimer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "asset",
                type: "address",
            },
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
            {
                internalType: "uint32",
                name: "newDistributionEnd",
                type: "uint32",
            },
        ],
        name: "setDistributionEnd",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "asset",
                type: "address",
            },
            {
                internalType: "address[]",
                name: "rewards",
                type: "address[]",
            },
            {
                internalType: "uint88[]",
                name: "newEmissionsPerSecond",
                type: "uint88[]",
            },
        ],
        name: "setEmissionPerSecond",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
            {
                internalType: "contract IEACAggregatorProxy",
                name: "rewardOracle",
                type: "address",
            },
        ],
        name: "setRewardOracle",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
            {
                internalType: "contract ITransferStrategyBase",
                name: "transferStrategy",
                type: "address",
            },
        ],
        name: "setTransferStrategy",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
export class RewardsController__factory {
    static createInterface() {
        return new Interface(_abi);
    }
    static connect(address, runner) {
        return new Contract(address, _abi, runner);
    }
}
RewardsController__factory.abi = _abi;

const DEFAULT_FONT_FAMILY = 'Saira, SF Pro Text, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif';
export const fontFamilyVariable = {
    default: DEFAULT_FONT_FAMILY,
    heading: DEFAULT_FONT_FAMILY,
    slogan: '--cronos-font-slogan',
};
export const fontFamily = {
    default: fontFamilyVariable.default,
    heading: fontFamilyVariable.heading,
    slogan: fontFamilyVariable.slogan,
};

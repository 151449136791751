import type { BugsnagStatic } from '@bugsnag/js';
import dynamic from 'next/dynamic';
import type { ReactNode } from 'react';
import React from 'react';

const LazyBugsnagErrorBoundary = dynamic(
  async () => {
    const [Bugsnag, BugsnagPluginReact] = await Promise.all([
      import('@bugsnag/js').then((mod) => mod.default),
      import('@bugsnag/plugin-react').then((mod) => mod.default),
    ]);

    Bugsnag.start({
      // eslint-disable-next-line
      // @ts-expect-error
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
      appVersion: process.env.APP_VERSION,
      releaseStage: process.env.APP_ENV,
      plugins: [new BugsnagPluginReact()],
    });
    // eslint-disable-next-line
    // @ts-expect-error
    return Bugsnag.getPlugin('react').createErrorBoundary(React);
  },
  {
    ssr: false,
  },
);

export const bugsnagNotify = async (
  ...args: Parameters<BugsnagStatic['notify']>
) => {
  if (!process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
    return;
  }
  const Bugsnag = await import('@bugsnag/js').then((mod) => mod.default);
  Bugsnag.notify(...args);
};

export const BugsnagErrorBoundary = ({ children }: { children: ReactNode }) => {
  if (!process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
    return <>{children}</>;
  }

  return <LazyBugsnagErrorBoundary>{children}</LazyBugsnagErrorBoundary>;
};

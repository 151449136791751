/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, Interface } from "ethers";
const _abi = [
    {
        inputs: [
            {
                internalType: "address",
                name: "_router",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "allowedTokenConfig",
        outputs: [
            {
                internalType: "bool",
                name: "isAllowed",
                type: "bool",
            },
            {
                internalType: "uint256",
                name: "minAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "discount",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getAllowedTokens",
        outputs: [
            {
                internalType: "address[]",
                name: "",
                type: "address[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_userAddress",
                type: "address",
            },
            {
                internalType: "address",
                name: "_allowedToken",
                type: "address",
            },
        ],
        name: "getDiscountFactor",
        outputs: [
            {
                internalType: "uint256",
                name: "gasFactor",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes",
                name: "_context",
                type: "bytes",
            },
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "txType",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "from",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "to",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "gasLimit",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "gasPerPubdataByteLimit",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "maxFeePerGas",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "maxPriorityFeePerGas",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "paymaster",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "nonce",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "value",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256[4]",
                        name: "reserved",
                        type: "uint256[4]",
                    },
                    {
                        internalType: "bytes",
                        name: "data",
                        type: "bytes",
                    },
                    {
                        internalType: "bytes",
                        name: "signature",
                        type: "bytes",
                    },
                    {
                        internalType: "bytes32[]",
                        name: "factoryDeps",
                        type: "bytes32[]",
                    },
                    {
                        internalType: "bytes",
                        name: "paymasterInput",
                        type: "bytes",
                    },
                    {
                        internalType: "bytes",
                        name: "reservedDynamic",
                        type: "bytes",
                    },
                ],
                internalType: "struct Transaction",
                name: "_transaction",
                type: "tuple",
            },
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
            {
                internalType: "enum ExecutionResult",
                name: "",
                type: "uint8",
            },
            {
                internalType: "uint256",
                name: "_maxRefundedGas",
                type: "uint256",
            },
        ],
        name: "postTransaction",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "router",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_allowedToken",
                type: "address",
            },
            {
                internalType: "bool",
                name: "_isAllowed",
                type: "bool",
            },
            {
                internalType: "uint256",
                name: "_minAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_discount",
                type: "uint256",
            },
        ],
        name: "setAllowedTokenConfig",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_router",
                type: "address",
            },
        ],
        name: "setRouter",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "txType",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "from",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "to",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "gasLimit",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "gasPerPubdataByteLimit",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "maxFeePerGas",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "maxPriorityFeePerGas",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "paymaster",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "nonce",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "value",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256[4]",
                        name: "reserved",
                        type: "uint256[4]",
                    },
                    {
                        internalType: "bytes",
                        name: "data",
                        type: "bytes",
                    },
                    {
                        internalType: "bytes",
                        name: "signature",
                        type: "bytes",
                    },
                    {
                        internalType: "bytes32[]",
                        name: "factoryDeps",
                        type: "bytes32[]",
                    },
                    {
                        internalType: "bytes",
                        name: "paymasterInput",
                        type: "bytes",
                    },
                    {
                        internalType: "bytes",
                        name: "reservedDynamic",
                        type: "bytes",
                    },
                ],
                internalType: "struct Transaction",
                name: "_transaction",
                type: "tuple",
            },
        ],
        name: "validateAndPayForPaymasterTransaction",
        outputs: [
            {
                internalType: "bytes4",
                name: "magic",
                type: "bytes4",
            },
            {
                internalType: "bytes",
                name: "context",
                type: "bytes",
            },
        ],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "withdrawERC20",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "withdrawETH",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        stateMutability: "payable",
        type: "receive",
    },
];
export class Paymaster__factory {
    static createInterface() {
        return new Interface(_abi);
    }
    static connect(address, runner) {
        return new Contract(address, _abi, runner);
    }
}
Paymaster__factory.abi = _abi;

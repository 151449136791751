import { Box } from '@chakra-ui/react';
import { useAllPoolsData } from '@ui/hooks/data';
import React from 'react';

import { useTokenInputState } from './TokenInput';
import TokenListItem from './TokenListItem';

export const TokenList = () => {
  const { isCollapse } = useTokenInputState();
  const { data: poolsData } = useAllPoolsData();
  if (isCollapse) return null;

  return (
    <Box>
      {poolsData?.map((pool) => (
        <TokenListItem key={pool.symbol} tokenData={pool} />
      ))}
    </Box>
  );
};

import { Borrow, ExpandRight, Supply, SupplyAndBorrow } from '@amply-app/icons';
import { Avatar, Box, Flex, Icon, Text, VStack } from '@chakra-ui/react';
import CardWithBorder from '@ui/components/CardWithBorder';
import { useUserReservesData } from '@ui/hooks/data/useUserReservesData';

import { SupplyAndBorrowType, useSupplyAndBorrowModal } from '.';

enum PanelType {
  Supply,
  Borrow,
  SupplyAndBorrow,
}

interface PanelProps {
  type: PanelType;
  pageType: SupplyAndBorrowType;
  title: string;
  description: string;
  errorMessage?: string;
  isError?: boolean;
}

const iconMap = {
  [PanelType.Supply]: Supply,
  [PanelType.Borrow]: Borrow,
  [PanelType.SupplyAndBorrow]: SupplyAndBorrow,
};

const Panel: React.FC<PanelProps> = ({
  type,
  pageType,
  title,
  description,
  errorMessage,
  isError,
}) => {
  return isError ? (
    <Box backgroundColor="rgba(131, 133, 136, 0.3)">
      <Flex m="4" alignItems="center">
        <Avatar
          h="10"
          w="10"
          bg="primary.disabled"
          opacity="1"
          icon={<Icon h="6" w="6" as={iconMap[type]} color="primary.cta" />}
        />
        <Box ml="3" flex="1">
          <Text color="primary.white">{title}</Text>
          <Text color="primary.text2">{description}</Text>
          <Text color="primary.error">{errorMessage}</Text>
        </Box>
      </Flex>
    </Box>
  ) : (
    <CardWithBorder
      _hover={{ cursor: 'pointer' }}
      onClick={() => {
        useSupplyAndBorrowModal.setState((s) => ({ ...s, type: pageType }));
      }}
      backgroundColor={'primary.card_bg'}
    >
      <Flex m="4" alignItems="center">
        <Avatar
          h="10"
          w="10"
          bg="primary.disabled"
          icon={<Icon h="6" w="6" as={iconMap[type]} color="primary.cta" />}
        />
        <Box ml="3" flex="1">
          <Text color="primary.white">{title}</Text>
          <Text color="primary.text2">{description}</Text>
        </Box>
        <Icon h="6" w="6" as={ExpandRight} color="primary.cta" />
      </Flex>
    </CardWithBorder>
  );
};

export const DefaultContent = () => {
  const { data } = useUserReservesData();
  const isNotSupplied = data?.every((item) => item.aTokenBalance.eq(0));
  const asCollateral = data?.some((item) => item.asCollateral);
  const panels: PanelProps[] = [
    {
      type: PanelType.Supply,
      pageType: SupplyAndBorrowType.Supply,
      title: 'Supply collateral',
      description: 'Deposit your assets to start generating passive income',
    },
    {
      type: PanelType.Borrow,
      pageType: SupplyAndBorrowType.Borrow,
      title: 'Borrow token',
      description: 'Access instant liquidity at competitive rates',
      ...((isNotSupplied || !asCollateral) && {
        isError: true,
        errorMessage: isNotSupplied
          ? 'Supply an asset before you borrow'
          : 'Enable an asset as collateral before you borrow',
      }),
    },
    // {
    //   type: PanelType.SupplyAndBorrow,
    //   pageType: SupplyAndBorrowType.BorrowAndSupply,
    //   title: 'Supply & Borrow',
    //   description: 'Deposit and borrow tokens in a single click',
    // },
  ];

  return (
    <VStack align="stretch" spacing="6">
      {panels.map((panel) => (
        <Panel key={panel.type} {...panel} />
      ))}
    </VStack>
  );
};

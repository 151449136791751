import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import { TxStatus } from '@ui/commonStateController';
import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';
import { InDesktop, InMobile } from '@ui/components/MobileOrDesktop';
import { Num } from '@ui/components/Num';
import { useUserReservesIncentivesData } from '@ui/hooks/data/useUserReservesIncentivesData';
import { Fraction } from 'bi-fraction';
import React, { useRef } from 'react';
import type { CountdownRenderProps } from 'react-countdown';
import Countdown from 'react-countdown';

import ClaimModal, { useClaimModal } from './ClaimModal';

const Earnings = () => {
  const [txStatus, openModal, setTxStatus] = useClaimModal((s) => [
    s.txStatus,
    s.open,
    s.setTxStatus,
  ]);

  const { data: userReservesIncentivesData } = useUserReservesIncentivesData();

  const isPreAmpCompleted = useRef(false);

  const totalAmount = isPreAmpCompleted.current
    ? userReservesIncentivesData?.amplyTokenAmount
    : userReservesIncentivesData?.preAmplyTokenAmount;

  const handleClaim = () => {
    useClaimModal.setState({ isPreAmpCompleted: isPreAmpCompleted.current });
    setTxStatus(TxStatus.Default);
    openModal();
  };

  const renderer = ({ completed }: CountdownRenderProps, isPreAmp: boolean) => {
    if (isPreAmp) {
      if (completed) {
        isPreAmpCompleted.current = true;
        return undefined;
      }
      return (
        userReservesIncentivesData?.preAmplyTokenAmount.gt(0) && (
          <Flex justifyContent={'space-between'}>
            <Text textStyle={'body3'}>{'preAMP'}</Text>
            <Box>
              <Flex gap={1} mb={0.5}>
                <Num
                  textStyle={'body1Bold'}
                  value={userReservesIncentivesData.preAmplyTokenAmount}
                  isShowUnit
                />
                <TokenImage w="24px" symbol={'preAMP'} />
              </Flex>
            </Box>
          </Flex>
        )
      );
    } else {
      if (completed) {
        return (
          userReservesIncentivesData?.amplyTokenAmount.gt(0) && (
            <Flex justifyContent={'space-between'}>
              <Text textStyle={'body3'}>{'AMPLY'}</Text>
              <Box>
                <Flex gap={1} mb={0.5}>
                  <Num
                    textStyle={'body1Bold'}
                    value={userReservesIncentivesData.amplyTokenAmount}
                    isShowUnit
                  />
                  <TokenImage w="24px" symbol={'AMPLY'} />
                </Flex>
              </Box>
            </Flex>
          )
        );
      }
      return undefined;
    }
  };

  return (
    <>
      <Flex
        flex={1}
        gap={2}
        flexDir={'column'}
        color={'primary.white'}
        bg={{ desktop: 'rgba(5, 18, 26, 0.3)' }}
        p={{ desktop: 2 }}
        minW={{ desktop: '268px' }}
        backdropFilter={'blur(2px)'}
      >
        <InDesktop>
          <Center textStyle={'body2'} mt={2}>
            My earnings
          </Center>
        </InDesktop>

        <Flex gap={4} alignItems={'center'}>
          <Flex gap={1} flexDir={'column'} flex={1}>
            {/* <Text textStyle={'body3'}>Total claimable amount</Text>
            <Num textStyle={'body1Bold'} value={totalUSDValue} suffix="USD" /> */}
          </Flex>
          <InMobile>
            <Button
              w="120px"
              onClick={handleClaim}
              isLoading={txStatus === TxStatus.Loading}
              isDisabled={totalAmount?.lte(Fraction.ZERO)}
            >
              Claim
            </Button>
          </InMobile>
        </Flex>

        <Flex gap={2} flexDir={'column'}>
          <Countdown
            date={process.env.PREAMPLY_END_DATE}
            renderer={(props) => renderer(props, true)}
          />
          <Countdown
            date={process.env.AMPLY_START_DATE}
            renderer={(props) => renderer(props, false)}
          />
        </Flex>

        <InDesktop>
          <Button
            onClick={handleClaim}
            isLoading={txStatus === TxStatus.Loading}
            isDisabled={!totalAmount || totalAmount.lte(Fraction.ZERO)}
            mt={6}
          >
            Claim
          </Button>
        </InDesktop>
      </Flex>
      <ClaimModal />
    </>
  );
};

export default Earnings;

/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Contract, Interface } from "ethers";
const _abi = [
    {
        inputs: [
            {
                internalType: "address",
                name: "owner",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "reward",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "oldAdmin",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newAdmin",
                type: "address",
            },
        ],
        name: "EmissionAdminUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "uint88",
                        name: "emissionPerSecond",
                        type: "uint88",
                    },
                    {
                        internalType: "uint256",
                        name: "totalSupply",
                        type: "uint256",
                    },
                    {
                        internalType: "uint32",
                        name: "distributionEnd",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "asset",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "reward",
                        type: "address",
                    },
                    {
                        internalType: "contract ITransferStrategyBase",
                        name: "transferStrategy",
                        type: "address",
                    },
                    {
                        internalType: "contract IEACAggregatorProxy",
                        name: "rewardOracle",
                        type: "address",
                    },
                ],
                internalType: "struct RewardsDataTypes.RewardsConfigInput[]",
                name: "config",
                type: "tuple[]",
            },
        ],
        name: "configureAssets",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
        ],
        name: "getEmissionAdmin",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getRewardsController",
        outputs: [
            {
                internalType: "contract IRewardsController",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "address",
                name: "claimer",
                type: "address",
            },
        ],
        name: "setClaimer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "asset",
                type: "address",
            },
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
            {
                internalType: "uint32",
                name: "newDistributionEnd",
                type: "uint32",
            },
        ],
        name: "setDistributionEnd",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
            {
                internalType: "address",
                name: "admin",
                type: "address",
            },
        ],
        name: "setEmissionAdmin",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "asset",
                type: "address",
            },
            {
                internalType: "address[]",
                name: "rewards",
                type: "address[]",
            },
            {
                internalType: "uint88[]",
                name: "newEmissionsPerSecond",
                type: "uint88[]",
            },
        ],
        name: "setEmissionPerSecond",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
            {
                internalType: "contract IEACAggregatorProxy",
                name: "rewardOracle",
                type: "address",
            },
        ],
        name: "setRewardOracle",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "controller",
                type: "address",
            },
        ],
        name: "setRewardsController",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "reward",
                type: "address",
            },
            {
                internalType: "contract ITransferStrategyBase",
                name: "transferStrategy",
                type: "address",
            },
        ],
        name: "setTransferStrategy",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
export class EmissionManager__factory {
    static createInterface() {
        return new Interface(_abi);
    }
    static connect(address, runner) {
        return new Contract(address, _abi, runner);
    }
}
EmissionManager__factory.abi = _abi;

import { ACLManager as ACLManagerAbi, Amply as AmplyAbi, AmplyOracle as AmplyOracleAbi, AmplyProtocolDataProvider as AmplyProtocolDataProviderAbi, AmplyVault as AmplyVaultAbi, AmplyVesting as AmplyVestingAbi, AToken as ATokenAbi, EmissionManager as EmissionManagerAbi, ERC20 as ERC20Abi, L2Encoder as L2EncoderAbi, L2Pool as L2PoolAbi, Paymaster as PaymasterAbi, PoolAddressesProvider as PoolAddressesProviderAbi, PoolAddressesProviderRegistry as PoolAddressesProviderRegistryAbi, PoolConfigurator as PoolConfiguratorAbi, ReservesSetupHelper as ReservesSetupHelperAbi, RewardsController as RewardsControllerAbi, UiIncentiveDataProviderV3 as UiIncentiveDataProviderV3Abi, UiPoolDataProviderV3 as UiPoolDataProviderV3Abi, VariableDebtToken as VariableDebtTokenAbi, WalletBalanceProvider as WalletBalanceProviderAbi, WrappedTokenGatewayV3 as WrappedTokenGatewayV3Abi, } from './abis';
import { getContractAddress } from './config';
import { getContract, getProvider } from './utils';
export class SDK {
    constructor(options) {
        this.getContract = (address, abi, signerOrProvider) => {
            if (!signerOrProvider) {
                signerOrProvider = getProvider(this.providerOptions);
            }
            return getContract(address, abi, signerOrProvider);
        };
        this.getContractAddress = (contractName) => {
            if (this.contractAddressMap) {
                return this.contractAddressMap[contractName];
            }
            return getContractAddress(this.providerOptions.chainId, contractName);
        };
        this.getACLManager = (signerOrProvider) => this.getContract(this.getContractAddress('ACLManager'), ACLManagerAbi, signerOrProvider);
        this.getAmply = (signerOrProvider) => this.getContract(this.getContractAddress('Amply'), AmplyAbi, signerOrProvider);
        this.getAmplyVesting = (signerOrProvider) => this.getContract(this.getContractAddress('AmplyVesting'), AmplyVestingAbi, signerOrProvider);
        this.getPaymaster = (signerOrProvider) => this.getContract(this.getContractAddress('Paymaster'), PaymasterAbi, signerOrProvider);
        this.getAmplyOracle = (signerOrProvider) => this.getContract(this.getContractAddress('AmplyOracle'), AmplyOracleAbi, signerOrProvider);
        this.getAmplyProtocolDataProvider = (signerOrProvider) => this.getContract(this.getContractAddress('AmplyProtocolDataProvider'), AmplyProtocolDataProviderAbi, signerOrProvider);
        this.getAmplyVault = (signerOrProvider) => this.getContract(this.getContractAddress('AmplyVault'), AmplyVaultAbi, signerOrProvider);
        this.getAToken = (address, signerOrProvider) => this.getContract(address, ATokenAbi, signerOrProvider);
        this.getEmissionManager = (signerOrProvider) => this.getContract(this.getContractAddress('EmissionManager'), EmissionManagerAbi, signerOrProvider);
        this.getERC20 = (address, signerOrProvider) => this.getContract(address, ERC20Abi, signerOrProvider);
        this.getL2Encoder = (signerOrProvider) => this.getContract(this.getContractAddress('L2Encoder'), L2EncoderAbi, signerOrProvider);
        this.getPoolAddressesProvider = (signerOrProvider) => this.getContract(this.getContractAddress('PoolAddressesProvider'), PoolAddressesProviderAbi, signerOrProvider);
        this.getPoolAddressesProviderRegistry = (signerOrProvider) => this.getContract(this.getContractAddress('PoolAddressesProviderRegistry'), PoolAddressesProviderRegistryAbi, signerOrProvider);
        this.getPoolConfigurator = (signerOrProvider) => this.getContract(this.getContractAddress('PoolConfigurator'), PoolConfiguratorAbi, signerOrProvider);
        this.getReservesSetupHelper = (signerOrProvider) => this.getContract(this.getContractAddress('ReservesSetupHelper'), ReservesSetupHelperAbi, signerOrProvider);
        this.getRewardsController = (signerOrProvider) => this.getContract(this.getContractAddress('RewardsController'), RewardsControllerAbi, signerOrProvider);
        this.getUiIncentiveDataProviderV3 = (signerOrProvider) => this.getContract(this.getContractAddress('UiIncentiveDataProviderV3'), UiIncentiveDataProviderV3Abi, signerOrProvider);
        this.getUiPoolDataProviderV3Abi = (signerOrProvider) => this.getContract(this.getContractAddress('UiPoolDataProviderV3'), UiPoolDataProviderV3Abi, signerOrProvider);
        this.getWalletBalanceProvider = (signerOrProvider) => this.getContract(this.getContractAddress('WalletBalanceProvider'), WalletBalanceProviderAbi, signerOrProvider);
        this.getVariableDebtToken = (address, signerOrProvider) => this.getContract(address, VariableDebtTokenAbi, signerOrProvider);
        this.getWrappedTokenGatewayV3 = (signerOrProvider) => this.getContract(this.getContractAddress('WrappedTokenGatewayV3'), WrappedTokenGatewayV3Abi, signerOrProvider);
        this.getL2Pool = (poolAddress, signerOrProvider) => this.getContract(poolAddress, L2PoolAbi, signerOrProvider);
        const { providerOptions, contractAddressMap } = options;
        this.providerOptions = providerOptions;
        this.contractAddressMap = contractAddressMap;
    }
}

import { Box, Flex, Input, useControllableState } from '@chakra-ui/react';
import { createContext } from '@ui/context';
import { useTokenBalance } from '@ui/hooks/data';
import type { ChangeEvent } from 'react';
import React, { forwardRef, useCallback, useRef, useState } from 'react';

import CardWithBorder from '../../CardWithBorder';
import { Num } from '../../Num';
import { TokenList } from '..';
import type { TokenInputContextProps, TokenInputProps } from '../types';
import { InputMessage } from './InputMessage';
import { TokenNotSelected } from './TokenNotSelected';
import { TokenSelect } from './TokenSelect';

export const [useTokenInputState, TokenInputProvider] =
  createContext<TokenInputProps>('useTokenInputState');

export const TokenInput = forwardRef<HTMLDivElement, TokenInputContextProps>(
  (
    {
      value,
      onChange,
      min = 0,
      max = Number.MAX_SAFE_INTEGER - 1,
      maxDecimalPlaces = 18,
      errorMsg,
      warningMsg,
      inputProps,
      selectedTokenData,
      disabled,
      topLabel,
      isShowBalance = true,
      setSelectedTokenData,
      ...props
    },
    ref,
  ) => {
    const [isCollapse, setIsCollapse] = useState(true);
    const inputRef = useRef<HTMLInputElement>();
    const [internalValue, setInternalValue] = useControllableState({
      value,
      onChange,
    });
    const { data: tokenBalance } = useTokenBalance(
      selectedTokenData?.tokenAddress,
    );

    const renderTopLabel = useCallback(() => {
      if (topLabel) {
        return topLabel;
      }

      if (isShowBalance) {
        return (
          <Num
            prefix="Wallet balance: "
            value={tokenBalance}
            color="primary.text2"
            textStyle="body1"
            mb={1}
            decimalPlaces={4}
          />
        );
      }

      return null;
    }, [isShowBalance, tokenBalance, topLabel]);

    const handleInputChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) =>
        onInputChange(e, setInternalValue, min, max, maxDecimalPlaces),
      [min, max, maxDecimalPlaces, setInternalValue],
    );
    return (
      <TokenInputProvider
        value={{
          isCollapse,
          setIsCollapse,
          disabled,
          selectedTokenData,
          setSelectedTokenData,
          value,
        }}
      >
        <CardWithBorder
          bg={disabled ? 'primary.disabled' : 'primary.layer0'}
          px={4.5}
          py={3}
          isError={!!errorMsg}
          cursor={disabled ? 'not-allowed' : undefined}
        >
          <Box ref={ref} {...props}>
            {selectedTokenData ? (
              <>
                {renderTopLabel()}
                <Flex
                  onClick={() => {
                    if (inputRef.current) {
                      inputRef.current.focus();
                    }
                  }}
                >
                  <Input
                    value={internalValue}
                    color={errorMsg ? 'primary.error' : 'primary.white'}
                    flex={1}
                    p={0}
                    textStyle={'body2'}
                    placeholder="Value"
                    variant={'unstyled'}
                    fontSize={'18px'}
                    disabled={disabled}
                    onChange={handleInputChange}
                    {...inputProps}
                  />
                  <TokenSelect />
                </Flex>
                <InputMessage
                  value={internalValue}
                  selectedTokenData={selectedTokenData}
                  errorMsg={errorMsg}
                  warningMsg={warningMsg}
                />
              </>
            ) : (
              <TokenNotSelected />
            )}
          </Box>
          <TokenList />
        </CardWithBorder>
      </TokenInputProvider>
    );
  },
);

TokenInput.displayName = 'TokenInput';

/**
 * Calculates and returns the number of decimal places in a given numeric string.
 *
 * If the string does not contain a decimal point, the function returns 0.
 *
 * @param {string} n - The numeric string for which to calculate the number of decimal places.
 * @returns {number} The number of decimal places in the input string.
 */
function getDecimalPlaces(n: string): number {
  const dotIdx = n.indexOf('.');

  if (dotIdx === -1) return 0;

  return n.length - dotIdx - 1;
}

const onInputChange = (
  e: ChangeEvent<HTMLInputElement>,
  setInternalValue: (value: React.SetStateAction<string>) => void,
  min: number,
  max: number,
  maxDecimalPlaces: number,
) => {
  const val = e.target.value;
  const n = Number(val);

  // reset user input
  if (!val || Number.isNaN(n)) {
    setInternalValue('');
    return;
  }

  // reject user input
  if (n < min || n > max) return;

  if (getDecimalPlaces(val) > maxDecimalPlaces) {
    // Special handling for integer input on mobile
    if (maxDecimalPlaces === 0) {
      const intPart = val.split('.')[0];
      setInternalValue(intPart);
    }
    return;
  }
  setInternalValue(val);
};

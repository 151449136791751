import { ExpandDown } from '@amply-app/icons';
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';

import { useTokenInputState } from './TokenInput';

export const TokenSelect = () => {
  const {
    disabled,
    setIsCollapse,
    isCollapse,
    selectedTokenData,
    setSelectedTokenData,
  } = useTokenInputState();
  return (
    <Flex
      cursor={'pointer'}
      pointerEvents={disabled ? 'none' : undefined}
      alignItems={'center'}
      gap={1}
      onClick={() => {
        if (!setSelectedTokenData) return;
        setIsCollapse?.(!isCollapse);
      }}
    >
      <Text textStyle={'body1'} color={'primary.text2'}>
        {selectedTokenData?.symbol}
      </Text>
      {selectedTokenData && <TokenImage symbol={selectedTokenData.symbol} />}
      {setSelectedTokenData ? (
        <Box
          transition={'all 0.25s'}
          transform={isCollapse ? undefined : 'rotate(180deg)'}
          fontSize={0}
        >
          <Icon as={ExpandDown} w={6} h={6} color="primary.white" />
        </Box>
      ) : null}
    </Flex>
  );
};

import { create } from 'zustand';

interface AccountModalStore {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const useAccountModal = create<AccountModalStore>((set) => ({
  isOpen: false,
  view: 'default',
  disclaimerUrl: '',
  closeModal: () => {
    // reset all data
    set({
      isOpen: false,
    });
  },
  openModal: () => {
    set({
      isOpen: true,
    });
  },
}));

import { SDK } from '@amply-app/sdk';
import { getAddEthereumChainParameter } from '@amply-app/wallet';

import { getChainId } from './getChainId';

let sdk: SDK | undefined;

export const getSDK = (): SDK => {
  if (!sdk) {
    const chainId = getChainId();
    const { chainName, rpcUrls } = getAddEthereumChainParameter(chainId);
    const rpcUrl = rpcUrls[0];

    sdk = new SDK({
      providerOptions: {
        chainName: chainName,
        chainId,
        rpcUrl,
      },
    });
  }

  return sdk;
};

import { ExpandRight } from '@amply-app/icons';
import { Box, Flex, Icon, Image, Text } from '@chakra-ui/react';
import { ChakraNextLink } from '@ui/components/ChakraNextLink';
import { Divider } from '@ui/components/MarketCard';
import { tokenPathMap } from '@ui/components/TokenInput/constants';
import React from 'react';

const CardTopSection = ({
  symbol,
  label,
}: {
  symbol: string;
  label?: string;
}) => {
  return (
    <>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Flex gap={1} alignItems={'center'}>
          <Image
            w="32px"
            src={`/tokens/${tokenPathMap[symbol]}.svg`}
            alt={symbol}
          />
          <Box>
            <Text textStyle={'body1'}>{label}</Text>
            <Text color="primary.text2" textStyle={'caption'}>
              {symbol}
            </Text>
          </Box>
        </Flex>
        <ChakraNextLink href={`/markets/token/?symbol=${symbol}`}>
          <Icon
            as={ExpandRight}
            boxSize={7}
            bg={'primary.icon_bg'}
            color={'primary.cta'}
            borderRadius={'50%'}
          />
        </ChakraNextLink>
      </Flex>
      <Divider />
    </>
  );
};

export default CardTopSection;

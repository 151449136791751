import { Box, Center, Flex } from '@chakra-ui/react';
import { POST_MVP_FLAG } from '@ui/constants';
import { useAllPoolsData } from '@ui/hooks/data';
import { useUserReservesData } from '@ui/hooks/data/useUserReservesData';
import { Fraction } from 'bi-fraction';
import type { PropsWithChildren } from 'react';
import React, { useEffect } from 'react';

import { RenderInDesktop, RenderInMobile } from '../MobileOrDesktop';
import { tokenAddressMap } from '../TokenInput/constants';
import HealthSection from './HealthSection';
import NoBorrowedOrSuppliedGuild from './NoBorrowedOrSuppliedGuild';
import { useReservesData } from './Overview';
import Earnings from './Panels/Earnings';
import LiquidationSection from './Panels/LiquidationSection';
import Portfolio from './Panels/Portfolio';
import PortfolioTabs from './Panels/PortfolioTabs';
import PortfolioOverview from './PortfolioOverview';

const UserPortfolio = () => {
  const setData = useReservesData((s) => s.setData);

  const { data } = useUserReservesData();
  const { data: poolsData } = useAllPoolsData();

  const _data = data?.map((item) => ({
    ...item,
    label: poolsData?.find(
      (pool) =>
        pool.symbol === tokenAddressMap[item.tokenAddress.toLowerCase()],
    )?.label,
    symbol: tokenAddressMap[item.tokenAddress.toLowerCase()],
  }));

  const hasBorrowedOrSupplied = data?.some(
    (item) =>
      item.aTokenBalance.gt(Fraction.ZERO) ||
      item.vDebtTokenBalance.gt(Fraction.ZERO),
  );

  useEffect(() => {
    _data && setData(_data);
  }, [_data, setData]);

  return !hasBorrowedOrSupplied ? (
    <>
      <RenderInDesktop>
        <NoBorrowedOrSuppliedGuild />
      </RenderInDesktop>
      <RenderInMobile>
        <PortfolioTabs />
      </RenderInMobile>
    </>
  ) : (
    <>
      {!POST_MVP_FLAG && (
        <RenderInDesktop>
          <Center my={8}>
            <Box w={'500px'} bg={'primary.card_bg'}>
              <LiquidationSection />
            </Box>
          </Center>
        </RenderInDesktop>
      )}
      <HasBorrowedOrSupplied>
        {!POST_MVP_FLAG && (
          <RenderInMobile>
            <LiquidationSection />
          </RenderInMobile>
        )}
      </HasBorrowedOrSupplied>
      <RenderInMobile>
        <PortfolioTabs />
      </RenderInMobile>
      <RenderInDesktop>
        <Flex gap={6}>
          <Portfolio />
          <Earnings />
        </Flex>
      </RenderInDesktop>
    </>
  );
};

export default UserPortfolio;

const HasBorrowedOrSupplied: React.FC<PropsWithChildren> = ({ children }) => {
  const { data } = useUserReservesData();
  const hasBorrowedOrSupplied = data?.some(
    (item) =>
      item.aTokenBalance.gt(Fraction.ZERO) ||
      item.vDebtTokenBalance.gt(Fraction.ZERO),
  );

  return (
    <Box px={{ base: 4, desktop: 0 }} display={{ desktop: 'flex' }} gap={3}>
      {hasBorrowedOrSupplied && <PortfolioOverview />}
      {children}
      {hasBorrowedOrSupplied && <HealthSection />}
    </Box>
  );
};

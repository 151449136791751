import { BaseContract } from 'ethers';
/**
 * @param chainId the chain on which the contract is deployed
 * @param address the contract address
 * @param abi the contract abi
 * @param SignerOrProvider signer is required when you need to write to the contract.
 * @returns the contract instance
 */
export function getContract(address, abi, signerOrProvider) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return new BaseContract(address, abi, signerOrProvider);
}

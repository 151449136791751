import { Info } from '@amply-app/icons';
import type { FlexProps, TooltipProps } from '@chakra-ui/react';
import { Flex, Icon, Tooltip, useDisclosure } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import React from 'react';

type InfoTooltipProps = FlexProps & {
  label: ReactNode;
  tooltipProps?: Partial<TooltipProps>;
};

const InfoTooltip = ({ label, tooltipProps, ...props }: InfoTooltipProps) => {
  const {
    isOpen: isInfoDrawerOpen,
    onClose: onCloseInfoDrawer,
    onOpen: onOpenInfoDrawer,
    onToggle,
  } = useDisclosure();

  return (
    <Tooltip label={label} isOpen={isInfoDrawerOpen} hasArrow {...tooltipProps}>
      <Flex cursor="pointer">
        <Icon
          as={Info}
          color="primary.cta"
          w={6}
          h={6}
          onMouseEnter={onOpenInfoDrawer}
          onMouseLeave={onCloseInfoDrawer}
          onClick={onToggle}
        />
      </Flex>
    </Tooltip>
  );
};

export default InfoTooltip;

import type { Fraction } from 'bi-fraction';
import { useMemo } from 'react';

import type { UseAllPoolsData } from './useAllPoolsData';
import { useTokenSupply } from './useATokens';
import { useTokenBorrow } from './useVariableDebtTokens';

export const useSupplyCap = (
  tokenData?: UseAllPoolsData,
  supplyAmount?: Fraction,
) => {
  const { data: tokenSupply } = useTokenSupply(tokenData?.aTokenAddress);
  return useMemo(() => {
    if (!tokenData) return false;
    let totalSupply = tokenSupply;
    if (supplyAmount) {
      totalSupply = tokenSupply?.add(supplyAmount);
    }
    return tokenData.supplyCap.isZero()
      ? false
      : totalSupply?.gte(tokenData.supplyCap);
  }, [tokenSupply, tokenData, supplyAmount]);
};

export const useBorrowCap = (
  tokenData?: UseAllPoolsData,
  borrowAmount?: Fraction,
) => {
  const { data: tokenBorrow } = useTokenBorrow(tokenData?.variableDebtToken);
  return useMemo(() => {
    if (!tokenData) return false;
    let totalBorrow = tokenBorrow;
    if (borrowAmount) {
      totalBorrow = tokenBorrow?.add(borrowAmount);
    }
    return tokenData.borrowCap.isZero()
      ? false
      : totalBorrow?.gte(tokenData.borrowCap);
  }, [tokenBorrow, tokenData, borrowAmount]);
};

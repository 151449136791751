import { ModalCloseButton } from '@chakra-ui/react';
import { TxStatus } from '@ui/commonStateController';
import {
  TransactionErrorContent,
  TransactionLoadingContent,
} from '@ui/components/CommonContents';
import { InDesktop } from '@ui/components/MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '@ui/components/ModalOrDrawer';

import { AsCollateralSuccessContent } from '../AsCollateralSuccessContent';
import { CollateralDrawerType, useCollateralDrawer } from '../hooks';
import { DefaultContent } from './DefaultContent';

export const EnableAsCollateralDrawer = () => {
  const [isOpen, txStatus, txError, closeModal, type] = useCollateralDrawer(
    (s) => [s.isOpen, s.txStatus, s.txError, s.close, s.type],
  );
  return (
    <ModalOrDrawer
      isOpen={isOpen && type === CollateralDrawerType.Enable}
      onClose={closeModal}
      scrollBehavior="inside"
      placement="bottom"
    >
      <ModalOrDrawerOverlay />
      <ModalOrDragDrawerContent
        roundedTop="10px"
        minH={'60vh'}
        minW={{ desktop: '500px' }}
      >
        <ModalOrDrawerHeader>{'Enable as collateral'}</ModalOrDrawerHeader>
        <InDesktop>
          <ModalCloseButton />
        </InDesktop>
        {txStatus === TxStatus.Default && <DefaultContent />}
        {txStatus === TxStatus.Loading && <TransactionLoadingContent />}
        {txStatus === TxStatus.Success && (
          <AsCollateralSuccessContent type="enableASCollateral" />
        )}
        {txStatus === TxStatus.Failed && (
          <TransactionErrorContent error={txError} />
        )}
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

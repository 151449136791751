import { currentWallet, useGlobalContextStore } from '@amply-app/wallet';
import {
  Button,
  Center,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BsExclamationDiamondFill } from 'react-icons/bs';

/**
 * experimental feature
 * @returns
 */
export const GlobalAccountInput = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [input, setInput] = useState('');
  const globalAccount = useGlobalContextStore((s) => s.account);
  const defaultAccount = currentWallet.useAccount();
  useEffect(() => {
    setInput(globalAccount ?? '');
  }, [globalAccount]);
  const isValid = useMemo(() => !input || isValidAddress(input), [input]);
  const submit = useCallback(() => {
    useGlobalContextStore.setState({
      account: input,
    });
    setIsOpen(false);
  }, [input]);
  return (
    <>
      <Center
        fontWeight="bold"
        color="white"
        cursor="pointer"
        mt="1px"
        rounded="4px"
        bgColor="rgba(23, 24, 26, 0.75)"
        onClick={(e) => {
          setIsOpen(true);
          e.stopPropagation();
        }}
      >
        {globalAccount ? (
          <Text>
            {globalAccount.slice(0, 4) +
              '...' +
              globalAccount.slice(globalAccount.length - 2)}
          </Text>
        ) : (
          <Text>--</Text>
        )}
      </Center>
      <Modal
        isOpen={isOpen}
        size="sm"
        onClose={() => setIsOpen(false)}
        closeOnOverlayClick
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily="mono">
            <Center gap={1}>
              <Icon as={BsExclamationDiamondFill} boxSize="12px" />
              <Text textStyle="h4Bold">Hardcode Account</Text>
            </Center>
          </ModalHeader>
          <ModalBody fontFamily="mono">
            <Text mb="4" textStyle="body2Bold">
              Effect on-chain read operations only
            </Text>
            <Textarea
              autoFocus
              value={input}
              placeholder={defaultAccount}
              variant="flushed"
              onChange={(e) => setInput(e.target.value)}
              color={isValid ? 'gray.700' : 'red.500'}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && isValid) submit();
              }}
            />
            {!isValid && <Text color="red.500">invalid address</Text>}
          </ModalBody>
          <ModalFooter fontFamily="mono">
            <Button variant="action" onClick={submit} isDisabled={!isValid}>
              Commit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const isValidAddress = (address: string) =>
  /^(0x){1}[0-9a-fA-F]{40}$/i.test(address);

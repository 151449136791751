export const breakpoints = {
    base: '0px',
    xxs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    /**
     * we should always use this "desktop" breakpoint rather than 'md' or 'lg' breakpoint.
     * "desktop" cannot be the same as the existing breakpoints,
     * overwise the `useBreakpointValues()` will not return the value of the 'desktop' breakpoint
     */
    desktop: '993px',
    xl: '1280px',
    '2xl': '1600px',
};

import { useQuery } from '@tanstack/react-query';
import { RAY_UNIT } from '@ui/config/sc';
import { RefetchInterval } from '@ui/config/ui';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

import { checkZKCROAddress } from './utils';

export const useSupplyAPY = (tokenAddress?: string) => {
  const sdk = getSDK();
  return useQuery(
    ['useSupplyAPY', tokenAddress],
    async () => {
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();
      const address = checkZKCROAddress(tokenAddress);
      const data = await sdk.getL2Pool(poolAddress).getReserveData(address!);
      return new Fraction(data[2]).shr(RAY_UNIT);
    },
    { enabled: !!tokenAddress, refetchInterval: RefetchInterval.Normal },
  );
};

export const useTokensSupplyAPY = (tokenAddresses?: string[]) => {
  const sdk = getSDK();
  return useQuery(
    ['useTokensSupplyAPY', tokenAddresses],
    async () => {
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();

      const data = await Promise.all(
        tokenAddresses!.map(async (tokenAddress) => {
          const address = checkZKCROAddress(tokenAddress);
          const data = await sdk
            .getL2Pool(poolAddress)
            .getReserveData(address!);
          return new Fraction(data[2]).shr(RAY_UNIT);
        }),
      );
      return data;
    },
    { enabled: !!tokenAddresses, refetchInterval: RefetchInterval.Normal },
  );
};

export const useBorrowAPY = (tokenAddress?: string) => {
  const sdk = getSDK();
  return useQuery(
    ['useBorrowAPY', tokenAddress],
    async () => {
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();
      const address = checkZKCROAddress(tokenAddress);
      const data = await sdk.getL2Pool(poolAddress).getReserveData(address!);
      return new Fraction(data[4]).shr(RAY_UNIT);
    },
    { enabled: !!tokenAddress, refetchInterval: RefetchInterval.Normal },
  );
};

export const useTokensBorrowAPY = (tokenAddresses?: string[]) => {
  const sdk = getSDK();
  return useQuery(
    ['useTokensBorrowAPY', tokenAddresses],
    async () => {
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();

      const data = await Promise.all(
        tokenAddresses!.map(async (tokenAddress) => {
          const address = checkZKCROAddress(tokenAddress);
          const data = await sdk
            .getL2Pool(poolAddress)
            .getReserveData(address!);
          return new Fraction(data[4]).shr(RAY_UNIT);
        }),
      );
      return data;
    },
    { enabled: !!tokenAddresses, refetchInterval: RefetchInterval.Normal },
  );
};

export const useIncentiveAPY = () => {
  const sdk = getSDK();
  const provider = sdk
    .getContractAddress('PoolAddressesProvider')
    .toLowerCase();
  return useQuery(
    ['useIncentiveAPY'],
    async () => {
      const sdk = getSDK();
      const data = await sdk
        .getUiIncentiveDataProviderV3()
        .getReservesIncentivesData(provider);

      return {
        tokenAddress: data.map((item) => item[0]),
        supplyIncentiveData: data.map((item) => item[1]).map((item) => item[2]),
        borrowIncentiveData: data.map((item) => item[2]).map((item) => item[2]),
      };
    },
    {
      refetchInterval: RefetchInterval.Normal,
    },
  );
};

import { APRChart } from '@ui/components/AmplyCharts';
import type { APRChartData } from '@ui/components/AmplyCharts/types';
import React from 'react';

export const Chart = ({ data }: { data: APRChartData }) => {
  return (
    <APRChart
      w={{
        base: '100px',
        desktop: '150px',
      }}
      data={data}
      chartContainerProps={{
        width: '100%',
        aspect: 2,
      }}
      areaProps={{ stroke: '#4CCCDE' }}
      linearGradientProps={{
        stopColor: '#4CCCDE',
        stopOpacity: '0.4',
        id: 'apr',
      }}
      xAxisProps={{
        hide: true,
      }}
      tooltipProps={{
        hide: true,
      }}
    />
  );
};

import { Close, Question } from '@amply-app/icons';
import { Button, Center, Flex, Icon, Text } from '@chakra-ui/react';
import CardWithBorder from '@ui/components/CardWithBorder';
import { create } from 'zustand';

interface LiquidationModalStore {
  isOpen: boolean;
  closeModal: () => void;
}

export const useLiquidationModal = create<LiquidationModalStore>((set) => ({
  isOpen: true,
  closeModal: () => {
    set({
      isOpen: false,
    });
  },
}));

const LiquidationSection = () => {
  const isOpen = useLiquidationModal((s) => s.isOpen);
  const closeModal = useLiquidationModal((s) => s.closeModal);

  // TODO: get real liquidated status
  const isLiquidated = false;
  const isFullyLiquidated = true;

  if (!isLiquidated || !isOpen) {
    return null;
  }
  return (
    <CardWithBorder p={4}>
      <Flex
        gap={1}
        justifyContent={'space-between'}
        {...(isFullyLiquidated && { color: 'primary.error' })}
      >
        <Icon as={Question} h={6} w={6} />
        <Text>
          {`Your debt was ${
            isFullyLiquidated ? 'fully' : 'partly'
          } liquidated.`}
          A corresponding amount of collateral, in addition to a liquidation
          penalty, was paid out to the liquidator.
        </Text>
        <Icon
          as={Close}
          onClick={closeModal}
          color={'primary.cta'}
          h={6}
          w={6}
          cursor={'pointer'}
        />
      </Flex>
      <Center mt={3}>
        <Button h={'30px'} variant={'outline'}>
          Learn more
        </Button>
        <Button h={'30px'} variant={'link'} ml={3}>
          CronosZK explorer
        </Button>
      </Center>
    </CardWithBorder>
  );
};

export default LiquidationSection;

/**
 * Tabs theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/tabs.ts
 */
import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import { textStyles } from '../textStyles';
export const Tabs = {
    parts: parts.keys,
    baseStyle() {
        return {};
    },
    variants: {
        line(props) {
            const { orientation } = props;
            const isVertical = orientation === 'vertical';
            const borderProp = isVertical ? 'borderStart' : 'borderBottom';
            return {
                tablist: {
                    [borderProp]: '1px solid',
                    borderColor: 'transparent',
                },
                tab: {
                    pos: 'relative',
                    [borderProp]: '1px solid',
                    ...textStyles?.body1,
                    color: 'primary.active_state',
                    transitionDuration: 0,
                    _active: {
                        bg: 'transparent',
                    },
                    _selected: {
                        // ...textStyles?.h4,
                        color: 'primary.cta',
                        '&::before': {
                            content: '""',
                            pos: 'absolute',
                            bg: 'primary.cta',
                            w: '10px',
                            h: '3px',
                            left: 0,
                            bottom: '-2px',
                        },
                        '&::after': {
                            content: '""',
                            pos: 'absolute',
                            bg: 'primary.cta',
                            w: '3px',
                            h: '3px',
                            right: 0,
                            bottom: '-2px',
                        },
                    },
                    _disabled: {
                        color: 'primary.disabled',
                    },
                },
                tabpanel: {
                    px: '4px',
                },
            };
        },
    },
    sizes: {},
    defaultProps: {},
};

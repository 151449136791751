export const textStyles = {
    // display headline
    d1: {
        fontSize: { base: 54, lg: 90 },
        fontWeight: 'semibold',
        lineHeight: { base: '64px', lg: '108px' },
    },
    d2: {
        fontSize: { base: 32, lg: 54 },
        fontWeight: 'semibold',
        lineHeight: { base: '40px', lg: '64px' },
    },
    landingDesktopTitle: {
        fontSize: '48px',
        fontWeight: 400,
        lineHeight: '76px',
    },
    // page headline
    h1: {
        fontSize: '48px',
        fontWeight: 700,
        lineHeight: '76px',
    },
    h2: {
        fontSize: '32px',
        fontWeight: 400,
        lineHeight: '48px',
    },
    // section headline
    h3: {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '38px',
    },
    h3Bold: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '38px',
    },
    // dialog headings, card headings and section headings
    h4: {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '28px',
    },
    // subheading under headlines
    subhead1: {
        fontSize: { base: 18, lg: 32 },
        fontWeight: 'normal',
        lineHeight: { base: '24px', lg: '40px' },
    },
    subhead2: {
        fontSize: { base: 16, lg: 24 },
        fontWeight: 'normal',
        lineHeight: { base: '24px', lg: '32px' },
    },
    // body text (e.g. introduction)
    body1: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
    },
    body1Bold: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
    },
    body2: {
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '27px',
    },
    body3: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
    },
    cta: {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: 'normal',
    },
    fab: {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '19px',
    },
    link: {
        fontSize: '14px',
        fontWeight: 800,
        lineHeight: 'normal',
    },
    // captions and supplementary text, T&C
    caption: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    captionBold: {
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: 'normal',
    },
    // microcopy (e.g. avatar initials)
    caption2: {
        fontSize: { base: 10, lg: 12 },
        fontWeight: 'light',
        lineHeight: { base: '14px', lg: '16px' },
    },
    landingTitle: {
        fontSize: '40px',
        lineHeight: '52px',
        fontFamily: 'Chakra-Petch',
    },
};

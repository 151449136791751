import { ExternalLink } from '@amply-app/icons';
import { Button, Link, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { getExplorerLink } from '@ui/utils/getExplorerLink';
import Image from 'next/image';
import type { ReactElement } from 'react';
import React from 'react';

import { SupplyAndBorrowType, useSupplyAndBorrowModal } from '.';

export const SuccessContent = () => {
  const [
    type,
    txHash,
    supplyTokenData,
    supplyAmount,
    borrowTokenData,
    borrowAmount,
  ] = useSupplyAndBorrowModal((s) => [
    s.type,
    s.txHash,
    s.supplyTokenData,
    s.supplyAmount,
    s.borrowTokenData,
    s.borrowAmount,
  ]);

  const tokenData =
    type === SupplyAndBorrowType.Supply ? supplyTokenData : borrowTokenData;
  const amount =
    type === SupplyAndBorrowType.Supply ? supplyAmount : borrowAmount;
  return (
    <>
      <VStack>
        <Text textStyle={'h3'} mt="24px">
          Transaction complete
        </Text>
        <Text mb={5} textStyle="body1" color={'primary.text2'}>
          You have{' '}
          {type === SupplyAndBorrowType.Supply ? 'supplied' : 'borrowed'}{' '}
          {amount?.toFixed(2)} {tokenData?.symbol}
        </Text>
        <Link
          href={getExplorerLink(txHash ?? '0x000000', 'transaction')}
          isExternal
          color="primary.cta"
          target="_blank"
          textStyle={'cta'}
        >
          View on CronosZK Explorer
        </Link>
      </VStack>
      {type === SupplyAndBorrowType.Borrow && (
        <VStack gap={2} alignItems={'flex-start'} px={2}>
          <Text textStyle={'body3'} mt={15}>
            {`Here's how you can use ${tokenData?.symbol}:`}
          </Text>
          <Text textStyle={'body3'} color={'primary.text2'}>
            {'Trade, provide liquidity, or find that next multi-bagger'}
          </Text>
          <SimpleGrid
            columns={2}
            spacingX={3}
            spacingY={4}
            minChildWidth={'150px'}
            width={'100%'}
          >
            {liquidityConfig.map((details) => {
              return (
                <Link
                  isExternal
                  href={details.href}
                  key={details.href}
                  width={'100%'}
                >
                  <Button
                    variant={'outline'}
                    height={'44px'}
                    width={'100%'}
                    as="button"
                    rightIcon={<ExternalLink />}
                  >
                    {details.icon}
                  </Button>
                </Link>
              );
            })}
          </SimpleGrid>
        </VStack>
      )}
    </>
  );
};

const liquidityConfig: {
  href: string;
  icon: ReactElement | undefined;
}[] = [
  {
    href: EXTERNAL_LINK.h2Finance,
    icon: (
      <Image src="/images/h2.svg" width={'38'} height={'24'} alt="h2finance" />
    ),
  },
  {
    href: EXTERNAL_LINK.fulcrom,
    icon: (
      <Image
        src="/images/fulcrom.svg"
        width={'58'}
        height={'18'}
        alt="fulcrom"
      />
    ),
  },
  // {
  //   href: EXTERNAL_LINK.puush,
  //   icon: (
  //     <Image
  //       src="/images/puush-fun.svg"
  //       width={'58'}
  //       height={'24'}
  //       alt="puush"
  //     />
  //   ),
  // },
  {
    href: EXTERNAL_LINK.ebisusbay,
    icon: <Image src="/images/ebi.svg" width={'81'} height={'24'} alt="ebi" />,
  },
  // {
  //   href: EXTERNAL_LINK.crolonmars,
  //   icon: (
  //     <Image
  //       src="/images/crolon-mars.svg"
  //       width={'77'}
  //       height={'22'}
  //       alt="crolon"
  //     />
  //   ),
  // },
  {
    href: EXTERNAL_LINK.wolfswap,
    icon: (
      <Image
        src="/images/wolfswap.svg"
        width={'34'}
        height={'28'}
        alt="wolfswap"
      />
    ),
  },
];

import type { FlexProps } from '@chakra-ui/react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { forwardRef } from 'react';

export const DragBar = forwardRef<
  HTMLDivElement,
  {
    isTouching?: boolean;
    onToggle?: () => void;
    buttonContainerProps?: FlexProps;
  } & FlexProps
>(function DragBar(
  { isTouching, onToggle, buttonContainerProps, ...props },
  ref,
) {
  return (
    <Flex
      ref={ref}
      w="70%"
      mx="auto"
      h="25px"
      justify="center"
      {...props}
      onClick={onToggle}
      zIndex={2}
    >
      <Center cursor="pointer" userSelect="none" {...buttonContainerProps}>
        <Box
          w="42px"
          h="5px"
          rounded="full"
          userSelect="none"
          bgColor={isTouching ? 'black' : 'primary.drag_bar'}
          _active={{
            bgColor: 'black',
          }}
        />
      </Center>
    </Flex>
  );
});

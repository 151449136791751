import { useSupplyMutation } from '@ui/hooks/mutation/useSupplyMutation';
import type { EthersError } from 'ethers';
import { useCallback } from 'react';

import { TxStatus, useSupplyAndBorrowModal } from '..';

export const useSupply = () => {
  const supplyMutation = useSupplyMutation();
  const [amount, setTxStatus, setTxHash, setTxError, supplyTokenData] =
    useSupplyAndBorrowModal((s) => [
      s.supplyAmount,
      s.setTxStatus,
      s.setTxHash,
      s.setTxError,
      s.supplyTokenData,
    ]);
  return useCallback(async () => {
    try {
      setTxStatus(TxStatus.Loading);

      if (!amount) throw new Error('Amount is required');
      if (!supplyTokenData) throw new Error('Supply token is required');

      const tx = await supplyMutation.mutateAsync({
        amount,
        tokenData: supplyTokenData,
      });
      setTxHash(tx?.hash ?? '');

      setTxStatus(TxStatus.Success);
    } catch (e: unknown) {
      setTxStatus(TxStatus.Failed);
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setTxError(e as EthersError);
    }
  }, [
    setTxStatus,
    amount,
    supplyMutation,
    supplyTokenData,
    setTxHash,
    setTxError,
  ]);
};

import Script from 'next/script';

export const GoogleAnalytics = () => {
  if (!process.env.GTAG_ID) return null;

  return (
    <Script id="google-analytics" strategy="afterInteractive">
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', '${process.env.GTAG_ID}');
      `}
    </Script>
  );
};

import { getAddEthereumChainParameter } from '@amply-app/wallet';
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { getChainId } from '@ui/utils/getChainId';
import { getExplorerLink } from '@ui/utils/getExplorerLink';
import type { Transaction } from 'ethers';
import { useMemo } from 'react';

import { ModalOrDrawerBody } from '../ModalOrDrawer';

export interface EthersError extends Partial<Error> {
  code: string;
  reason?: string;
  transaction?: Transaction;
  transactionHash?: string;
}

interface TransactionErrorContentProps {
  error: EthersError | null | undefined;
}

export const TransactionErrorContent: React.FC<
  TransactionErrorContentProps
> = ({ error }) => {
  const errorText = useErrorText(error);

  return (
    <>
      <ModalOrDrawerBody>
        <Flex mt="18px" flexDir="column" gap="30px" align="center">
          <Text textStyle="h3">Transaction failed</Text>
          <Box maxW="full">
            <Text textStyle="body1" color="primary.text2">
              {errorText}
            </Text>
          </Box>
        </Flex>
      </ModalOrDrawerBody>
    </>
  );
};

export const useErrorText = (error: EthersError | null | undefined) => {
  const chainInfo = getAddEthereumChainParameter(getChainId());
  const explorerUrl = chainInfo.blockExplorerUrls?.[0];

  return useMemo(() => {
    if (!error) {
      return 'Something went wrong';
    }

    const errorCode = error.code;
    const errorMessage = error.reason ?? error.message ?? error.toString();
    const txHash = error.transaction?.hash;

    if (errorCode === 'ACTION_REJECTED') return 'User declined transaction';

    const txLink =
      explorerUrl && txHash ? (
        <Link href={getExplorerLink(txHash, 'transaction')} mr="6px" isExternal>
          View on CronosZK Explorer
        </Link>
      ) : null;

    return (
      <>
        {txLink}
        {'Something went wrong'}
        {` (${new Date().toUTCString()}): ${errorCode}. ${
          errorMessage ? errorMessage.substring(0, 700) + '...' : ''
        }`}
      </>
    );
  }, [error, explorerUrl]);
};

import { Question } from '@amply-app/icons';
import { Button, Flex, Icon, Stack, Text } from '@chakra-ui/react';
import HealthFactor from '@ui/components/HealthFactor';
import InfoTooltip from '@ui/components/InfoTooltip';
import {
  ModalOrDrawerBody,
  ModalOrDrawerFooter,
} from '@ui/components/ModalOrDrawer';
import { Num } from '@ui/components/Num';
import { Paymaster, usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { Info } from '@ui/components/TextBlocks';
import {
  AvailableBorrowType,
  useAvailableBorrow,
} from '@ui/hooks/data/useAvailableBorrow';
import {
  HealthFactorType,
  useHealthFactor,
} from '@ui/hooks/data/useHealthFactor';
import { Fraction } from 'bi-fraction';
import { useMemo } from 'react';

import { useCollateralDrawer, useSetAsCollateral } from '../hooks';

export const DefaultContent: React.FC = () => {
  const [collateralUSDValue, tokenAddress] = useCollateralDrawer((s) => [
    s.tokenUSDValue,
    s.tokenAddress,
  ]);
  const isPaymasterError = usePaymaster((s) => s.isError);
  const disableAsCollateral = useSetAsCollateral(false);

  const availableToBorrowUSD = useAvailableBorrow(
    AvailableBorrowType.DisableAsCollateral,
    collateralUSDValue,
    tokenAddress,
  );

  const healthFactor = useHealthFactor(
    HealthFactorType.DisableAsCollateral,
    collateralUSDValue,
    tokenAddress,
  );

  const healthFactorLtOne = useMemo(
    () => healthFactor.lt(Fraction.ONE),
    [healthFactor],
  );

  const color = useMemo(
    () => (healthFactorLtOne ? 'primary.error' : 'primary.caution'),
    [healthFactorLtOne],
  );

  return (
    <>
      <ModalOrDrawerBody>
        <Flex direction={'column'} gap={'3'}>
          <Info
            title={
              <>
                {'Available to borrow'}
                <InfoTooltip
                  title={'Available to borrow'}
                  label="Maximum amount available for you to borrow, assuming that there is no borrow cap"
                />
              </>
            }
            content={<Num value={availableToBorrowUSD} />}
          />
          {healthFactor.lt(new Fraction(3)) ? (
            <Flex justifyContent={'center'} color={color} gap={2}>
              <Icon as={Question} color={color} w={6} h={6} />
              <Text textStyle={'body3'}>
                {healthFactorLtOne
                  ? 'Borrow less or supply more to keep your Health Factor above 1'
                  : 'To keep your Health Factor above 3, we recommend lowering your debt or supplying more'}
              </Text>
            </Flex>
          ) : null}
          <HealthFactor value={healthFactor} />
          {healthFactor.gte(new Fraction(3)) ? (
            <Flex justifyContent={'center'} color={'primary.caution'} gap={2}>
              <Icon as={Question} color={'primary.caution'} w={6} h={6} />
              <Text textStyle={'body3'}>
                When you disable this asset as collateral, you will lower your
                Health Factor and borrowing limit
              </Text>
            </Flex>
          ) : null}
        </Flex>
      </ModalOrDrawerBody>
      <ModalOrDrawerFooter>
        <Stack w={'full'} spacing={4}>
          <Paymaster />
          <Button isDisabled={healthFactorLtOne || isPaymasterError} onClick={disableAsCollateral}>
            {'Confirm'}
          </Button>
        </Stack>
      </ModalOrDrawerFooter>
    </>
  );
};

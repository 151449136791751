import { Copy, ExternalLink } from '@amply-app/icons';
import { currentWallet } from '@amply-app/wallet';
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Fade,
  Flex,
  Icon,
  Link,
  ModalBody,
  SimpleGrid,
  Text,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import { CurrentWalletIcon } from '@ui/components/CurrentWalletIcon';
import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';
import { Num } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import { Info } from '@ui/components/TextBlocks';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import {
  useAllPoolsData,
  useAmplyPrice,
  useTokenBalance,
  useUserWalletBalances,
} from '@ui/hooks/data';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import { isNumber } from 'lodash-es';
import Image from 'next/image';
import { type ReactElement, useMemo } from 'react';

import { useAccountModal } from '../useAccountModal';
import { BalanceDetails } from './BalanceDetails';
import { PioneerProgram } from './PioneerProgram';

const SWAP_LINKS: {
  href: string;
  icon: ReactElement | undefined;
}[] = [
  {
    href: EXTERNAL_LINK.h2Finance,
    icon: (
      <Image src="/images/h2.svg" width={'38'} height={'24'} alt="h2finance" />
    ),
  },
  {
    href: EXTERNAL_LINK.ebisusbay,
    icon: <Image src="/images/ebi.svg" width={'81'} height={'24'} alt="ebi" />,
  },
  {
    href: EXTERNAL_LINK.wolfswap,
    icon: (
      <Image
        src="/images/wolfswap.svg"
        width={'34'}
        height={'28'}
        alt="wolfswap"
      />
    ),
  },
];

const BRIDGE_LINKS: {
  href: string;
  icon: ReactElement | undefined;
}[] = [
  {
    href: EXTERNAL_LINK.cronosZkevm,
    icon: (
      <Image
        src="/images/cronos-zkevm.svg"
        width={'83'}
        height={'24'}
        alt="cronos-zkevm"
      />
    ),
  },
  {
    href: EXTERNAL_LINK.xyFinance,
    icon: (
      <Image
        src="/images/xy-finance.svg"
        width={'104'}
        height={'24'}
        alt="xy-finace"
      />
    ),
  },
  // have not integreated our chain yet
  // {
  //   href: EXTERNAL_LINK.symbiosis,
  //   icon: (
  //     <Image
  //       src="/images/symbiosis.svg"
  //       width={'92'}
  //       height={'24'}
  //       alt="symbiosis"
  //     />
  //   ),
  // },
  // {
  //   href: EXTERNAL_LINK.deBridgeFinance,
  //   icon: (
  //     <Image
  //       src="/images/deBridge-finance.svg"
  //       width={'109'}
  //       height={'24'}
  //       alt="deBridge-finance"
  //     />
  //   ),
  // },
];

export const DefaultContent = () => {
  const sdk = getSDK();
  const closeModal = useAccountModal((s) => s.closeModal);
  const account = currentWallet.useAccount();
  const { onCopy, hasCopied } = useClipboard(account ?? '');
  const { data: walletBalancesDetails } = useUserWalletBalances();
  const { data: poolsData } = useAllPoolsData();
  const amplyToken = sdk.getContractAddress('Amply').toLowerCase();
  const { data: tokenBalance } = useTokenBalance(amplyToken);
  const { data: amplyTokenPrice } = useAmplyPrice();

  const isEmptyWallet = useMemo(() => {
    {
      if (!walletBalancesDetails || !poolsData) return true;
      return poolsData
        .map((pool) => {
          const poolBalanceIndex =
            walletBalancesDetails.balanceTokenAddresses.findIndex(
              (item) => item === pool.tokenAddress,
            );
          const balance = isNumber(poolBalanceIndex)
            ? walletBalancesDetails.balances[poolBalanceIndex]
            : Fraction.ZERO;
          return balance;
        })
        .reduce((acc, cur) => acc.add(cur), Fraction.ZERO)
        .eq(Fraction.ZERO);
    }
  }, [poolsData, walletBalancesDetails]);
  return (
    <ModalBody p={4}>
      <Fade in={hasCopied} unmountOnExit>
        <Alert
          w="calc(100% - 32px)"
          p="10px 16px"
          mt="-5"
          position="absolute"
          bgColor="primary.success"
          rounded="5px"
          justifyContent="center"
        >
          <AlertDescription color="primary.text1">
            Wallet address copied
          </AlertDescription>
        </Alert>
      </Fade>

      <Flex gap={2} alignItems={'center'}>
        <CurrentWalletIcon />
        {`${account?.slice(0, 6)}...${account?.slice(-4)}`}
        <Icon as={Copy} color="primary.cta" cursor="pointer" onClick={onCopy} />
      </Flex>

      <PioneerProgram />

      {!isEmptyWallet && (
        <Flex gap={3} mt={6} direction="column">
          {poolsData?.map((pool) => {
            const poolBalanceIndex =
              walletBalancesDetails?.balanceTokenAddresses.findIndex(
                (item) => item === pool.tokenAddress,
              );
            return (
              <Box key={pool.symbol}>
                {isNumber(poolBalanceIndex) && (
                  <BalanceDetails
                    pool={pool}
                    balance={walletBalancesDetails?.balances[poolBalanceIndex]}
                  />
                )}
              </Box>
            );
          })}
          <Info
            title={'AMPLY'}
            content={
              <>
                <Num value={tokenBalance} />
                <TokenImage symbol={'AMPLY'} />
              </>
            }
            usdContent={
              <UsdValue value={tokenBalance} price={amplyTokenPrice} />
            }
          />
        </Flex>
      )}
      <Text textStyle={'body3'} mt={3}>
        {isEmptyWallet
          ? 'Your wallet looks a bit dry. Bridge assets with our partners to fill it up:'
          : 'Need more funds? Get more assets with our partners:'}
      </Text>
      {!isEmptyWallet && (
        <>
          <Text my={2} textStyle={'cta'} color={'primary.cta'}>
            Swap
          </Text>
          <SimpleGrid columns={2} spacing={3}>
            {SWAP_LINKS.map((details) => {
              return (
                <Button
                  as={Link}
                  variant={'outline'}
                  height={10}
                  rightIcon={<ExternalLink />}
                  href={details.href}
                  key={details.href}
                  isExternal
                >
                  {details.icon}
                </Button>
              );
            })}
          </SimpleGrid>
        </>
      )}
      <Text my={2} textStyle={'cta'} color={'primary.cta'}>
        Bridge
      </Text>
      <SimpleGrid columns={2} spacing={3}>
        {BRIDGE_LINKS.map((details) => {
          return (
            <Button
              as={Link}
              variant={'outline'}
              height={10}
              rightIcon={<ExternalLink />}
              href={details.href}
              key={details.href}
              isExternal
            >
              {details.icon}
            </Button>
          );
        })}
      </SimpleGrid>

      <VStack gap={6} mt={6}>
        <Button
          as={Link}
          href={EXTERNAL_LINK.gitbook.riskDisclosure}
          px={8}
          isExternal
        >
          Risk disclosure
        </Button>
        <Button
          variant={'link'}
          onClick={async () => {
            await currentWallet.disconnect();
            closeModal();
          }}
        >
          Disconnect wallet
        </Button>
      </VStack>
    </ModalBody>
  );
};

import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Switch,
  Text,
} from '@chakra-ui/react';
import { TxStatus } from '@ui/commonStateController';
import CardWithBorder from '@ui/components/CardWithBorder';
import InfoTooltip from '@ui/components/InfoTooltip';
import { Num } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import {
  SupplyAndBorrowType,
  useSupplyAndBorrowModal,
} from '@ui/components/SupplyAndBorrowModal';
import { Info } from '@ui/components/TextBlocks';
import { tokenAddressMap } from '@ui/components/TokenInput/constants';
import {
  useWithdrawAndRepayModal,
  WithdrawOrRepay,
} from '@ui/components/WithdrawAndRepayModal';
import type { UseAllPoolsData } from '@ui/hooks/data';
import { useAllPoolsData, useIncentiveAPY, useSupplyAPY } from '@ui/hooks/data';
import { find } from 'lodash-es';
import React, { useMemo } from 'react';

import {
  CollateralDrawerType,
  useCollateralDrawer,
} from '../CollateralDrawer/hooks';
import type { CardData } from '../Overview';
import AprSection from './AprSection';
import CardTopSection from './CardTopSection';

export interface Props {
  data?: CardData[];
}

const SupplyCards = ({ data }: Props) => {
  return (
    <Box overflowX={'auto'} maxW={'calc(100vw - 16px)'} py={1}>
      <Flex
        flexWrap={'nowrap'}
        w={'min-content'}
        gap={4}
        pr={4}
        alignItems={'flex-start'}
      >
        {data?.map((item) => (
          <SupplyCard key={item.symbol} data={item} />
        ))}
      </Flex>
    </Box>
  );
};

export default SupplyCards;

const SupplyCard = ({ data }: { data: CardData }) => {
  const {
    symbol,
    label,
    aTokenBalance,
    asCollateral,
    tokenAddress,
    tokenUSDPrice,
  } = data;
  const { data: supplyAPY } = useSupplyAPY(tokenAddress);
  const { data: poolsData } = useAllPoolsData();
  const tokenData = find(poolsData, (data) => data.symbol === symbol);
  const { data: incentiveData } = useIncentiveAPY();
  const incentiveSupplyData = useMemo(() => {
    const incentiveTokenIndex = incentiveData?.tokenAddress.findIndex(
      (item) => tokenAddressMap[item.toLowerCase()] === tokenData?.symbol,
    );
    return incentiveData?.supplyIncentiveData[incentiveTokenIndex!];
  }, [incentiveData, tokenData]);

  const [
    openDrawer,
    setAmount,
    setTokenSymbol,
    setTokenAddress,
    setTokenUSDValue,
    setType,
  ] = useCollateralDrawer((s) => [
    s.open,
    s.setAmount,
    s.setTokenSymbol,
    s.setTokenAddress,
    s.setTokenUSDValue,
    s.setType,
  ]);

  const handleWithDrawClick = () => {
    useWithdrawAndRepayModal.setState((s) => ({
      ...s,
      isOpen: true,
      type: WithdrawOrRepay.Withdraw,
      txStatus: TxStatus.Default,
      tokenSymbol: symbol,
      tokenAddress: tokenAddress,
      isCollateral: asCollateral,
      tokenBalance: aTokenBalance,
      tokenUSDPrice,
    }));
  };

  const handleSupplyMoreClick = () => {
    useSupplyAndBorrowModal.setState((s) => ({
      ...s,
      type: SupplyAndBorrowType.Supply,
      supplyTokenData: poolsData?.find(
        (pool: UseAllPoolsData) => pool.symbol === symbol,
      ),
      isOpen: true,
    }));
  };

  const handleSwitchAsCollateral = () => {
    openDrawer();
    setTokenSymbol(symbol);
    setTokenAddress(tokenAddress);
    setAmount(aTokenBalance);
    setTokenUSDValue(aTokenBalance.mul(tokenUSDPrice));
    setType(
      asCollateral ? CollateralDrawerType.Disable : CollateralDrawerType.Enable,
    );
  };

  return (
    <CardWithBorder
      w={'300px'}
      isLargeBorder
      key={symbol}
      color="primary.white"
    >
      <Card>
        <CardBody p={4}>
          <CardTopSection label={label} symbol={symbol} />
          <Flex gap={4} flexDir={'column'}>
            <Info
              title={
                <>
                  <Text>Supplied</Text>
                  <InfoTooltip label={'interest carrying'} />
                </>
              }
              content={<Num value={aTokenBalance} decimalPlaces={4} />}
              usdContent={
                <UsdValue
                  value={aTokenBalance}
                  price={tokenUSDPrice}
                  decimalPlaces={4}
                />
              }
            />

            <Info
              title={
                <>
                  <Text>{`${
                    asCollateral ? 'Disable' : 'Enable'
                  } as collateral`}</Text>
                  <InfoTooltip
                    label={
                      'Enabling this token as collateral increases Health Factor and lets you borrow against it, while disabling it accomplishes the opposite.'
                    }
                  />
                </>
              }
              content={
                <Switch
                  isChecked={asCollateral}
                  onChange={handleSwitchAsCollateral}
                />
              }
            />

            <AprSection
              type="supply"
              tokenAPY={supplyAPY}
              incentiveDetails={incentiveSupplyData}
            />
          </Flex>

          <Flex mt={5} mb={1.5} justifyContent={'space-between'}>
            <Button
              h={'30px'}
              variant={'outline'}
              minW={'100px'}
              onClick={handleWithDrawClick}
            >
              Withdraw
            </Button>
            <Button
              h={'30px'}
              minW={'160px'}
              variant={'outline'}
              onClick={handleSupplyMoreClick}
            >
              Supply more
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </CardWithBorder>
  );
};

import { Link, Text, VStack } from '@chakra-ui/react';
import { getExplorerLink } from '@ui/utils/getExplorerLink';
import type { ReactNode } from 'react';

import { useWithdrawAndRepayModal } from './hooks';

const SuccessContent = ({ description }: { description: ReactNode }) => {
  const txHash = useWithdrawAndRepayModal((s) => s.txHash);

  return (
    <>
      <VStack>
        <Text textStyle={'h3'} mt="24px">
          Transaction complete
        </Text>
        <Text mb={5} textStyle="body1" color={'primary.text2'} as={'div'}>
          {description}
        </Text>
        <Link
          href={getExplorerLink(txHash ?? '', 'transaction')}
          isExternal
          color="primary.cta"
          target="_blank"
          textStyle={'cta'}
        >
          View on CronosZK Explorer
        </Link>
      </VStack>
    </>
  );
};

export default SuccessContent;

import type { DotProps } from 'recharts';

import { textAnchor, textFill, textOffsetX, textOffsetY } from './constants';
import type { ViewBox } from './types';

export const CustomizedActiveDot = (
  props: DotProps & {
    value?: number;
    index?: number;
    customData?: string[];
    placement?: 'top' | 'auto';
    suffix?: string;
    prefix?: string;
    offsetY?: number;
  },
) => {
  const {
    cx,
    cy,
    value,
    r,
    strokeWidth,
    fill,
    index,
    fontSize,
    customData,
    placement = 'auto',
    suffix,
    prefix,
    offsetY,
    color,
  } = props;
  if (!cx || !cy || !value || !r) return null;

  const textX = index === 0 ? cx + textOffsetX : cx - textOffsetX;
  const textY = cy - textOffsetY - r * 2 - (offsetY ?? 0);

  const displayValue = customData?.[index ?? 0] ?? value;

  return (
    <>
      <text
        x={textX}
        y={placement === 'top' ? 30 : textY}
        fontSize={fontSize ?? '12px'}
        fill={color ? color : textFill}
        textAnchor={textAnchor}
      >
        {prefix}
        {displayValue}
        {suffix}
      </text>
      <circle
        cx={cx}
        cy={cy}
        r={r * 2}
        stroke={'#05121A'}
        strokeWidth={strokeWidth}
        fill={fill}
      />
    </>
  );
};

export const CustomizedReferenceDotLabel = (props: {
  viewBox?: ViewBox;
  value?: number;
  index?: number;
  fontSize?: string;
  suffix?: string;
  processor?: (value: number) => string;
}) => {
  const { value, viewBox, index, fontSize, suffix, processor } = props;
  if (!value || !viewBox) return null;

  const textX =
    index === 0
      ? viewBox.x + viewBox.width / 2 + textOffsetX
      : viewBox.x + viewBox.width / 2 - textOffsetX;
  const textY = viewBox.y - textOffsetY;

  return (
    <text
      x={textX}
      y={textY}
      fontSize={fontSize ?? '14px'}
      fill={textFill}
      textAnchor={textAnchor}
    >
      {processor ? processor(value) : value}
      {suffix}
    </text>
  );
};

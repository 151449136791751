import { ExpandDown } from '@amply-app/icons';
import { Box, Flex, HStack, Icon, Image, Text } from '@chakra-ui/react';
import type { UseAllPoolsData } from '@ui/hooks/data';
import { useAllPoolsData, useTokenBalance } from '@ui/hooks/data';
import { MOCK_ZKCRO_ADDRESS } from '@ui/hooks/data/constants';
import { usePaymasterAllowedTokens } from '@ui/hooks/data/usePaymasterAllowedTokens';
import useReservedGasFee from '@ui/hooks/data/useReservedGasFee';
import getPositiveOrZeroFraction from '@ui/utils/getPositiveOrZeroFraction';
import { Fraction } from 'bi-fraction';
import { useEffect, useMemo } from 'react';
import { create } from 'zustand';

import { Num } from '../Num';
import { tokenMap, tokenPathMap } from '../TokenInput/constants';
import {
  useWithdrawAndRepayModal,
  WithdrawOrRepay,
} from '../WithdrawAndRepayModal';
import { SupplyAndBorrowType, useSupplyAndBorrowModal } from '.';

interface UsePaymaster {
  token: string;
  isCollapse: boolean;
  setIsCollapse: (isCollapse: boolean) => void;
  isError: boolean;
  setIsError: (isError: boolean) => void;
}

export const usePaymaster = create<UsePaymaster>((set) => ({
  token: tokenMap.zkCRO,
  isCollapse: true,
  setIsCollapse: (isCollapse) => set({ isCollapse }),
  isError: false,
  setIsError: (isError) => set({ isError }),
}));

const PaymasterTokenBalance = ({
  tokenData,
  type,
}: {
  tokenData: UseAllPoolsData;
  type?: SupplyAndBorrowType | WithdrawOrRepay;
}) => {
  const { data: tokenBalance } = useTokenBalance(tokenData.tokenAddress);
  const [setIsCollapse, symbol, setIsError] = usePaymaster((state) => [
    state.setIsCollapse,
    state.token,
    state.setIsError,
  ]);
  const [supplyAmount, supplyTokenData] = useSupplyAndBorrowModal((s) => [
    s.supplyAmount,
    s.supplyTokenData,
  ]);
  const [repayAmount, repayTokenSymbol] = useWithdrawAndRepayModal((s) => [
    s.amount,
    s.tokenSymbol,
  ]);
  const gasFees = useReservedGasFee();
  const balance = useMemo(() => {
    if (
      type === SupplyAndBorrowType.Supply &&
      tokenData.tokenAddress === supplyTokenData?.tokenAddress &&
      supplyAmount?.gt(Fraction.ZERO)
    ) {
      const balance = tokenBalance?.sub(supplyAmount);
      return getPositiveOrZeroFraction(balance);
    } else if (
      type === WithdrawOrRepay.Repay &&
      tokenData.symbol === repayTokenSymbol
    ) {
      const balance = tokenBalance?.sub(repayAmount ?? Fraction.ZERO);
      return getPositiveOrZeroFraction(balance);
    }
    return tokenBalance;
  }, [
    repayAmount,
    repayTokenSymbol,
    supplyAmount,
    supplyTokenData?.tokenAddress,
    tokenBalance,
    tokenData.symbol,
    tokenData.tokenAddress,
    type,
  ]);
  const token = tokenData.symbol;
  const isSelected = token === symbol;
  const disabled = balance?.lt(gasFees?.[tokenData.symbol] ?? Fraction.ZERO);
  useEffect(() => {
    setIsError(Boolean(isSelected && disabled));
  }, [disabled, isSelected, setIsError]);
  return (
    <Box>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        color={'primary.white'}
        p={2}
        border={'0.5px solid'}
        borderColor={isSelected ? 'primary.cta' : 'transparent'}
        mb={2}
        _last={{
          mb: 0,
        }}
        onClick={() => {
          !disabled && usePaymaster.setState({ token });
          setIsCollapse(true);
        }}
        bg={disabled ? 'primary.disabled' : undefined}
        cursor={disabled ? 'not-allowed' : 'pointer'}
      >
        <Flex gap={1} textStyle={'body3'}>
          Balance: <Num as={'span'} value={balance} />
        </Flex>
        <Flex gap={1}>
          <Text color={'primary.text2'} textStyle={'body1'}>
            {token}
          </Text>
          <Image src={`/tokens/${tokenPathMap[token]}.svg`} alt={token} />
        </Flex>
      </Flex>
      {disabled && (
        <Text color="primary.error" textStyle={'body3'}>
          {`If your balance is below ${gasFees?.[tokenData.symbol].toFormat({
            decimalPlaces: 4,
          })} ${tokenData.symbol} it may not be enough to cover swap gas fees`}
        </Text>
      )}
    </Box>
  );
};

export const Paymaster = ({
  type,
}: {
  type?: SupplyAndBorrowType | WithdrawOrRepay;
}) => {
  const [isCollapse, setIsCollapse, symbol] = usePaymaster((state) => [
    state.isCollapse,
    state.setIsCollapse,
    state.token,
  ]);
  const { data: poolsData } = useAllPoolsData();
  const { data: allowedTokens } = usePaymasterAllowedTokens();
  return (
    <>
      <HStack justify={'space-between'}>
        <Text textStyle={'body1'} color={'primary.text2'}>
          Gas Token
        </Text>
        <Flex
          cursor={'pointer'}
          alignItems={'center'}
          gap={1}
          onClick={() => setIsCollapse(!isCollapse)}
        >
          <Text textStyle={'body1Bold'} color={'primary.white'}>
            {symbol}
          </Text>
          {
            <Image
              src={`/tokens/${tokenPathMap[symbol]}.svg`}
              alt={symbol}
              boxSize={6}
            />
          }
          <Box
            transition={'all 0.25s'}
            transform={isCollapse ? undefined : 'rotate(180deg)'}
            fontSize={0}
          >
            <Icon as={ExpandDown} w={6} h={6} color="primary.white" />
          </Box>
        </Flex>
      </HStack>
      <Box display={isCollapse ? 'none' : 'block'}>
        {poolsData
          ?.filter(
            (tokenData) =>
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              allowedTokens?.includes(tokenData.tokenAddress) ||
              tokenData.tokenAddress === MOCK_ZKCRO_ADDRESS,
          )
          ?.map((tokenData) => {
            return (
              <PaymasterTokenBalance
                key={tokenData.symbol}
                tokenData={tokenData}
                type={type}
              />
            );
          })}
      </Box>
    </>
  );
};

import { ChainId } from '@amply-app/sdk';
export const CRONOS_CHAIN_ID = (() => {
    switch (process.env.APP_ENV) {
        case 'prod':
        case 'uat':
            return ChainId.CRONOS_ZKEVM_MAINNET;
        default:
            return ChainId.CRONOS_ZKEVM_TESTNET;
    }
})();
const SUPPORTED_CHAIN_IDS = [Number(process.env.CHAIN_ID)];
export const getIsSupportedChainId = (chainId) => !!chainId && SUPPORTED_CHAIN_IDS.includes(chainId);
export const explorers = {
    [ChainId.CRONOS_ZKEVM_MAINNET]: 'https://explorer.zkevm.cronos.org',
    [ChainId.CRONOS_ZKEVM_TESTNET]: 'https://explorer.zkevm.cronos.org/testnet',
};
const CHAIN_CONFIG = {
    [ChainId.CRONOS_ZKEVM_MAINNET]: {
        chainId: 388,
        chainName: 'Cronos zkEVM Mainnet',
        nativeCurrency: {
            name: 'zkCRO',
            symbol: 'zkCRO',
            decimals: 18,
        },
        rpcUrls: [process.env.RPC_URL_388],
        blockExplorerUrls: [explorers[ChainId.CRONOS_ZKEVM_MAINNET]],
    },
    [ChainId.CRONOS_ZKEVM_TESTNET]: {
        chainId: 282,
        chainName: 'Cronos zkEVM Testnet',
        nativeCurrency: {
            name: 'zkTCRO',
            symbol: 'zkTCRO',
            decimals: 18,
        },
        rpcUrls: [process.env.RPC_URL_282],
        blockExplorerUrls: [explorers[ChainId.CRONOS_ZKEVM_TESTNET]],
    },
};
export const getAddEthereumChainParameter = (chainId) => {
    return CHAIN_CONFIG[chainId];
};
export const getChainConfig = () => {
    return CHAIN_CONFIG[CRONOS_CHAIN_ID];
};

import { Question } from '@amply-app/icons';
import { Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';

const Warning = ({
  type = 'caution',
  text,
}: {
  type?: 'alert' | 'caution';
  text: string;
}) => {
  const color = type === 'alert' ? 'primary.error' : 'primary.caution';
  return (
    <Flex gap={1} my={4}>
      <Icon as={Question} boxSize={6} color={color} />
      <Text textStyle={'body3'} color={color}>
        {text}
      </Text>
    </Flex>
  );
};

export default Warning;

import { AmplyLogo, AmplyLogoText } from '@amply-app/icons';
import { currentWallet } from '@amply-app/wallet';
import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { communityList, IconBtn } from '@ui/views/Landing/Introduction';
import { useRouter } from 'next/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useWalletModal } from '../Wallet';
import { useOnboardingModal } from './useOnboardingModal';

const ChakraSwiper = chakra(Swiper);

enum Titles {
  'Ready for Amply Finance?' = 'Ready for Amply Finance?',
  'Deposit Your Assets' = 'Deposit Your Assets',
  'Earn passive income' = 'Earn passive income',
  'Borrow tokens' = 'Borrow tokens',
  'Monitor your Health Factor (HF)' = 'Monitor your Health Factor (HF)',
  'Enter the fold' = 'Enter the fold',
}

const Slides = [
  {
    title: Titles['Ready for Amply Finance?'],
    description:
      'Follow these steps to start earning interest or access instant liquidity with your crypto assets.',
  },
  {
    title: Titles['Deposit Your Assets'],
    description:
      'To begin, deposit some of your assets to either earn interest or use as collateral for borrowing.',
    image: 'onboarding-deposit-your-assets.png',
  },
  {
    title: Titles['Earn passive income'],
    description:
      'As your deposit generates interest over time, be sure to track your earnings on the Dashboard.',
    image: 'onboarding-earn-passive-income.png',
  },
  {
    title: Titles['Borrow tokens'],
    description:
      'Just like how you might use your car or house to secure a loan, here you use your assets as collateral to borrow other tokens.',
    image: 'onboarding-borrow-tokens.png',
  },
  {
    title: Titles['Monitor your Health Factor (HF)'],
    description:
      'The Health Factor (HF) indicates the safety of your loan, so the higher the better. (We recommend 3 or above!)',
    image: 'onboarding-monitor-your-health-factor.png',
  },
  {
    title: Titles['Enter the fold'],
    description:
      'Ask questions, connect with the community and start amplifying your success.',
  },
];

export const OnboardingModal = () => {
  const isOpen = useOnboardingModal((s) => s.isOpen);
  const closeModal = useOnboardingModal((s) => s.closeModal);
  const isConnected = currentWallet.useIsConnected();
  const router = useRouter();
  const { openModal: openWalletModal } = useWalletModal();
  return (
    <Modal
      size={{
        base: 'md',
        desktop: 'lg',
      }}
      scrollBehavior="inside"
      onClose={closeModal}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent
        bgColor={'primary.background'}
        background={'center / cover url(/images/bg-full.svg)'}
      >
        <ModalHeader p={5} textStyle="body3">
          Onboarding
        </ModalHeader>
        <ModalCloseButton color={'primary.cta'} boxSize={6} top={5} right={5} />
        <ModalBody p={0}>
          <ChakraSwiper
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Navigation, Pagination]}
            sx={{
              '.swiper-pagination-bullets': {
                display: 'flex',
                width: 'fit-content',
                paddingBottom: '8px',
                paddingLeft: '24px',
                background: 'primary.icon_bg',
                borderRadius: '30px',
              },
              '.swiper-pagination-bullet': {
                background: 'primary.active_state',
                opacity: 1,
              },
              '.swiper-pagination-bullet-active': {
                background: 'primary.cta',
                width: '30px',
                borderRadius: '25px',
              },
              '.swiper-button-prev, .swiper-button-next': {
                position: 'absolute',
                top: 'unset',
                bottom: '6px',
                right: '24px',
                borderRadius: '50%',
                backgroundColor: 'primary.icon_bg',
                w: 7,
                h: 7,
                cursor: 'pointer',
                '&::after': {
                  color: 'primary.cta',
                  fontSize: 12,
                },
              },
              '.swiper-button-prev': {
                right: '76px',
                left: 'unset',
              },
            }}
          >
            {Slides.map(({ title, description, image }) => (
              <SwiperSlide key={title}>
                {title === Titles['Enter the fold'] && (
                  <Flex direction={'column'} alignItems={'center'}>
                    <Flex gap={8} mt={22} mb={18}>
                      {communityList.map(({ icon, link }) => (
                        <Link href={link} key={link} isExternal>
                          <IconBtn>
                            <Icon as={icon} color="primary.cta" w="6" h="6" />
                          </IconBtn>
                        </Link>
                      ))}
                    </Flex>
                    <Text textStyle={'h4'}>{title}</Text>
                    <Text mt={1.5} px={6}>
                      {description}
                    </Text>
                    <Button
                      mt={12}
                      onClick={() => {
                        if (isConnected) {
                          closeModal();
                          router.push('/markets');
                          return;
                        }
                        openWalletModal();
                      }}
                    >
                      {isConnected ? 'View our markets' : 'Connect wallet'}
                    </Button>
                    <Button mt={5} variant={'linkLarge'} onClick={closeModal}>
                      Done
                    </Button>
                  </Flex>
                )}
                {title !== Titles['Enter the fold'] && (
                  <Box>
                    {image ? (
                      <Image
                        src={`/images/${image}`}
                        alt={image}
                        objectFit={'cover'}
                        h={{ base: '309px', desktop: '360px' }}
                        m={'auto'}
                      />
                    ) : (
                      <Box h={'309px'}>
                        <Box
                          h={{ base: '180%', desktop: '120%' }}
                          background={
                            "center / cover no-repeat url('/images/bg-amply-token-mobile.svg')"
                          }
                          backgroundPosition={{ desktop: '0 50px' }}
                        />
                        <Center
                          gap="0"
                          pos={'absolute'}
                          top={'70px'}
                          w={'100%'}
                        >
                          <Icon
                            color="primary.cta"
                            as={AmplyLogo}
                            h={'40px'}
                            w={'85px'}
                          />
                          <Icon
                            color="primary.cta"
                            as={AmplyLogoText}
                            w={'197px'}
                            h={'50px'}
                            ml={'6px'}
                          />
                        </Center>
                      </Box>
                    )}
                    <Center textStyle={'h4'} mt={3}>
                      {title}
                    </Center>
                    <Box mt={1.5} px={6} height={'190px'} textAlign={'center'}>
                      {description}
                      {title === Titles['Monitor your Health Factor (HF)'] && (
                        <Box mt={4}>
                          <Button
                            variant={'outline'}
                            h={'30px'}
                            as={Link}
                            isExternal
                            href={EXTERNAL_LINK.gitbook.hf}
                          >
                            What factors might impact HF?
                          </Button>
                        </Box>
                      )}
                      {title === Titles['Ready for Amply Finance?'] && (
                        <Box mt={4}>
                          <Button
                            variant={'outline'}
                            h={'30px'}
                            as={Link}
                            isExternal
                            href={EXTERNAL_LINK.gitbook.home}
                          >
                            Take me to docs
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </SwiperSlide>
            ))}
          </ChakraSwiper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

import { useQuery } from '@tanstack/react-query';
import { H2_FINANCE_SUBGRAPH } from '@ui/config/subgraph';
import { RefetchInterval } from '@ui/config/ui';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

import { checkZKCROAddress } from './utils';

export const useTokenPrice = (tokenAddress?: string) => {
  const sdk = getSDK();
  return useQuery(
    ['useTokenPrice', tokenAddress],
    async () => {
      const address = checkZKCROAddress(tokenAddress);
      const price = await sdk.getAmplyOracle().getAssetPrice(address!);

      return new Fraction(price).shr(8);
    },
    { enabled: !!tokenAddress, refetchInterval: RefetchInterval.Normal },
  );
};

export const useTokenPrices = (tokenAddresses: string[]) => {
  const sdk = getSDK();
  return useQuery(
    ['useTokenPrices', tokenAddresses],
    async () => {
      const assresses = tokenAddresses.map(
        (addr) => checkZKCROAddress(addr) ?? '',
      );
      const prices = await sdk.getAmplyOracle().getAssetsPrices(assresses);

      return prices.map((price) => new Fraction(price).shr(8));
    },
    { enabled: !!tokenAddresses, refetchInterval: RefetchInterval.Normal },
  );
};

export const useAmplyPrice = () => {
  return useQuery(['useAmplyPrice'], async () => {
    const res = await fetch(H2_FINANCE_SUBGRAPH, {
      method: 'POST',
      body: JSON.stringify({
        query: `
        query MyQuery($id:ID!) {
          token(id: $id) {
            derivedUSD
          }
        }`,
        variables: {
          id: getSDK().getContractAddress('Amply').toLowerCase(),
        },
      }),
    });
    const resBody = await res.json();

    if (
      !resBody?.data?.token ||
      typeof resBody.data.token.derivedUSD !== 'string'
    ) {
      throw new Error('Invalid response structure');
    }
    return new Fraction(resBody.data.token.derivedUSD);
  });
};

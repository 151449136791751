import { useQuery } from '@tanstack/react-query';
import { RefetchInterval } from '@ui/config/ui';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

import { useAllPoolsData } from './useAllPoolsData';
import { checkZKCROAddress } from './utils';

export const useTotalBorrow = () => {
  const { data: poolsData } = useAllPoolsData();
  const sdk = getSDK();
  return useQuery(
    ['useTotalBorrow', poolsData],
    async () => {
      const borrowSize = await Promise.all(
        poolsData!.map(async (data) => {
          const tokenBorrow = await sdk
            .getVariableDebtToken(data.variableDebtToken)
            .totalSupply();
          const tokenDecimal = await sdk
            .getVariableDebtToken(data.variableDebtToken)
            .decimals();
          const address = checkZKCROAddress(data.tokenAddress);
          const price = await sdk.getAmplyOracle().getAssetPrice(address!);
          return new Fraction(tokenBorrow)
            .shr(Number(tokenDecimal))
            .mul(new Fraction(price).shr(8));
        }),
      );
      return borrowSize.reduce(
        (accumulator, currentValue) => accumulator.add(currentValue),
        Fraction.ZERO,
      );
    },
    { enabled: !!poolsData, refetchInterval: RefetchInterval.Normal },
  );
};

import { Fraction } from 'bi-fraction';
import { forwardRef, useMemo } from 'react';

import type { NumProps } from './Num';
import { Num } from './Num';

export type PercentProps = NumProps;

export const Percent = forwardRef<HTMLDivElement, PercentProps>(
  ({ suffix = '%', value, ...rest }, ref) => {
    const n = useMemo(() => {
      if (value === undefined) return undefined;

      return new Fraction(value).mul(100);
    }, [value]);

    return <Num suffix={suffix} value={n} ref={ref} gap="0" {...rest} />;
  },
);

Percent.displayName = 'Percent';

import type { DrawerContentProps } from '@chakra-ui/react';
import {
  DrawerContent,
  useDrawerContext,
  useModalContext,
} from '@chakra-ui/react';
import { useCallback, useId } from 'react';

import { DragBar } from './DragBar';
import { useDragController } from './hooks/useDragController';

const DRAG_DRAWER_CONTENT_CLASS_NAME = 'drag-drawer-content';

export const DragDrawerContent = ({
  children,
  ...props
}: DrawerContentProps) => {
  const id = useId();
  const { onClose } = useModalContext();
  const { placement } = useDrawerContext();
  const { ref, isTouching, triggerRef } = useDragController({
    onDrag: useCallback(
      (ele: HTMLDivElement, offsetY: number) =>
        (ele.style.bottom = `-${Math.max(offsetY, 0)}px`),
      [],
    ),
    onClose,
    onReset: useCallback((ele: HTMLDivElement) => (ele.style.bottom = '0'), []),
  });
  if (placement !== 'bottom')
    return <DrawerContent {...props}>{children}</DrawerContent>;
  return (
    <DrawerContent
      position="relative"
      {...props}
      className={`${
        props.className ?? ''
      } ${DRAG_DRAWER_CONTENT_CLASS_NAME} ${id}`}
      ref={ref}
    >
      <DragBar
        ref={triggerRef}
        position="absolute"
        top="0"
        left="50%"
        transform="translateX(-50%)"
        pt="8px"
        buttonContainerProps={{
          alignItems: 'flex-start',
        }}
        isTouching={isTouching}
        onToggle={onClose}
      />
      {children}
    </DrawerContent>
  );
};

import type { StateCreator } from 'zustand';

export interface ModalController {
  isOpen: boolean;

  close: () => void;
  open: () => void;
}

export type ModalOverride = Pick<ModalController, 'close' | 'isOpen'>;

export const createModalController: StateCreator<ModalController> = (set) => ({
  isOpen: false,

  close: () => {
    set({
      isOpen: false,
    });
  },
  open: () => {
    set({
      isOpen: true,
    });
  },
});

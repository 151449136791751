export type TimeHorizons = '1w' | '6m' | '1y' | 'all';
export const timeHorizons: TimeHorizons[] = ['1w', '6m', '1y', 'all'];
export const NUM_OF_DAYS_MAP: Record<TimeHorizons, number> = {
  '1w': 7,
  '6m': 180,
  '1y': 365,
  all: 9999,
};
export const aprTip =
  'The APR here is not inclusive of APR from token emissions';

import type { Fraction } from 'bi-fraction';
import { useMemo } from 'react';

import { useAllPoolsData } from './useAllPoolsData';
import { HealthFactorType } from './useHealthFactor';
import { useUserAccountData } from './useUserAccountData';
import { useUserReservesData } from './useUserReservesData';
import {
  computeDisableAsCollateralLTV,
  computeEnableAsCollateralLTV,
  computeSupplyLTV,
  computeWithdrawLTV,
  LTVType,
} from './utils';

export const useWeightedAverageCurrentLTV = (
  type: HealthFactorType,
  USD: Fraction,
  tokenAddress?: string,
) => {
  const { data: userReservesData } = useUserReservesData();
  const { data } = useUserAccountData();
  const { data: poolsData } = useAllPoolsData();
  return useMemo(() => {
    if (
      type === HealthFactorType.EnableAsCollateral &&
      userReservesData &&
      tokenAddress &&
      poolsData
    ) {
      return computeEnableAsCollateralLTV(
        tokenAddress,
        userReservesData,
        poolsData,
        LTVType.HealthFactor,
      );
    } else if (
      type === HealthFactorType.DisableAsCollateral &&
      userReservesData &&
      tokenAddress &&
      poolsData
    ) {
      return computeDisableAsCollateralLTV(
        tokenAddress,
        userReservesData,
        poolsData,
        LTVType.HealthFactor,
      );
    } else if (
      type === HealthFactorType.Supply &&
      userReservesData &&
      tokenAddress &&
      poolsData
    ) {
      return computeSupplyLTV(
        tokenAddress,
        userReservesData,
        poolsData,
        USD,
        LTVType.HealthFactor,
      );
    } else if (
      type === HealthFactorType.Withdraw &&
      userReservesData &&
      tokenAddress &&
      poolsData
    ) {
      return computeWithdrawLTV(
        tokenAddress,
        userReservesData,
        poolsData,
        USD,
        LTVType.HealthFactor,
      );
    }

    return data?.currentLiquidationThreshold;
  }, [
    type,
    USD,
    tokenAddress,
    data?.currentLiquidationThreshold,
    poolsData,
    userReservesData,
  ]);
};

import { Flex, Stack, Switch } from '@chakra-ui/react';
import { useAllPoolsData, useUserWalletBalances } from '@ui/hooks/data';
import { WITHDRAWAL_HF_WARNING_THRESHOLD } from '@ui/hooks/data/constants';
import {
  AvailableBorrowType,
  useAvailableBorrow,
} from '@ui/hooks/data/useAvailableBorrow';
import {
  HealthFactorType,
  useHealthFactor,
} from '@ui/hooks/data/useHealthFactor';
import useReservedGasFee from '@ui/hooks/data/useReservedGasFee';
import getPositiveOrZeroFraction from '@ui/utils/getPositiveOrZeroFraction';
import { Fraction } from 'bi-fraction';
import React, { useCallback, useMemo } from 'react';

import { useReservesData } from '../Dashboard/Overview';
import HealthFactor from '../HealthFactor';
import InfoTooltip from '../InfoTooltip';
import TokenImage from '../Markets/IndividualMarket/TokenImage';
import { Num } from '../Num';
import { UsdValue } from '../Num/UsdValue';
import { PercentageSelect } from '../PercentageSelect';
import { usePaymaster } from '../SupplyAndBorrowModal/Paymaster';
import { Info } from '../TextBlocks';
import { TokenInput } from '../TokenInput';
import { useWithdrawAndRepayModal, WithdrawOrRepay } from '.';
import { useMaxWithdrawTokens, useWithdrawAndRepayMsg } from './hooks';

const DefaultContent = () => {
  const [
    amount,
    tokenSymbol,
    isCollateral,
    type,
    setAmount,
    aAndVTokenBalance,
    tokenUSDPrice,
    errorMsg,
    warningMsg,
    isMaxAmount,
    setMaxAmount,
    isAcknowledged,
  ] = useWithdrawAndRepayModal((s) => [
    s.amountStr,
    s.tokenSymbol,
    s.isCollateral,
    s.type,
    s.setAmountStr,
    s.tokenBalance,
    s.tokenUSDPrice,
    s.inputError,
    s.inputWarning,
    s.isMaxAmount,
    s.setMaxAmount,
    s.isAcknowledged,
  ]);
  const isWithdraw = type === WithdrawOrRepay.Withdraw;

  const { data: poolsData } = useAllPoolsData();
  const gasTokenSymbol = usePaymaster((s) => s.token);
  const gasFeeMap = useReservedGasFee();
  const { data: walletBalancesDetails } = useUserWalletBalances();
  const maxRepayValue = useMemo(() => {
    if (
      gasTokenSymbol !== tokenSymbol ||
      !poolsData ||
      !walletBalancesDetails
    ) {
      return aAndVTokenBalance;
    }
    const tokenAddress = poolsData.find(
      (token) => token.symbol === tokenSymbol,
    )?.tokenAddress;
    const walletIndex = walletBalancesDetails.balanceTokenAddresses.findIndex(
      (addr) => addr === tokenAddress,
    );
    const walletBalance = walletBalancesDetails.balances[walletIndex];

    return walletBalance
      .sub(gasFeeMap?.[tokenSymbol] ?? Fraction.ZERO)
      .gte(aAndVTokenBalance ?? Fraction.ZERO)
      ? aAndVTokenBalance
      : getPositiveOrZeroFraction(
          walletBalance.sub(gasFeeMap?.[tokenSymbol] ?? Fraction.ZERO),
        );
  }, [
    aAndVTokenBalance,
    gasFeeMap,
    gasTokenSymbol,
    poolsData,
    tokenSymbol,
    walletBalancesDetails,
  ]);

  const maxWithdrawTokens = useMaxWithdrawTokens(
    isCollateral,
    aAndVTokenBalance,
    tokenUSDPrice,
  );

  const data = useReservesData((s) => s.data);
  const hasBorrow = data?.find((item) =>
    item.vDebtTokenBalance.gt(Fraction.ZERO),
  );

  const withdrawAndRepayUSD = useMemo(() => {
    if (!amount || !tokenUSDPrice) return Fraction.ZERO;
    return new Fraction(amount).mul(tokenUSDPrice);
  }, [amount, tokenUSDPrice]);

  const healthFactorAfterWithdraw = useHealthFactor(
    HealthFactorType.Withdraw,
    withdrawAndRepayUSD,
    poolsData?.find((pool) => pool.symbol === tokenSymbol)?.tokenAddress,
  );
  const healthFactorAfterRepay = useHealthFactor(
    HealthFactorType.Repay,
    withdrawAndRepayUSD,
  );

  const availableToBorrowUSD = useAvailableBorrow(
    AvailableBorrowType.Withdraw,
    withdrawAndRepayUSD,
    poolsData?.find((pool) => pool.symbol === tokenSymbol)?.tokenAddress,
  );
  const remainingSupplyAndDebt = aAndVTokenBalance?.sub(
    amount ?? Fraction.ZERO,
  );

  const handleAcknowledgedChange = () => {
    useWithdrawAndRepayModal.setState((s) => ({
      ...s,
      isAcknowledged: !s.isAcknowledged,
    }));
  };

  const render = useCallback(
    (isHealthFactorBelowThreshold?: boolean) => {
      if (isWithdraw)
        return (
          <>
            <Info
              title="Remaining supply"
              usdContent={
                <UsdValue
                  value={remainingSupplyAndDebt}
                  price={tokenUSDPrice}
                  decimalPlaces={4}
                />
              }
              content={
                <>
                  <Num value={remainingSupplyAndDebt} decimalPlaces={4} />
                  <TokenImage symbol={tokenSymbol} />
                </>
              }
            />
            {isCollateral ? (
              <>
                <Info
                  title={
                    <>
                      Available to borrow
                      <InfoTooltip label="Maximum amount available for you to borrow, assuming that there is no borrow cap" />
                    </>
                  }
                  content={<Num value={availableToBorrowUSD} suffix="USD" />}
                />
                {isHealthFactorBelowThreshold && hasBorrow && (
                  <Info
                    title={
                      <>
                        I acknowledge that withdrawing this amount will put me
                        at high risk of liquidation
                      </>
                    }
                    content={
                      <Flex alignSelf={'flex-start'}>
                        <Switch
                          isChecked={isAcknowledged}
                          onChange={handleAcknowledgedChange}
                        />
                      </Flex>
                    }
                  />
                )}
                <HealthFactor value={healthFactorAfterWithdraw} />
              </>
            ) : null}
          </>
        );

      return (
        <>
          <Info
            title="Remaining debt"
            usdContent={
              <UsdValue value={remainingSupplyAndDebt} price={tokenUSDPrice} />
            }
            content={
              <>
                <Num value={remainingSupplyAndDebt} />
                <TokenImage symbol={tokenSymbol} />
              </>
            }
          />
          <HealthFactor value={healthFactorAfterRepay} />
        </>
      );
    },
    [
      isWithdraw,
      remainingSupplyAndDebt,
      tokenUSDPrice,
      tokenSymbol,
      isCollateral,
      availableToBorrowUSD,
      hasBorrow,
      isAcknowledged,
      healthFactorAfterWithdraw,
      healthFactorAfterRepay,
    ],
  );

  useWithdrawAndRepayMsg({
    amount,
    aAndVTokenBalance,
    isWithdraw,
    isCollateral,
    healthFactorAfterWithdraw,
    healthFactorAfterRepay,
    maxWithdrawTokens,
    tokenData: poolsData?.find((pool) => pool.symbol === tokenSymbol),
  });

  return (
    <Stack spacing={4}>
      <TokenInput
        isShowBalance={!isWithdraw}
        selectedTokenData={poolsData?.find(
          (pool) => pool.symbol === tokenSymbol,
        )}
        onChange={(value) => {
          setAmount(value);
          isMaxAmount ? setMaxAmount(false) : null;
        }}
        value={amount ?? ''}
        errorMsg={errorMsg}
        warningMsg={warningMsg}
      />
      <PercentageSelect
        value={amount ?? ''}
        maxValue={isWithdraw ? maxWithdrawTokens : maxRepayValue}
        decimalPlaces={4}
        onSelect={setAmount}
        setMaxAmount={setMaxAmount}
      />
      {render(
        isWithdraw
          ? healthFactorAfterWithdraw.gt(Fraction.ZERO) &&
              healthFactorAfterWithdraw.lt(WITHDRAWAL_HF_WARNING_THRESHOLD)
          : false,
      )}
    </Stack>
  );
};

export default DefaultContent;

import { Fraction } from 'bi-fraction';
import { useMemo } from 'react';

import type { UseAllPoolsData } from './useAllPoolsData';
import { useTokenSupply } from './useATokens';
import { useTokenBorrow } from './useVariableDebtTokens';

export const useTokenUtilization = (
  tokenData: UseAllPoolsData,
): Fraction | undefined => {
  const { data: tokenBorrow } = useTokenBorrow(tokenData.variableDebtToken);
  const { data: tokenSupply } = useTokenSupply(tokenData.aTokenAddress);
  const tokenUtilization = useMemo(() => {
    return tokenSupply?.gt(0) ? tokenBorrow?.div(tokenSupply) : Fraction.ZERO;
  }, [tokenBorrow, tokenSupply]);

  return tokenUtilization;
};

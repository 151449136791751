import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import {
  AvailableBorrowType,
  useAvailableBorrow,
} from '@ui/hooks/data/useAvailableBorrow';
import {
  useUserTotalBorrowedUSD,
  useUserTotalSuppliedUSD,
} from '@ui/hooks/data/useUserTotalBalanceInUSD';
import { useWeightedAverageAPY } from '@ui/hooks/data/useWeightedAverageAPY';
import React from 'react';

import InfoTooltip from '../InfoTooltip';
import { Num, Percent } from '../Num';

const PortfolioOverview = () => {
  const weightedAverageAPY = useWeightedAverageAPY();

  const totalSuppliedUSD = useUserTotalSuppliedUSD();
  const totalBorrowedUSD = useUserTotalBorrowedUSD();

  const availableToBorrowUSD = useAvailableBorrow(AvailableBorrowType.OverAll);

  return (
    <Flex flexDir={'column'} gap={4} color={'primary.white'} mb={6}>
      <Flex gap={10}>
        <Box w={'130px'}>
          <Text textStyle={'body3'} color="primary.text2">
            Total supplied
          </Text>
          <Num value={totalSuppliedUSD} textStyle={'body2'} suffix={'USD'} />
        </Box>
        <Box w={'130px'}>
          <Text textStyle={'body3'} color="primary.text2">
            Total borrowed
          </Text>
          <Num value={totalBorrowedUSD} textStyle={'body2'} suffix={'USD'} />
        </Box>
      </Flex>
      <Flex gap={5}>
        <Box w={'150px'}>
          <HStack gap={0}>
            <Text textStyle={'body3'} color="primary.text2">
              Net Weighted APR
            </Text>
            <InfoTooltip
              title={'Net Weighted APR'}
              label="Amount of interest you earn relative to the amount you have to pay. A negative number means you’re paying more than you’re earning."
            />
          </HStack>
          <Percent value={weightedAverageAPY} textStyle={'body2'} />
        </Box>
        <Box w={'150px'}>
          <HStack gap={0}>
            <Text textStyle={'body3'} color="primary.text2">
              Available to borrow
            </Text>
            <InfoTooltip
              title={'Net Weighted APR'}
              label="Maximum amount that you are allowed to borrow."
            />
          </HStack>
          <Num
            value={availableToBorrowUSD}
            textStyle={'body2'}
            suffix={'USD'}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default PortfolioOverview;

import { Fraction } from 'bi-fraction';

import type { UserAccountData } from '../data/useUserAccountData';

export const checkWalletBalance = (
  amount: Fraction,
  walletTokenBalance: Fraction,
) => {
  if (walletTokenBalance.lt(amount)) {
    return 'Insufficient balance';
  }
  return undefined;
};

export const checkNewHealthFactor = (
  USD: Fraction,
  userAccountData: UserAccountData,
) => {
  const totalCollateralBase = userAccountData.totalCollateralBase;
  const currentLTV = userAccountData.currentLiquidationThreshold;
  const totalDebtBase = userAccountData.totalDebtBase;
  let newHealthFactor = Fraction.ZERO;
  if (totalDebtBase.eq(Fraction.ZERO)) return undefined;
  if (totalCollateralBase && currentLTV && totalDebtBase.gt(Fraction.ZERO)) {
    newHealthFactor = totalCollateralBase
      .mul(currentLTV)
      .div(totalDebtBase.add(USD));
  }
  if (newHealthFactor.lt(Fraction.ONE)) {
    return 'Health Factor is below the minimum allowed. Please lower borrowing amount';
  } else {
    return undefined;
  }
};

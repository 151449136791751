import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import type { FC } from 'react';

import { DragDrawerContent } from '../DragDrawer';
import { RenderInDesktop, RenderInMobile } from '../MobileOrDesktop';

export const ModalOrDrawer = hocModalOrDrawer('ModalOrDrawer', Drawer, Modal);
export const ModalOrDrawerContent = hocModalOrDrawer(
  'ModalOrDrawerContent',
  DrawerContent,
  ModalContent,
);
export const ModalOrDragDrawerContent = hocModalOrDrawer(
  'ModalOrDragDrawerContent',
  DragDrawerContent,
  ModalContent,
);
export const ModalOrDrawerOverlay = hocModalOrDrawer(
  'ModalOrDrawerOverlay',
  DrawerOverlay,
  ModalOverlay,
);
export const ModalOrDrawerBody = hocModalOrDrawer(
  'ModalOrDrawerBody',
  DrawerBody,
  ModalBody,
);
export const ModalOrDrawerHeader = hocModalOrDrawer(
  'ModalOrDrawerHeader',
  DrawerHeader,
  ModalHeader,
);
export const ModalOrDrawerFooter = hocModalOrDrawer(
  'ModalOrDrawerFooter',
  DrawerFooter,
  ModalFooter,
);

export const ModalOrDrawerCloseButton = hocModalOrDrawer(
  'ModalOrDrawerCloseButton',
  DrawerCloseButton,
  ModalCloseButton,
);

function hocModalOrDrawer<D, M>(
  name: string,
  DrawerPart: FC<D>,
  ModalPart: FC<M>,
) {
  const Hoc = (props: D & M & JSX.IntrinsicAttributes) => {
    return (
      <>
        <RenderInMobile>
          <DrawerPart {...props} />
        </RenderInMobile>
        <RenderInDesktop>
          <ModalPart {...props} />
        </RenderInDesktop>
      </>
    );
  };
  Hoc.displayName = name;
  // Hoc.defaultProps = {} as Partial<D & M>;
  return Hoc;
}

import { Button, ModalCloseButton, Stack, Text } from '@chakra-ui/react';
import { TxStatus } from '@ui/commonStateController';
import {
  HealthFactorType,
  useAllPoolsData,
  useHealthFactor,
} from '@ui/hooks/data';
import { WITHDRAWAL_HF_WARNING_THRESHOLD } from '@ui/hooks/data/constants';
import { Fraction } from 'bi-fraction';
import React, { useCallback, useMemo } from 'react';

import {
  TransactionErrorContent,
  TransactionLoadingContent,
} from '../CommonContents';
import { useReservesData } from '../Dashboard/Overview';
import { useWithdrawOrRepay } from '../Dashboard/Panels/hooks/useWithdrawOrRepay';
import { InDesktop } from '../MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '../ModalOrDrawer';
import { Num } from '../Num';
import { Paymaster, usePaymaster } from '../SupplyAndBorrowModal/Paymaster';
import DefaultContent from './DefaultContent';
import { useWithdrawAndRepayModal } from './hooks';
import SuccessContent from './SuccessContent';
import { WithdrawOrRepay } from './types';

export { useWithdrawAndRepayModal, WithdrawOrRepay };

const getText = (type: WithdrawOrRepay) => {
  switch (type) {
    case WithdrawOrRepay.Withdraw:
      return 'Withdraw';
    case WithdrawOrRepay.Repay:
      return 'Repay';
  }
};

const WithdrawAndRepayModal = () => {
  const [
    isOpen,
    closeModal,
    type,
    txStatus,
    tokenSymbol,
    amount,
    amountStr,
    txError,
    inputError,
    isAcknowledged,
    isCollateral,
    tokenUSDPrice,
  ] = useWithdrawAndRepayModal((s) => [
    s.isOpen,
    s.close,
    s.type,
    s.txStatus,
    s.tokenSymbol,
    s.amount,
    s.amountStr,
    s.txError,
    s.inputError,
    s.isAcknowledged,
    s.isCollateral,
    s.tokenUSDPrice,
  ]);

  const isPaymasterError = usePaymaster((s) => s.isError);
  const data = useReservesData((s) => s.data);
  const hasBorrow = data?.find((item) =>
    item.vDebtTokenBalance.gt(Fraction.ZERO),
  );

  const withdraw = useWithdrawOrRepay(WithdrawOrRepay.Withdraw);
  const repay = useWithdrawOrRepay(WithdrawOrRepay.Repay);

  const handleCTAClick = useCallback(() => {
    if (type === WithdrawOrRepay.Withdraw) {
      withdraw();
    } else {
      repay();
    }
  }, [repay, type, withdraw]);

  const { data: poolsData } = useAllPoolsData();

  const withdrawAndRepayUSD = useMemo(() => {
    if (!amount || !tokenUSDPrice) return Fraction.ZERO;
    return new Fraction(amount).mul(tokenUSDPrice);
  }, [amount, tokenUSDPrice]);

  const healthFactorAfterWithdraw = useHealthFactor(
    HealthFactorType.Withdraw,
    withdrawAndRepayUSD,
    poolsData?.find((pool) => pool.symbol === tokenSymbol)?.tokenAddress,
  );

  const renderBody = useCallback(() => {
    switch (txStatus) {
      case TxStatus.Loading:
        return <TransactionLoadingContent />;
      case TxStatus.Failed:
        return <TransactionErrorContent error={txError} />;
      case TxStatus.Success:
        return (
          <SuccessContent
            description={
              type === WithdrawOrRepay.Withdraw ? (
                <Text as="div">
                  You withdrew{' '}
                  <Text display={'inline-block'} as="div">
                    <Num value={amount} suffix={tokenSymbol} />
                  </Text>
                </Text>
              ) : (
                <Text as="div">
                  You repaid{' '}
                  <Text display={'inline-block'} as="div">
                    <Num value={amount} suffix={tokenSymbol} />
                  </Text>
                </Text>
              )
            }
          />
        );
      default:
        return <DefaultContent />;
    }
  }, [txStatus, txError, type, amount, tokenSymbol]);

  const renderButton = useCallback(() => {
    switch (txStatus) {
      case TxStatus.Loading:
        return <Button isLoading />;
      case TxStatus.Failed:
        return <Button onClick={closeModal}>Close</Button>;
      case TxStatus.Success:
        return <Button onClick={closeModal}>Done</Button>;
      default:
        return (
          <>
            <Paymaster type={type} />
            <Button
              isDisabled={
                isPaymasterError ||
                !amountStr ||
                !!inputError ||
                (type === WithdrawOrRepay.Withdraw &&
                  isCollateral &&
                  !isAcknowledged &&
                  !!hasBorrow &&
                  healthFactorAfterWithdraw.gt(Fraction.ZERO) &&
                  healthFactorAfterWithdraw.lt(WITHDRAWAL_HF_WARNING_THRESHOLD))
              }
              onClick={handleCTAClick}
            >
              {getText(type)}
            </Button>
          </>
        );
    }
  }, [
    amountStr,
    closeModal,
    handleCTAClick,
    hasBorrow,
    healthFactorAfterWithdraw,
    inputError,
    isAcknowledged,
    isCollateral,
    isPaymasterError,
    txStatus,
    type,
  ]);

  return (
    <ModalOrDrawer
      size={{
        base: 'full',
        desktop: 'lg',
      }}
      scrollBehavior="inside"
      placement="bottom"
      isOpen={isOpen}
      onClose={() => closeModal()}
    >
      <ModalOrDrawerOverlay />
      <ModalOrDragDrawerContent
        bgColor={'primary.background'}
        backgroundImage={'url(/images/bg-full.svg)'}
        backgroundSize={'cover'}
      >
        <ModalOrDrawerHeader pb={2} textStyle="body3">
          <Text>{getText(type)}</Text>
        </ModalOrDrawerHeader>
        <InDesktop>
          <ModalCloseButton
            color={'primary.cta'}
            boxSize={6}
            top={'26px'}
            right={'24px'}
          />
        </InDesktop>
        <ModalOrDrawerBody
          mt="10px"
          px={4}
          minH="320px"
          color="primary.white"
          zIndex={1}
        >
          {renderBody()}
        </ModalOrDrawerBody>
        <ModalOrDrawerFooter
          bg={'primary.background'}
          position={{ base: 'fixed', desktop: 'unset' }}
          bottom={0}
          zIndex={10}
          w={'100%'}
        >
          <Stack w={'full'} spacing={4}>
            {renderButton()}
          </Stack>
        </ModalOrDrawerFooter>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

export default WithdrawAndRepayModal;

import { Box, Button, Card, CardBody, Flex, Text } from '@chakra-ui/react';
import { TxStatus } from '@ui/commonStateController';
import CardWithBorder from '@ui/components/CardWithBorder';
import InfoTooltip from '@ui/components/InfoTooltip';
import { Num } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import {
  SupplyAndBorrowType,
  useSupplyAndBorrowModal,
} from '@ui/components/SupplyAndBorrowModal';
import { Info } from '@ui/components/TextBlocks';
import { tokenAddressMap } from '@ui/components/TokenInput/constants';
import {
  useWithdrawAndRepayModal,
  WithdrawOrRepay,
} from '@ui/components/WithdrawAndRepayModal';
import type { UseAllPoolsData } from '@ui/hooks/data';
import { useAllPoolsData, useBorrowAPY, useIncentiveAPY } from '@ui/hooks/data';
import { find } from 'lodash-es';
import React, { useMemo } from 'react';

import type { CardData } from '../Overview';
import AprSection from './AprSection';
import CardTopSection from './CardTopSection';

interface Props {
  data?: CardData[];
}

const BorrowCards = ({ data }: Props) => {
  return (
    <Box
      overflowX={'auto'}
      maxW={'calc(100vw - 16px)'}
      py={1}
      alignItems={'flex-start'}
    >
      <Flex flexWrap={'nowrap'} w={'min-content'} gap={4} pr={4}>
        {data?.map((item) => (
          <BorrowCard key={item.symbol} data={item} />
        ))}
      </Flex>
      {data?.length === 0 ? (
        <Text color={'primary.white'} textAlign={'center'} p={4}>
          No data
        </Text>
      ) : null}
    </Box>
  );
};

export default BorrowCards;

const BorrowCard = ({ data }: { data: CardData }) => {
  const { symbol, tokenAddress, label, vDebtTokenBalance, tokenUSDPrice } =
    data;

  const { data: borrowAPY } = useBorrowAPY(tokenAddress);
  const { data: poolsData } = useAllPoolsData();
  const tokenData = find(poolsData, (data) => data.symbol === symbol);
  const { data: incentiveData } = useIncentiveAPY();
  const incentiveBorrowData = useMemo(() => {
    const incentiveTokenIndex = incentiveData?.tokenAddress.findIndex(
      (item) => tokenAddressMap[item.toLowerCase()] === tokenData?.symbol,
    );
    return incentiveData?.supplyIncentiveData[incentiveTokenIndex!];
  }, [incentiveData, tokenData]);

  const handleRepayClick = () => {
    useWithdrawAndRepayModal.setState((s) => ({
      ...s,
      isOpen: true,
      type: WithdrawOrRepay.Repay,
      txStatus: TxStatus.Default,
      tokenSymbol: symbol,
      tokenAddress,
      tokenBalance: vDebtTokenBalance,
      tokenUSDPrice,
    }));
  };

  const handleBorrowMoreClick = () => {
    useSupplyAndBorrowModal.setState((s) => ({
      ...s,
      type: SupplyAndBorrowType.Borrow,
      borrowTokenData: poolsData?.find(
        (pool: UseAllPoolsData) => pool.symbol === symbol,
      ),
      isOpen: true,
    }));
  };

  return (
    <CardWithBorder
      w={'300px'}
      isLargeBorder
      key={symbol}
      color="primary.white"
    >
      <Card>
        <CardBody p={4}>
          <CardTopSection label={label} symbol={symbol} />
          <Flex gap={4} flexDir={'column'}>
            <Info
              title={
                <>
                  <Text>Borrowed</Text>
                  <InfoTooltip label={'interest carrying'} />
                </>
              }
              content={<Num value={vDebtTokenBalance} decimalPlaces={4} />}
              usdContent={
                <UsdValue
                  value={vDebtTokenBalance}
                  price={tokenUSDPrice}
                  decimalPlaces={4}
                />
              }
            />
            <AprSection
              type="borrow"
              tokenAPY={borrowAPY}
              incentiveDetails={incentiveBorrowData}
            />
          </Flex>

          <Flex mt={5} mb={1.5} justifyContent={'space-between'}>
            <Button
              h={'30px'}
              variant={'outline'}
              onClick={handleRepayClick}
              minW={'100px'}
            >
              Repay
            </Button>
            <Button
              h={'30px'}
              variant={'outline'}
              minW={'160px'}
              onClick={handleBorrowMoreClick}
            >
              Borrow more
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </CardWithBorder>
  );
};

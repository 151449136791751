import { AccountModal } from './AccountModal';
import { useAccountModal } from './AccountModal/useAccountModal';
import { DisableAsCollateralDrawer } from './Dashboard/CollateralDrawer/DisableAsCollateralDrawer';
import { EnableAsCollateralDrawer } from './Dashboard/CollateralDrawer/EnableAsCollateralDrawer';
import { useCollateralDrawer } from './Dashboard/CollateralDrawer/hooks';
import { DevUtils } from './DevUtils';
import { LazyComponent } from './LazyComponent';
import { OnboardingModal } from './OnboardingModal';
import { useOnboardingModal } from './OnboardingModal/useOnboardingModal';
import SupplyAndBorrowModal, {
  useSupplyAndBorrowModal,
} from './SupplyAndBorrowModal';
import { useWalletModal, WalletModal } from './Wallet';
import WithdrawAndRepayModal, {
  useWithdrawAndRepayModal,
} from './WithdrawAndRepayModal';

export const GlobalComponents = () => {
  const isOpenWalletModalOpen = useWalletModal((s) => s.isOpen);
  const isAccountModalOpen = useAccountModal((s) => s.isOpen);
  const isSupplyAndBorrowModalOpen = useSupplyAndBorrowModal((s) => s.isOpen);
  const isOnboardingModalOpen = useOnboardingModal((s) => s.isOpen);
  const isWithdrawAndRepayModalOpen = useWithdrawAndRepayModal((s) => s.isOpen);
  const isCollateralDrawerOpen = useCollateralDrawer((s) => s.isOpen);

  return (
    <>
      <DevUtils />
      <LazyComponent isOpen={isOnboardingModalOpen}>
        <OnboardingModal />
      </LazyComponent>
      <LazyComponent isOpen={isOpenWalletModalOpen}>
        <WalletModal />
      </LazyComponent>
      <LazyComponent isOpen={isAccountModalOpen}>
        <AccountModal />
      </LazyComponent>
      <LazyComponent isOpen={isSupplyAndBorrowModalOpen}>
        <SupplyAndBorrowModal />
      </LazyComponent>
      <LazyComponent isOpen={isWithdrawAndRepayModalOpen}>
        <WithdrawAndRepayModal />
      </LazyComponent>
      <LazyComponent isOpen={isCollateralDrawerOpen}>
        <EnableAsCollateralDrawer />
      </LazyComponent>
      <LazyComponent isOpen={isCollateralDrawerOpen}>
        <DisableAsCollateralDrawer />
      </LazyComponent>
    </>
  );
};

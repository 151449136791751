import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';

import Earnings from './Earnings';
import Portfolio from './Portfolio';

const PortfolioTabs = () => {
  return (
    <Box
      minW={{
        base: '100vw',
        desktop: '100%',
      }}
    >
      <Tabs mb={{ base: 10, desktop: 0 }}>
        <TabList>
          <Tab flex={1}>My portfolio</Tab>
          <Tab flex={1}>My earnings</Tab>
        </TabList>

        <TabPanels pt={4}>
          <TabPanel pl={4} pr={0}>
            <Portfolio />
          </TabPanel>
          <TabPanel px={4}>
            <Earnings />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default PortfolioTabs;

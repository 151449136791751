import { Flex } from '@chakra-ui/react';
import { useUserAccountData } from '@ui/hooks/data/useUserAccountData';
import { isHealthFactorInWarning } from '@ui/hooks/data/utils';
import React from 'react';

import HealthFactor from '../HealthFactor';
import Warning from '../HealthFactor/Warning';

const HealthSection = () => {
  const { data } = useUserAccountData();
  const healthFactor = data?.healthFactor;
  const showWarning = isHealthFactorInWarning(healthFactor);

  return (
    <Flex
      mb={8}
      mt={{ base: 6, desktop: 0 }}
      flexDir={{ base: 'column', desktop: 'column-reverse' }}
    >
      {showWarning ? (
        <Warning text="To keep your Health Factor above 3, we recommend lowering your debt or supplying more" />
      ) : null}
      <HealthFactor value={healthFactor} />
    </Flex>
  );
};

export default HealthSection;

/**
 * Modal theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/modal.ts
 */
import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { textStyles } from '../textStyles';
import { viewportHeightVariable } from '../utils/useInjectedCssVarOfViewport';
export const Modal = {
    parts: parts.keys,
    baseStyle() {
        return {
            dialog: {
                bg: 'primary.layer1',
            },
            overlay: {
                // show overlay on top of parent modal
                zIndex: '1400',
                bg: 'rgba(23, 24, 26, 0.75)',
            },
            header: {
                ...textStyles?.body3,
                color: 'primary.white',
                justifyContent: 'center',
                py: '26px',
                textAlign: 'center',
            },
        };
    },
    variants: {},
    sizes: {
        md: {
            dialogContainer: {
                px: 4,
            },
        },
        lg: {
            dialog: {
                w: '500px',
                h: '700px',
            },
        },
        full(props) {
            /**
             * Fix missing modal footer on iOS.
             * @see https://github.com/chakra-ui/chakra-ui/issues/4903
             */
            const isScrollInside = props.scrollBehavior === 'inside';
            return {
                dialogContainer: {
                    ...(!isScrollInside && fullStyles),
                    /**
                     * keep some gap for modal in full size
                     */
                    height: `calc(${fullStyles.height} - 54px)`,
                    bottom: '24px',
                    top: 'unset',
                    roundedTop: '10px',
                    px: 4,
                },
                dialog: {
                    ...(isScrollInside && fullStyles),
                    my: 0,
                    ml: 0,
                    mr: 0,
                    mt: 0,
                    mb: 0,
                },
            };
        },
    },
    defaultProps: {},
};
const fullStyles = {
    minHeight: 'auto',
    maxHeight: 'unset',
    height: `var(${viewportHeightVariable}, -webkit-fill-available)`,
};

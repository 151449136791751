/**
 * Drawer theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/drawer.ts
 */
import { drawerAnatomy as parts } from '@chakra-ui/anatomy';
import { textStyles } from '../textStyles';
import { viewportHeightVariable } from '../utils/useInjectedCssVarOfViewport';
export const Drawer = {
    parts: parts.keys,
    baseStyle(props) {
        return {
            dialog: {
                ...(props.isFullHeight && fullStyles),
                backgroundColor: 'primary.layer1',
            },
            overlay: {
                // show overlay on top of parent modal
                zIndex: '1400',
                bg: 'rgba(23, 24, 26, 0.75)',
            },
            header: {
                ...textStyles?.body3,
                color: 'primary.white',
                justifyContent: 'center',
                py: '26px',
                textAlign: 'center',
            },
        };
    },
    variants: {},
    sizes: {
        full() {
            return {
                dialog: {
                    ...fullStyles,
                    /**
                     * keep some gap for drag-drawer in full size
                     */
                    '&.drag-drawer-content': {
                        height: `calc(${fullStyles.height} - 30px)`,
                    },
                },
            };
        },
    },
    defaultProps: {},
};
/**
 * Fix missing modal footer on iOS.
 * @see https://github.com/chakra-ui/chakra-ui/issues/4903
 */
const fullStyles = {
    minHeight: 'auto',
    maxHeight: 'unset',
    height: `var(${viewportHeightVariable}, -webkit-fill-available)`,
};

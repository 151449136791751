import {
  Discord,
  IconBtnBorder,
  LandingBlockBorder,
  Telegram,
  X,
} from '@amply-app/icons';
import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { InDesktop, InMobile } from '@ui/components/MobileOrDesktop';
import { Num } from '@ui/components/Num';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { useTotalMarketSize } from '@ui/hooks/data';
import { useTotalBorrow } from '@ui/hooks/data/useTotalBorrow';
import { useRouter } from 'next/router';
import type { PropsWithChildren } from 'react';

export const IconBtn = ({ children }: PropsWithChildren) => {
  return (
    <Flex
      w="10"
      h="10"
      background="primary.icon_btn"
      pos="relative"
      alignItems="center"
      justifyContent="center"
    >
      <Icon
        as={IconBtnBorder}
        pos="absolute"
        left="7px"
        top="-1px"
        color="primary.cta"
        w="34px"
        h="15px"
      />

      {children}
      <Icon
        transform="rotate(180deg)"
        as={IconBtnBorder}
        pos="absolute"
        left="-1px"
        bottom="-1px"
        color="primary.cta"
        w="34px"
        h="15px"
      />
    </Flex>
  );
};

export const communityList = [
  { icon: Discord, link: EXTERNAL_LINK.discord },
  { icon: Telegram, link: EXTERNAL_LINK.telegram },
  { icon: X, link: EXTERNAL_LINK.twitter },
];

export const Introduction = () => {
  const { data: totalMarketSize } = useTotalMarketSize();
  const { data: totalBorrow } = useTotalBorrow();
  const router = useRouter();
  const isShowUnit = useBreakpointValue({ base: true, desktop: false });
  return (
    <Box pos={'relative'}>
      <InMobile>
        <Icon
          as={LandingBlockBorder}
          pos={'absolute'}
          w={'197.5px'}
          h={'96.25px'}
          color={'primary.active_state'}
          right={'26.5px'}
          top={'-40px'}
        />
      </InMobile>
      <Text
        mt={16}
        color="primary.cta"
        textStyle="landingTitle"
        mb={{ base: '10px', desktop: '80px' }}
        fontSize={{ base: '40px', desktop: '74px' }}
      >
        Amplify Your Impact
      </Text>
      <Text textStyle={{ base: 'body1', desktop: 'h3' }}>
        Earn and borrow your favorite assets with Amply Finance to go further
        together
      </Text>
      <Button mt="6" mb="8" w={'171px'} onClick={() => router.push('/markets')}>
        View our markets
      </Button>
      <Flex gap={'2.5'} mb={8} alignItems={'center'}>
        <InDesktop>
          <Box
            w={'153px'}
            pos={'relative'}
            h={'1px'}
            bg={'primary.active_state'}
          >
            <Box
              pos={'absolute'}
              bg={'primary.active_state'}
              h={'7px'}
              w={'65px'}
              left={0}
              top={'-3px'}
            />
            <Box
              pos={'absolute'}
              bg={'primary.active_state'}
              h={'7px'}
              w={'10px'}
              right={0}
              top={'-3px'}
            />
          </Box>
        </InDesktop>
        <Text color={'primary.cta'} textStyle={'body3'}>
          Enter the fold
        </Text>
        <Box
          flex={1}
          pos={'relative'}
          h={'1px'}
          bg={'primary.active_state'}
          maxW={{ desktop: '270px' }}
        >
          <Box
            pos={'absolute'}
            bg={'primary.active_state'}
            h={'7px'}
            w={{ base: '65px', desktop: '7px' }}
            left={0}
            top={'-3px'}
          />
          <Box
            pos={'absolute'}
            bg={'primary.active_state'}
            h={'7px'}
            w={{ base: '10px', desktop: '32px' }}
            right={0}
            top={'-3px'}
          />
        </Box>
      </Flex>
      <Flex gap={8} mb={12}>
        {communityList.map(({ icon, link }) => (
          <Link href={link} key={link} isExternal>
            <IconBtn>
              <Icon as={icon} color="primary.cta" w="6" h="6" />
            </IconBtn>
          </Link>
        ))}
      </Flex>
      <Flex gap={{ base: 6, desktop: 9 }} alignItems={'center'}>
        <Box>
          <Text
            color={'primary.cta'}
            textStyle={{ base: 'body3', desktop: 'h2' }}
          >
            Total supplied
          </Text>
          <Num
            value={totalMarketSize}
            prefix={'$'}
            textStyle={{ base: 'h3', desktop: 'h2' }}
            isShowUnit={isShowUnit}
          />
        </Box>
        <Box
          pos={'relative'}
          w={'1px'}
          bg={'primary.active_state'}
          h={{ base: '71px', desktop: '113px' }}
        >
          <Box
            pos={'absolute'}
            bg={'primary.active_state'}
            h={{ base: '12px', desktop: '22px' }}
            w={'5px'}
            top={0}
            left={'-2px'}
          />
          <Box
            pos={'absolute'}
            bg={'primary.active_state'}
            h={{ base: '21px', desktop: '34px' }}
            w={'5px'}
            bottom={0}
            left={'-2px'}
          />
        </Box>
        <Box>
          <Text
            color={'primary.cta'}
            textStyle={{ base: 'body3', desktop: 'h2' }}
          >
            Total borrowed
          </Text>
          <Num
            value={totalBorrow}
            prefix={'$'}
            textStyle={{ base: 'h3', desktop: 'h2' }}
            isShowUnit={isShowUnit}
          />
        </Box>
      </Flex>
    </Box>
  );
};

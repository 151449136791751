import type { ImageProps } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { tokenPathMap } from '@ui/components/TokenInput/constants';
import React from 'react';

const TokenImage = ({ symbol, ...props }: ImageProps & { symbol?: string }) => {
  if (!symbol) return null;

  return (
    <Image
      w={props.w ?? '24px'}
      src={`/tokens/${tokenPathMap[symbol]}.svg`}
      alt={symbol}
      {...props}
    />
  );
};

export default TokenImage;

import type { ContractAddressMap } from '@amply-app/sdk';
import { TxStatus } from '@ui/commonStateController';
import { useSetUserUseReserveAsCollateral } from '@ui/hooks/data';
import type { EthersError } from 'ethers';
import { useCallback } from 'react';

import { useCollateralDrawer } from './';

export const useSetAsCollateral = (asCollateral: boolean) => {
  const mutation = useSetUserUseReserveAsCollateral();

  const [tokenSymbol, setTxStatus, setTxHash, setTxError] = useCollateralDrawer(
    (s) => [s.tokenSymbol, s.setTxStatus, s.setTxHash, s.setTxError],
  );
  return useCallback(async () => {
    try {
      setTxStatus(TxStatus.Loading);

      if (!tokenSymbol) throw new Error(`Token is required`);

      const tx = await mutation.mutateAsync({
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        tokenSymbol: tokenSymbol as keyof ContractAddressMap,
        asCollateral,
      });
      setTxHash(tx?.hash ?? '');

      setTxStatus(TxStatus.Success);
    } catch (e: unknown) {
      setTxStatus(TxStatus.Failed);
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setTxError(e as EthersError);
    }
  }, [setTxStatus, tokenSymbol, mutation, asCollateral, setTxHash, setTxError]);
};

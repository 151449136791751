import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);
const baseStyle = definePartsStyle({
    container: {},
    thumb: {
        bg: 'primary.cta',
        _checked: {},
    },
    track: {
        bg: 'gradient.blur',
        border: '0.5px solid',
        borderColor: 'primary.cta',
        _checked: {
            bg: 'primary.layer0',
        },
    },
});
export const Switch = defineMultiStyleConfig({ baseStyle });

import { currentWallet } from '@amply-app/wallet';
import { useQuery } from '@tanstack/react-query';
import { RefetchInterval } from '@ui/config/ui';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

import { formatAddressForNativeToken } from './utils';

export const useTokenBalance = (tokenAddress?: string) => {
  const account = currentWallet.useAccount();
  const sdk = getSDK();
  return useQuery(
    ['useTokenBalance', tokenAddress, account],
    async () => {
      const _tokenAddress = formatAddressForNativeToken(tokenAddress);

      const [balanceBn, decimalBn] = await sdk
        .getWalletBalanceProvider()
        .balanceOf(account!, _tokenAddress!);

      return new Fraction(balanceBn).shr(Number(decimalBn));
    },
    {
      enabled: !!account && !!tokenAddress,
      refetchInterval: RefetchInterval.Normal,
    },
  );
};

export const useUserWalletBalances = () => {
  const account = currentWallet.useAccount();
  const sdk = getSDK();
  return useQuery(
    ['useUserWalletBalances', account],
    async () => {
      const provider = sdk
        .getContractAddress('PoolAddressesProvider')
        .toLowerCase();
      const data = await sdk
        .getWalletBalanceProvider()
        .getUserWalletBalances(provider, account!);
      return {
        balanceTokenAddresses: data[0],
        balances: data[1].map((item, index) =>
          new Fraction(item).shr(Number(data[2][index])),
        ),
      };
    },
    {
      enabled: !!account,
      refetchInterval: RefetchInterval.Normal,
    },
  );
};

import { currentWallet } from '@amply-app/wallet';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { RefetchInterval } from '@ui/config/ui';
import { getGasOverrides, getPaymasterParams, getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import { BrowserProvider } from 'zksync-ethers';

import { useAllPoolsData } from './useAllPoolsData';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useClaimAmplyVesting = () => {
  const account = useAccount();
  const provider = useProvider();
  const connectorProvider = getConnector().provider;
  const chainId = useChainId();
  const { data: allPoolsData } = useAllPoolsData();
  const gasTokenSymbol = usePaymaster((s) => s.token);
  const gasTokenAddress =
    allPoolsData?.find((token) => token.symbol === gasTokenSymbol)
      ?.tokenAddress ?? '';
  return useMutation(async ({ stakeAmply }: { stakeAmply: boolean }) => {
    if (!chainId || !provider || !account || !connectorProvider) return;
    const sdk = getSDK();
    const browserSigner = await new BrowserProvider(
      connectorProvider,
    ).getSigner();

    let overrides = {};
    if (gasTokenSymbol !== tokenMap.zkCRO) {
      const paymasterParams = getPaymasterParams({ gasTokenAddress });
      const gasLimit = await sdk
        .getAmplyVesting(browserSigner)
        .claim.estimateGas(stakeAmply);
      overrides = await getGasOverrides({ gasLimit, paymasterParams });
    }

    const tx = await sdk
      .getAmplyVesting(browserSigner)
      .claim(stakeAmply, overrides);
    return await tx.wait();
  });
};

export const useClaimableAmount = () => {
  const sdk = getSDK();
  const account = useAccount();
  return useQuery(
    ['useClaimableAmount', account],
    async () => {
      const amount = await sdk.getAmplyVesting().getClaimableAmount(account!);
      return new Fraction(amount).shr(18);
    },
    { enabled: !!account, refetchInterval: RefetchInterval.Normal },
  );
};

export const useClaimedAmount = () => {
  const sdk = getSDK();
  const account = useAccount();
  return useQuery(
    ['useClaimedAmount', account],
    async () => {
      const amount = await sdk.getAmplyVesting().getClaimedAmount(account!);
      return new Fraction(amount).shr(18);
    },
    { enabled: !!account, refetchInterval: RefetchInterval.Normal },
  );
};

export const useRemainingAmount = () => {
  const sdk = getSDK();
  const account = useAccount();
  return useQuery(
    ['useRemainingAmount', account],
    async () => {
      const amount = await sdk.getAmplyVesting().getRemainingAmount(account!);
      return new Fraction(amount).shr(18);
    },
    { enabled: !!account, refetchInterval: RefetchInterval.Normal },
  );
};

export const useConvertibleAmount = () => {
  const sdk = getSDK();
  const account = useAccount();
  return useQuery(
    ['useConvertibleAmount', account],
    async () => {
      const amount = await sdk.getAmplyVesting().getConvertibleAmount(account!);
      return new Fraction(amount).shr(18);
    },
    { enabled: !!account, refetchInterval: RefetchInterval.Normal },
  );
};

export const useAmplyEventTimes = () => {
  const sdk = getSDK();
  return useQuery(['useAmplyEventTimes'], async () => {
    const vesting = sdk.getAmplyVesting();
    const [claimOpenAt, claimCloseAt, vestStartAt, vestEndAt] =
      await Promise.all([
        vesting.claimOpenAt(),
        vesting.claimClosedAt(),
        vesting.vestStartAt(),
        vesting.vestEndAt(),
      ]);
    return {
      claimOpenAt: new Date(Number(claimOpenAt) * 1000),
      claimCloseAt: new Date(Number(claimCloseAt) * 1000),
      vestStartAt: new Date(Number(vestStartAt) * 1000),
      vestEndAt: new Date(Number(vestEndAt) * 1000),
    };
  });
};

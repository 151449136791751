import { useBreakpointValue } from '@chakra-ui/react';
import { useMemo } from 'react';
export const useBreakpointRanges = () => {
    const breakPoint = useBreakpointValue({
        base: 'base',
        xxs: 'xxs',
        sm: 'sm',
        md: 'md',
        lg: 'lg',
        xl: 'xl',
        '2xl': '2xl',
    }) || 'xl';
    return useMemo(() => {
        const isBaseToXxs = breakPoint === 'base';
        const isXxsToSm = breakPoint === 'xxs';
        const isBaseToSm = isBaseToXxs || isXxsToSm;
        const isSmToMd = breakPoint === 'sm';
        const isMdToLarge = breakPoint === 'md';
        const isLargeToXLarge = breakPoint === 'lg';
        const isXLargeToXxLarge = breakPoint === 'xl';
        const isXxLargeAndUp = breakPoint === '2xl';
        const isXLargeAndUp = isXLargeToXxLarge || isXxLargeAndUp;
        const isLargeAndUp = isLargeToXLarge || isXLargeAndUp;
        const isMdAndUp = isMdToLarge || isLargeAndUp;
        const isSmAndUp = isSmToMd || isMdAndUp;
        const isXxsAndUp = isXxsToSm || isSmAndUp;
        const isBaseToMd = isBaseToSm || isSmToMd;
        return {
            breakPoint,
            isBaseToXxs,
            isBaseToSm,
            isBaseToMd,
            isBaseToTablet: isBaseToSm || isSmToMd,
            isXxsToSm,
            isSmToMd,
            isMdToLarge,
            isLargeToXLarge,
            isXLargeToXxLarge,
            isXxsAndUp,
            isSmAndUp,
            isMdAndUp,
            isLargeAndUp,
            isXLargeAndUp,
            isXxLargeAndUp,
            isTabletToDesktop: isMdToLarge,
            isTabletAndUp: isMdAndUp,
            isDesktopAndUp: isLargeAndUp,
        };
    }, [breakPoint]);
};

import { Box } from '@chakra-ui/react';
import React from 'react';
import {
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from 'recharts';

import { textAnchor, textFill, textOffsetX } from './constants';
import { CustomizedActiveDot } from './CustomizedChartTexts';
import type { InterestRateChartProps, ViewBox } from './types';

const timeTextOffsetY = -20;

const CustomizedReferenceLineLabel = (props: {
  title: string;
  viewBox?: ViewBox;
  index?: number;
  offsetY?: number;
}) => {
  const { viewBox, index, title, offsetY } = props;
  if (!viewBox) return null;
  const textX =
    (index ?? 0) < 50 ? viewBox.x + textOffsetX : viewBox.x - textOffsetX;
  const textY = viewBox.y - timeTextOffsetY + 20 + (offsetY ?? 0);

  return (
    <text
      x={textX}
      y={textY}
      fontSize={'12px'}
      fill={textFill}
      textAnchor={textAnchor}
    >
      {title}
    </text>
  );
};

export const InterestRateChart = ({
  data,
  ...props
}: InterestRateChartProps) => {
  const currentIndex = data.findIndex((d) => d.current);
  const uOptimalIndex = data[0].kink;

  return (
    <Box {...props}>
      <ResponsiveContainer width={'100%'} aspect={1.4}>
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 20,
            left: 52,
          }}
        >
          <Tooltip
            content={() => null}
            cursor={{
              strokeOpacity: '0.12',
              stroke: 'white',
            }}
          />
          <Legend
            align="left"
            iconType="circle"
            wrapperStyle={{
              paddingTop: '16px',
              paddingLeft: '16px',
              fontSize: '12px',
            }}
            payload={[
              {
                value: 'Supply Rate',
                type: 'circle',
                color: '#F4F499',
              },
              {
                value: 'Borrow Rate',
                type: 'circle',
                color: '#FF96DF',
              },
            ]}
          />
          <YAxis hide />
          <Line
            strokeWidth={'4px'}
            dot={false}
            type="monotone"
            dataKey="utilityDisplay"
            stroke="transparent"
            activeDot={
              <CustomizedActiveDot
                placement="top"
                customData={data.map((d) => d.utility)}
                prefix="Utility: "
                suffix="%"
              />
            }
          />
          <Line
            strokeWidth={'4px'}
            dot={false}
            type="monotone"
            dataKey="supplyRate"
            stroke="#F4F499"
            activeDot={
              <CustomizedActiveDot color="#F4F499" offsetY={40} suffix="%" />
            }
          />
          <Line
            strokeWidth={'4px'}
            dot={false}
            type="monotone"
            dataKey="borrowRate"
            stroke="#FF96DF"
            activeDot={<CustomizedActiveDot color="#FF96DF" suffix="%" />}
          />

          <ReferenceLine
            x={+currentIndex}
            stroke="rgba(255,255,255, 0.12)"
            height={'100px'}
            label={
              <CustomizedReferenceLineLabel
                title={`Current: ${currentIndex}%`}
                index={currentIndex}
                offsetY={15}
              />
            }
          />
          <ReferenceLine
            x={+uOptimalIndex}
            stroke="rgba(255,255,255, 0.12)"
            label={
              <CustomizedReferenceLineLabel
                title={`Optimal: ${uOptimalIndex}%`}
                index={uOptimalIndex}
              />
            }
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

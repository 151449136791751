import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { RefetchInterval } from '@ui/config/ui';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

import { MOCK_ZKCRO_ADDRESS } from './constants';

export interface UseAllPoolsData {
  tokenAddress: string;
  label: string;
  symbol: string;
  aTokenAddress: string;
  variableDebtToken: string;
  baseLTVasCollateral: Fraction;
  reserveLiquidationThreshold: Fraction;
  reserveLiquidationBonus: Fraction;
  reserveFactor: Fraction;
  borrowCap: Fraction;
  supplyCap: Fraction;
  availableLiquidity: Fraction;

  kink: number;
  baseVariableBorrowRate: number;
  variableSlopeRate1: number;
  variableSlopeRate2: number;
  decimals: number;
}

export const useAllPoolsData = (): UseQueryResult<UseAllPoolsData[]> => {
  const sdk = getSDK();
  const provider = sdk
    .getContractAddress('PoolAddressesProvider')
    .toLowerCase();
  return useQuery(
    ['useAllPoolsData'],
    async () => {
      const poolsData = await sdk
        .getUiPoolDataProviderV3Abi()
        .getReservesData(provider);

      const formattedPoolsData: UseAllPoolsData[] = poolsData[0].map(
        (item) => ({
          tokenAddress: item[2] === 'wzkCRO' ? MOCK_ZKCRO_ADDRESS : item[0],
          label: item[2] === 'wzkCRO' ? 'Cronos zkEVM' : item[1],
          symbol: item[2] === 'wzkCRO' ? 'zkCRO' : item[2],
          aTokenAddress: item[17],
          variableDebtToken: item[18],
          baseLTVasCollateral: new Fraction(item[4]).shr(4),
          reserveLiquidationThreshold: new Fraction(item[5]).shr(4),
          reserveLiquidationBonus: new Fraction(item[6]).shr(4),
          reserveFactor: new Fraction(item[7]).shr(4),
          borrowCap: new Fraction(item[37]),
          supplyCap: new Fraction(item[38]),
          availableLiquidity: new Fraction(item[20]).shr(Number(item[3])),
          kink: Number(new Fraction(item[27]).shr(27).toFixed(2)),
          baseVariableBorrowRate: Number(
            new Fraction(item[26]).shr(27).toFixed(2),
          ),
          variableSlopeRate1: Number(new Fraction(item[24]).shr(27).toFixed(2)),
          variableSlopeRate2: Number(new Fraction(item[25]).shr(27).toFixed(2)),
          decimals: Number(item[3]),
        }),
      );
      return formattedPoolsData;
    },
    { refetchInterval: RefetchInterval.Normal },
  );
};

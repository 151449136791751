export enum RefetchInterval {
  VeryFast = 2_000,
  // 6 seconds
  Fast = 6_000,
  // 10 seconds
  Normal = 10_000,
  // 1 minutes
  Slow = 60_000,
}

export const TOP_NAV_HEIGHT = 68;
export const TOP_NAV_HEIGHT_DESKTOP = 104;
export const MAX_WIDTH = 1170;

import { HStack, Text } from '@chakra-ui/react';
import {
  SupplyAndBorrowType,
  useSupplyAndBorrowModal,
} from '@ui/components/SupplyAndBorrowModal';

import { TokenSelect } from './TokenSelect';

export const TokenNotSelected = () => {
  const type = useSupplyAndBorrowModal((s) => s.type);
  return (
    <HStack pt={8} justify={'space-between'}>
      <Text textStyle={'body2'}>
        {type === SupplyAndBorrowType.Supply
          ? 'Select collateral to supply'
          : 'Select token to borrow'}
      </Text>
      <TokenSelect />
    </HStack>
  );
};

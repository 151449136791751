import type { WalletConfig } from '@amply-app/wallet';
import type { FlexProps } from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/react';
import Image from 'next/image';
import type { FC } from 'react';

type WalletCardProps = {
  walletConfig: WalletConfig;
  onClick: () => void;
} & FlexProps;

export const WalletCard: FC<WalletCardProps> = ({
  walletConfig: { name, icon, title },
  ...rest
}) => {
  return (
    <Flex
      py={2}
      cursor="pointer"
      alignItems="center"
      key={name}
      gap={4}
      {...rest}
    >
      <Image src={icon} width={32} height={32} alt={title} />
      <Text>{title}</Text>
    </Flex>
  );
};

import { Amplified } from '@amply-app/icons';
import { Box, HStack, Text } from '@chakra-ui/react';

export const Boosted = () => {
  return (
    <Box
      bg={'primary.yellow'}
      borderRadius={'17px'}
      gap={'4px'}
      px="6px"
      py="2px"
    >
      <HStack>
        <Amplified />
        <Text color={'primary.h2_gray'} textStyle={'captionBold'}>
          {'Amplified'}
        </Text>
      </HStack>
    </Box>
  );
};

import {
  currentWallet,
  RABBY_WALLET_TITLE,
  rabbyWalletIcon,
} from '@amply-app/wallet';
import type { ImageProps } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { useWalletModal } from '@ui/components/Wallet';

type CurrentWalletIconProps = Omit<ImageProps, 'src' | 'alt'>;

export const CurrentWalletIcon: React.FC<CurrentWalletIconProps> = (props) => {
  const currentWalletName = useWalletModal((s) => s.currentWalletName);
  const walletConfig = currentWallet.useWalletConfig();

  return (
    <Image
      src={
        currentWalletName === RABBY_WALLET_TITLE
          ? rabbyWalletIcon
          : walletConfig.icon
      }
      alt={walletConfig.title}
      width="24px"
      height="24px"
      {...props}
    />
  );
};

CurrentWalletIcon.displayName = 'CurrentWalletIcon';

import { Box, Flex, Text } from '@chakra-ui/react';
import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';
import { Num, Percent } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import {
  SupplyAndBorrowType,
  useSupplyAndBorrowModal,
} from '@ui/components/SupplyAndBorrowModal';
import type { UseAllPoolsData } from '@ui/hooks/data';
import {
  useBorrowAPY,
  useBorrowCap,
  useSupplyAPY,
  useSupplyCap,
  useTokenBalance,
  useTokenPrice,
} from '@ui/hooks/data';
import { useUserReservesData } from '@ui/hooks/data/useUserReservesData';
import React, { useMemo } from 'react';

import { tokenAddressMap } from '../constants';
import { useTokenInputState } from './TokenInput';

export const BorrowTokenListItem = ({
  tokenData,
}: {
  tokenData: UseAllPoolsData;
}) => {
  const { selectedTokenData, setSelectedTokenData, setIsCollapse } =
    useTokenInputState();
  const { data: borrowAPY } = useBorrowAPY(tokenData.tokenAddress);
  const { data: useReservesData } = useUserReservesData();
  const isSelected = selectedTokenData?.symbol === tokenData.symbol;
  const asCollateral = useMemo(() => {
    const userReserveToken = useReservesData?.find(
      (item) =>
        tokenAddressMap[item.tokenAddress.toLowerCase()] === tokenData.symbol,
    );
    if (userReserveToken) {
      return userReserveToken.asCollateral;
    }
    // if the token is not in the user reserves, it means it's enabled as collateral
    return true;
  }, [useReservesData, tokenData]);
  const isBorrowCapReached = useBorrowCap(tokenData);
  const isDisabled = isBorrowCapReached;
  return (
    <Flex
      justifyContent={'space-between'}
      alignItems={'center'}
      color={'primary.white'}
      p={2}
      border={'0.5px solid'}
      borderColor={isSelected ? 'primary.cta' : 'transparent'}
      mb={2}
      _last={{
        mb: 0,
      }}
      onClick={() => {
        if (isDisabled || !setSelectedTokenData) {
          return;
        }
        setSelectedTokenData(tokenData);
        setIsCollapse?.(true);
      }}
      bg={isDisabled ? 'primary.disabled' : undefined}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      minH={'60px'}
    >
      <Box>
        <Percent value={borrowAPY} textStyle={'body3'} suffix="% APR" />
        {isBorrowCapReached && (
          <Text color={'primary.error'} textStyle={'caption'}>
            {'Borrow cap reached'}
          </Text>
        )}
      </Box>
      <Flex direction={'column'} alignItems={'end'}>
        <Flex gap={1}>
          <Text color={'primary.text2'} textStyle={'body1'}>
            {tokenData.symbol}
          </Text>
          <TokenImage symbol={tokenData.symbol} />
        </Flex>
        {asCollateral && (
          <Text color={'primary.caution'} textStyle={'caption'}>
            Enabled as collateral
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export const SupplyTokenListItem = ({
  tokenData,
}: {
  tokenData: UseAllPoolsData;
}) => {
  const { selectedTokenData, setSelectedTokenData, setIsCollapse } =
    useTokenInputState();
  const { data: balance } = useTokenBalance(tokenData.tokenAddress);
  const { data: tokenPrice } = useTokenPrice(tokenData.tokenAddress);
  const { data: supplyAPY } = useSupplyAPY(tokenData.tokenAddress);
  const isSelected = selectedTokenData?.symbol === tokenData.symbol;
  const isSupplyCapReached = useSupplyCap(tokenData);

  const isDisabled = isSupplyCapReached || balance?.isZero();
  return (
    <Flex
      justifyContent={'space-between'}
      alignItems={'center'}
      color={'primary.white'}
      p={2}
      border={'0.5px solid'}
      borderColor={isSelected ? 'primary.cta' : 'transparent'}
      mb={2}
      _last={{
        mb: 0,
      }}
      onClick={() => {
        if (isDisabled || !setSelectedTokenData) {
          return;
        }
        setSelectedTokenData(tokenData);
        setIsCollapse?.(true);
      }}
      bg={isDisabled ? 'primary.disabled' : undefined}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      minH={'60px'}
    >
      <Box>
        <Num
          value={balance}
          prefix={'Balance:'}
          textStyle={'body3'}
          decimalPlaces={4}
        />
        <UsdValue
          color={'primary.text2'}
          value={balance}
          price={tokenPrice}
          textStyle={'caption'}
          decimalPlaces={4}
        />
        {isSupplyCapReached && (
          <Text color={'primary.error'} textStyle={'caption'}>
            {'Supply cap reached'}
          </Text>
        )}
      </Box>
      <Flex direction={'column'} alignItems={'end'}>
        <Flex gap={1}>
          <Text color={'primary.text2'} textStyle={'body1'}>
            {tokenData.symbol}
          </Text>
          <TokenImage symbol={tokenData.symbol} />
        </Flex>

        <Percent
          value={supplyAPY}
          color={'primary.text2'}
          textStyle={'caption'}
          suffix="% APR"
        />
      </Flex>
    </Flex>
  );
};

const TokenListItem = ({ tokenData }: { tokenData: UseAllPoolsData }) => {
  const type = useSupplyAndBorrowModal((s) => s.type);

  return (
    <>
      {type === SupplyAndBorrowType.Borrow ? (
        <BorrowTokenListItem tokenData={tokenData} />
      ) : (
        <SupplyTokenListItem tokenData={tokenData} />
      )}
    </>
  );
};

export default TokenListItem;

import { TxStatus } from '@ui/commonStateController';
import { useClaimAllRewardsToSelf } from '@ui/hooks/data/useClaimAllRewardsToSelf';
import type { EthersError } from 'ethers';
import { useCallback } from 'react';

import { useClaimModal } from '../ClaimModal';

export const useClaimRewards = (tokenAddresses?: string[]) => {
  const mutation = useClaimAllRewardsToSelf();

  const [setTxStatus, setTxHash, setTxError] = useClaimModal((s) => [
    s.setTxStatus,
    s.setTxHash,
    s.setTxError,
  ]);
  return useCallback(async () => {
    try {
      if (!tokenAddresses || tokenAddresses.length === 0) return;

      setTxStatus(TxStatus.Loading);
      const tx = await mutation.mutateAsync({
        tokenAddresses,
      });
      setTxHash(tx?.hash ?? '');

      setTxStatus(TxStatus.Success);
    } catch (e: unknown) {
      setTxStatus(TxStatus.Failed);
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setTxError(e as EthersError);
    }
  }, [setTxStatus, mutation, tokenAddresses, setTxHash, setTxError]);
};

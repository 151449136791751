import { Box } from '@chakra-ui/react';
import { useAllPoolsData } from '@ui/hooks/data';
import React from 'react';

import { TokenCard } from '.';

export const TokenCards = () => {
  const { data: poolsData } = useAllPoolsData();
  return (
    <Box my={4}>
      {poolsData &&
        poolsData.map((tokenInfo) => (
          <TokenCard key={tokenInfo.symbol} tokenData={tokenInfo} />
        ))}
    </Box>
  );
};

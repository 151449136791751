import type { FractionIsh } from 'bi-fraction';
import { Fraction } from 'bi-fraction';
import { forwardRef, useMemo } from 'react';

import type { NumProps } from './Num';
import { Num } from './Num';

export type UsdValueProps = NumProps & {
  price?: FractionIsh;
};

export const UsdValue = forwardRef<HTMLDivElement, UsdValueProps>(
  ({ price, value, suffix = 'USD', ...rest }, ref) => {
    const n = useMemo(() => {
      if (value === undefined || price === undefined) return undefined;
      const v = new Fraction(value);
      const p = new Fraction(price);

      return v.mul(p);
    }, [price, value]);

    return <Num suffix={suffix} value={n} ref={ref} {...rest} />;
  },
);

UsdValue.displayName = 'UsdValue';

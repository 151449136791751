import { GoogleAnalytics } from './GoogleAnalytics';
import { GoogleTagManager } from './GoogleTagManager';
import { VConsole } from './VConsole';

export const Scripts = () => (
  <>
    <GoogleTagManager />
    <GoogleAnalytics />
    <VConsole />
  </>
);

import { JsonRpcProvider } from 'ethers';
import { getEnsAddress } from '../config';
export function createProvider(providerOptions) {
    const { chainName, chainId, rpcUrl, ensAddress } = providerOptions;
    return new JsonRpcProvider(rpcUrl, {
        name: chainName,
        chainId,
        ensAddress: ensAddress ?? getEnsAddress(chainId),
    });
}
export function createWsProvider(providerOptions) {
    const { chainName, chainId, wsUrl, ensAddress } = providerOptions;
    if (!wsUrl)
        throw new Error(`must wsUrl wsUrl`);
    return new JsonRpcProvider(wsUrl, {
        name: chainName,
        chainId,
        ensAddress: ensAddress ?? getEnsAddress(chainId),
    });
}
const providerMap = new Map();
export function getProvider(providerOptions) {
    const chainId = providerOptions.chainId;
    if (!providerMap.get(chainId)) {
        const provider = createProvider(providerOptions);
        providerMap.set(chainId, provider);
    }
    // eslint-disable-next-line
    // @ts-expect-error
    return providerMap.get(chainId);
}

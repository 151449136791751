import { tokenMap } from '@ui/components/TokenInput/constants';
import { Fraction } from 'bi-fraction';

import { useAllPoolsData } from './useAllPoolsData';
import { useTokenPrices } from './useUsdPrices';

const ZKCRO_FOR_GAS_FEE = new Fraction(3);

const useReservedGasFee = () => {
  const { data: tokenData } = useAllPoolsData();
  const { data: prices } = useTokenPrices(
    tokenData!.map((token) => token.tokenAddress),
  );
  if (!tokenData || !prices) {
    return;
  }
  const zkCROIndex = tokenData.findIndex(
    (token) => token.symbol === tokenMap.zkCRO,
  );
  const gasFeeUSD = prices[zkCROIndex].mul(ZKCRO_FOR_GAS_FEE);
  return tokenData.reduce<Record<string, Fraction>>((pre, cur, curIndex) => {
    if (curIndex === zkCROIndex) {
      pre[cur.symbol] = ZKCRO_FOR_GAS_FEE;
    } else {
      const tokenPrice = prices[curIndex];
      const tokenBalance = gasFeeUSD.div(tokenPrice);
      pre[cur.symbol] = tokenBalance;
    }
    return pre;
  }, {});
};

export default useReservedGasFee;

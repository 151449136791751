import { useQuery } from '@tanstack/react-query';
import {
  NUM_OF_DAYS_MAP,
  type TimeHorizons,
} from '@ui/components/Markets/IndividualMarket/Panels/constants';
import { isBefore } from 'date-fns';
import { create } from 'zustand';

export interface ReserveInfoResponse {
  timestamp: string;
  variableBorrowAPY: number;
  depositAPY: number;
  totalBorrowUsd: number;
  totalSupplyUsd: number;
  id: string;
}

interface UseReserveHistoryStore {
  window: TimeHorizons;
  setWindow: (window: TimeHorizons) => void;
}

export const useReserveHistoryStore = create<UseReserveHistoryStore>((set) => ({
  window: '1w',
  setWindow: (window) => {
    set({ window });
  },
}));

export default function useReserveHistory(symbol: string) {
  const window = useReserveHistoryStore((state) => state.window);
  return useQuery(
    ['useReserveHistory', symbol, window],
    async () => {
      const apyResponse = await fetch(
        process.env.NEXT_PUBLIC_API_URL + '/reserve-history/symbol/' + symbol,
      ).then((res) => res.json());
      return apyResponse
        .filter((info: ReserveInfoResponse) =>
          isBefore(
            new Date().setDate(new Date().getDate() - NUM_OF_DAYS_MAP[window]),
            new Date(info.timestamp),
          ),
        )
        .map((info: ReserveInfoResponse) => ({
          ...info,
          borrowApyPercent: info.variableBorrowAPY * 100,
          supplyApyPercent: info.depositAPY * 100,
          timestamp: new Date(info.timestamp),
        }));
    },
    {
      enabled: !!symbol,
      refetchInterval: 20000,
    },
  );
}

import { useSupplyAndBorrowModal } from '@ui/components/SupplyAndBorrowModal';
import { Fraction } from 'bi-fraction';
import { useMemo } from 'react';

import type { UseAllPoolsData } from '../data';
import { useSupplyCap, useTokenBalance } from '../data';
import { checkWalletBalance } from './utils';

export const useValidationSupply = (
  supplyAmount: Fraction,
  tokenData?: UseAllPoolsData,
): string | undefined => {
  const { data: walletTokenBalance } = useTokenBalance(tokenData?.tokenAddress);
  const isSupplyCapReached = useSupplyCap(tokenData, supplyAmount);

  const setSupplyAndBorrowModal = (msg: string) => {
    useSupplyAndBorrowModal.setState(() => ({
      errorMsg: msg,
    }));
  };
  return useMemo(() => {
    if (!walletTokenBalance || !tokenData || supplyAmount.lte(Fraction.ZERO)) {
      setSupplyAndBorrowModal('no data');
      return;
    }
    const walletBalanceError = checkWalletBalance(
      supplyAmount,
      walletTokenBalance,
    );
    if (walletBalanceError) {
      setSupplyAndBorrowModal(walletBalanceError);
      return walletBalanceError;
    }
    if (isSupplyCapReached) {
      setSupplyAndBorrowModal('supply cap reached');
      return 'supply cap reached';
    }

    setSupplyAndBorrowModal('');
    return undefined;
  }, [supplyAmount, tokenData, walletTokenBalance, isSupplyCapReached]);
};

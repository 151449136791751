import type { EthersError } from 'ethers';
import type { StateCreator } from 'zustand';

export enum TxStatus {
  Default,
  Loading,
  Success,
  Failed,
}

export interface TxStatusController {
  txStatus: TxStatus;
  txHash?: string;
  txError?: EthersError;
  setTxStatus: (txStats: TxStatus) => void;
  setTxHash: (txHash?: string) => void;
  setTxError: (txError?: EthersError) => void;
}

export type TxStatusOverride = Pick<
  TxStatusController,
  'txStatus' | 'txError' | 'txHash'
>;

export const createTxStatusController: StateCreator<TxStatusController> = (
  set,
) => ({
  txStatus: TxStatus.Default,

  setTxStatus(status) {
    set({ txStatus: status });
  },
  setTxHash(txHash) {
    set({ txHash });
  },
  setTxError(txError) {
    set({ txError });
  },
});

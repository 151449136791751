import { ExpandDown, Question } from '@amply-app/icons';
import { Box, Flex, Icon, Text, VStack } from '@chakra-ui/react';
import type { APRChartData } from '@ui/components/AmplyCharts/types';
import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';
import { Num, Percent } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import { Info } from '@ui/components/TextBlocks';
import type { Fraction } from 'bi-fraction';
import React, { useState } from 'react';

import InfoTooltip from '../../InfoTooltip';
import { Chart } from './Chart';

interface IncentiveDetailsProps {
  symbol: string;
  apy?: Fraction;
}
interface Props {
  type: 'supply' | 'borrow';
  data: {
    symbol: string;
    chartData: APRChartData;
    tokenAPY?: Fraction;
    netAPY?: Fraction;
    incentiveDetails?: IncentiveDetailsProps[];
    tokenAmount?: Fraction;
    tokenUSDValue?: Fraction;
    utilization?: Fraction;
    isCapReached?: boolean;
  };
}

export const Information = ({ data, type }: Props) => {
  const {
    chartData,
    symbol,
    tokenAPY,
    netAPY,
    incentiveDetails,
    tokenAmount,
    tokenUSDValue,
    utilization,
    isCapReached,
  } = data;
  const [isExpanded, setIsExpanded] = useState(false);
  const isSupply = type === 'supply';

  return (
    <Box>
      <Flex direction={'column'} gap={4}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Text textStyle={'body3'}>{isSupply ? 'Supply' : 'Borrow'} APR</Text>
          <Chart data={chartData} />
          <Box>
            <Flex>
              <Percent value={netAPY} textStyle={'body1Bold'} />
              <Icon
                as={ExpandDown}
                color={'primary.cta'}
                w={6}
                h={6}
                cursor={'pointer'}
                transform={isExpanded ? 'rotate(180deg)' : 'rotate(0)'}
                transition={'all 0.15s'}
                onClick={() => setIsExpanded(!isExpanded)}
              />
            </Flex>
          </Box>
        </Flex>
        {isExpanded && (
          <VStack alignItems={'flex-end'}>
            <Percent
              value={tokenAPY}
              textStyle={'body1'}
              justifyContent={'flex-end'}
              mt={-2}
              prefix={<Text>{'Base:'} &nbsp;</Text>}
            />
            {incentiveDetails?.map((details) => {
              return (
                <Percent
                  key={details.symbol}
                  value={details.apy}
                  gap={'2px'}
                  textStyle={'body1'}
                  prefix={
                    <>
                      <TokenImage
                        w={'16px'}
                        h={'16px'}
                        mr={'5px'}
                        symbol={details.symbol}
                      />
                      <Text> ${details.symbol}:</Text>
                    </>
                  }
                />
              );
            })}
          </VStack>
        )}

        <Info
          title={
            <Text textStyle={'body3'}>
              Total {isSupply ? 'Supply' : 'Borrow'}
            </Text>
          }
          content={
            <>
              <Num value={tokenAmount} textStyle={'body1Bold'} />{' '}
              <TokenImage symbol={symbol} w="22px" />
            </>
          }
          usdContent={<UsdValue value={tokenAmount} price={tokenUSDValue} />}
        />

        <Info
          title={
            <>
              <Text textStyle={'body3'}>{'Utilization'}</Text>
              <InfoTooltip label="The percentage of assets lent out to borrowers" />
            </>
          }
          content={<Percent value={utilization} textStyle={'body1Bold'} />}
        />

        {isCapReached ? (
          <Flex justifyContent={'center'} color={'primary.error'} gap={2}>
            <Icon as={Question} color={'primary.error'} w={6} h={6} />
            <Text textStyle={'body3'}>
              {isSupply ? 'Supply' : 'Borrow'} Cap reached
            </Text>
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
};

export const colors = [
  'linear-gradient(180deg, #FF7878 0%, #E43030 100%)',
  '#FF7878',
  ...new Array(2).fill('#FFA078'),
  ...new Array(2).fill('linear-gradient(180deg, #E2FB00 0%, #FA9702 100%)'),
  'linear-gradient(180deg, #EBFF38 0%, #FFB23F 100%)',
  'linear-gradient(180deg, #EEFF54 0%, #FFCB7C 100%);',
  'linear-gradient(180deg, #FDF3A7 0%, #FFD699 100%)',
  ...new Array(4).fill('#FDF3A7'),
  'linear-gradient(180deg, #FDF3A7 0%, #BFA 100%)',
  'linear-gradient(180deg, #FDF3A7 0%, #98FF7E 100%)',
  '#98FF7E',
];

export const colorsEnd = [
  'linear-gradient(180deg, #98FF7E 0%, #8EDE79 100%)',
  ...new Array(4).fill('linear-gradient(180deg, #98FF7E 0%, #6DB05C 100%)'),
];
export const backgrounds = colors.map((color, index) => ({
  key: `${color}_${index}`,
  bg: color,
}));
export const backgroundsEnd = colorsEnd.map((color, index) => ({
  key: `${color}_${index}`,
  bg: color,
}));

export const longBarIndexes = [0, 15];
export const minHealthFactor = 1;
export const maxHealthFactor = 3;
export const healthFactorPointerConfig = [
  {
    threshold: 1,
    leftShift: 0,
    color: 'linear-gradient(180deg, #FF7878 0%, #E43030 100%)',
  },
  { threshold: 3, leftShift: '70px', color: '#FDF3A7' },
  { threshold: 10, leftShift: '75%', color: '#98FF7E' },
  { threshold: Infinity, leftShift: '100%', color: '#98FF7E' },
];

import TokenImage from '@ui/components/Markets/IndividualMarket/TokenImage';
import { Num } from '@ui/components/Num';
import { UsdValue } from '@ui/components/Num/UsdValue';
import { Info } from '@ui/components/TextBlocks';
import type { UseAllPoolsData} from '@ui/hooks/data';
import { useTokenPrice } from '@ui/hooks/data';
import type { Fraction } from 'bi-fraction';

interface BalanceDetailsPops {
  pool: UseAllPoolsData;
  balance?: Fraction;
}
export const BalanceDetails = ({ pool, balance }: BalanceDetailsPops) => {
  const { data: tokenPrice } = useTokenPrice(pool.tokenAddress);
  return (
    <Info
      key={pool.symbol}
      title={pool.symbol}
      content={
        <>
          <Num value={balance} />
          <TokenImage symbol={pool.symbol} />
        </>
      }
      usdContent={<UsdValue value={balance} price={tokenPrice} />}
    />
  );
};

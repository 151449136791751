import { useReservesData } from '@ui/components/Dashboard/Overview';
import { Fraction } from 'bi-fraction';

import { useTokensBorrowAPY, useTokensSupplyAPY } from './useAPY';
import { useUserTotalSuppliedUSD } from './useUserTotalBalanceInUSD';

export const useWeightedAverageAPY = () => {
  // ((positionBalanceUSD * positionAPY) / totalBalanceUSD)
  const { data: userReservesData } = useReservesData();
  const tokens = userReservesData?.map((item) => item.tokenAddress);
  const totalSuppliedUSD = useUserTotalSuppliedUSD();

  const { data: supplyTokensAPY } = useTokensSupplyAPY(tokens);
  const { data: borrowTokensAPY } = useTokensBorrowAPY(tokens);

  if (
    !userReservesData ||
    !totalSuppliedUSD ||
    totalSuppliedUSD.eq(Fraction.ZERO) ||
    !supplyTokensAPY ||
    !borrowTokensAPY
  )
    return undefined;

  const totalSupplyTokensAPY = userReservesData.reduce(
    (accumulator, item, index) =>
      accumulator.add(
        item.aTokenBalance.mul(item.tokenUSDPrice).mul(supplyTokensAPY[index]),
      ),
    Fraction.ZERO,
  );

  const totalBorrowTokensAPY = userReservesData.reduce(
    (accumulator, item, index) =>
      accumulator.add(
        item.vDebtTokenBalance
          .mul(item.tokenUSDPrice)
          .mul(borrowTokensAPY[index]),
      ),
    Fraction.ZERO,
  );
  const apy = totalSupplyTokensAPY
    .sub(totalBorrowTokensAPY)
    .div(totalSuppliedUSD);
  return apy;
};

import { ExpandLeft } from '@amply-app/icons';
import {
  Button,
  Flex,
  Icon,
  ModalCloseButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import type { UseAllPoolsData } from '@ui/hooks/data';
import { Fraction } from 'bi-fraction';
import { useCallback, useState } from 'react';
import { create } from 'zustand';

import type { EthersError } from '../CommonContents';
import {
  TransactionErrorContent,
  TransactionLoadingContent,
} from '../CommonContents';
import { InDesktop } from '../MobileOrDesktop';
import {
  ModalOrDragDrawerContent,
  ModalOrDrawer,
  ModalOrDrawerBody,
  ModalOrDrawerFooter,
  ModalOrDrawerHeader,
  ModalOrDrawerOverlay,
} from '../ModalOrDrawer';
import { Borrow } from './Borrow';
import { useBorrow } from './Borrow/useBorrow';
import { BorrowAndSupply } from './BorrowAndSupply';
import { DefaultContent } from './DefaultContent';
import { Paymaster, usePaymaster } from './Paymaster';
import { SuccessContent } from './SuccessContent';
import { Supply } from './Supply';
import { useSupply } from './Supply/useSupply';

export enum SupplyAndBorrowType {
  Selection = 'Selection',
  Supply = 'Supply',
  Borrow = 'Borrow',
  BorrowAndSupply = 'BorrowAndSupply',
}

export enum TxStatus {
  Default,
  Loading,
  Success,
  Failed,
}

interface UseSupplyAndBorrowModal {
  isOpen: boolean;
  type: SupplyAndBorrowType;
  borrowTokenData?: UseAllPoolsData;
  supplyTokenData?: UseAllPoolsData;
  borrowAmountStr: string;
  supplyAmountStr: string;
  borrowAmount?: Fraction;
  supplyAmount?: Fraction;

  // Form Validation Error
  errorMsg?: string;

  status: TxStatus;
  txHash?: string;
  txError?: EthersError;

  isMaxAmount: boolean;
  isBorrowRiskAgreed: boolean;

  closeModal: () => void;
  openModal: () => void;
  setBorrowAmountStr: (amount: string) => void;
  setSupplyAmountStr: (amount: string) => void;
  setBorrowTokenData: ((token: UseAllPoolsData) => void) | undefined;
  setSupplyTokenData: ((token: UseAllPoolsData) => void) | undefined;

  setTxStatus: (txStats: TxStatus) => void;
  setTxHash: (txHash: string) => void;
  setTxError: (txError: EthersError) => void;

  setMaxAmount: (isMaxAmount: boolean) => void;
  setIsBorrowRiskAgreed: (isBorrowRiskAgreed: boolean) => void;
}

export const useSupplyAndBorrowModal = create<UseSupplyAndBorrowModal>(
  (set) => ({
    isOpen: false,
    status: TxStatus.Default,
    type: SupplyAndBorrowType.Selection,
    errorMsg: '',
    isMaxAmount: false,
    isBorrowRiskAgreed: false,
    closeModal: () => {
      set({
        isOpen: false,
        borrowTokenData: undefined,
        supplyTokenData: undefined,
        type: SupplyAndBorrowType.Selection,
        borrowAmount: undefined,
        supplyAmount: undefined,
        borrowAmountStr: '',
        supplyAmountStr: '',
        errorMsg: undefined,
        txHash: undefined,
        status: TxStatus.Default,
        isMaxAmount: false,
        isBorrowRiskAgreed: false,
        setBorrowTokenData: (borrowTokenData) => {
          set({ borrowTokenData });
        },
        setSupplyTokenData: (supplyTokenData) => {
          set({ supplyTokenData });
        },
      });
    },
    openModal: () => {
      set({ isOpen: true });
    },
    supplyAmountStr: '',
    borrowAmountStr: '',
    setBorrowAmountStr: (borrowAmountStr) => {
      set({
        borrowAmountStr,
        borrowAmount: new Fraction(borrowAmountStr),
      });
    },
    setSupplyAmountStr: (supplyAmountStr) => {
      set({
        supplyAmountStr,
        supplyAmount: new Fraction(supplyAmountStr),
      });
    },
    setBorrowTokenData: (borrowTokenData) => {
      set({ borrowTokenData });
    },
    setSupplyTokenData: (supplyTokenData) => {
      set({ supplyTokenData });
    },
    setTxStatus: (status) => {
      set({ status });
    },
    setTxHash: (txHash) => {
      set({ txHash });
    },
    setTxError: (txError) => {
      set({ txError });
    },
    setMaxAmount(isMaxAmount) {
      set({ isMaxAmount });
    },
    setIsBorrowRiskAgreed(isBorrowRiskAgreed) {
      set({ isBorrowRiskAgreed });
    },
  }),
);

const getModalTitle = (type: SupplyAndBorrowType) => {
  switch (type) {
    case SupplyAndBorrowType.Selection:
      return 'Supply & Borrow';
    case SupplyAndBorrowType.Supply:
      return 'Supply';
    case SupplyAndBorrowType.Borrow:
      return 'Borrow';
    case SupplyAndBorrowType.BorrowAndSupply:
      return 'Borrow & Supply';
  }
};

const getCTAText = (type: SupplyAndBorrowType, status: TxStatus) => {
  if (status === TxStatus.Failed) return 'Close';
  if (status === TxStatus.Success) return 'Done';

  switch (type) {
    case SupplyAndBorrowType.Supply:
      return 'Supply';
    case SupplyAndBorrowType.Borrow:
    case SupplyAndBorrowType.BorrowAndSupply:
      return 'Borrow';
  }
};

const SupplyAndBorrowModal = () => {
  const [isOpen, closeModal, errorMsg, type, status] = useSupplyAndBorrowModal(
    (s) => [s.isOpen, s.closeModal, s.errorMsg, s.type, s.status],
  );

  const isPaymasterError = usePaymaster((s) => s.isError);

  const supply = useSupply();
  const borrow = useBorrow();

  const [isCollapse] = useState(false);

  const getTxContent = useCallback(() => {
    switch (status) {
      case TxStatus.Loading:
        return <TransactionLoadingContent />;
      case TxStatus.Failed:
        return <TransactionErrorContent error={null} />;
      case TxStatus.Success:
        return <SuccessContent />;
      default:
        return null;
    }
  }, [status]);

  const handleCTAClick = useCallback(() => {
    if (status === TxStatus.Failed || status === TxStatus.Success) {
      closeModal();
    } else if (type === SupplyAndBorrowType.Supply) {
      supply();
    } else if (type === SupplyAndBorrowType.Borrow) {
      borrow();
    } else if (type === SupplyAndBorrowType.BorrowAndSupply) {
      // TODO: borrowAndSupply();
      console.log('borrowAndSupply');
    }
  }, [status, type, supply, borrow, closeModal]);

  if (isCollapse) return null;

  return (
    <ModalOrDrawer
      scrollBehavior="inside"
      placement="bottom"
      isOpen={isOpen}
      onClose={() => closeModal()}
      size={{
        base: type !== SupplyAndBorrowType.Selection ? 'full' : 'none',
        desktop: 'lg',
      }}
    >
      <ModalOrDrawerOverlay />
      <ModalOrDragDrawerContent
        roundedTop="10px"
        bgColor={'primary.background'}
        backgroundImage={'url(/images/bg-full.svg)'}
        backgroundSize={'cover'}
        backgroundPosition={'center'}
      >
        <ModalOrDrawerHeader pb={2} textStyle="body3">
          <Flex alignItems="center" justifyContent="center" position="relative">
            {type !== SupplyAndBorrowType.Selection &&
              status === TxStatus.Default && (
                <Button
                  variant={'unstyled'}
                  position={'absolute'}
                  left={0}
                  onClick={() =>
                    useSupplyAndBorrowModal.setState((s) => ({
                      ...s,
                      type: SupplyAndBorrowType.Selection,
                    }))
                  }
                  h={6}
                  w={6}
                >
                  <Icon as={ExpandLeft} boxSize={6} color={'primary.cta'} />
                </Button>
              )}
            <Text>{getModalTitle(type)}</Text>
          </Flex>
        </ModalOrDrawerHeader>
        <InDesktop>
          <ModalCloseButton
            color={'primary.cta'}
            boxSize={6}
            top={'26px'}
            right={'24px'}
          />
        </InDesktop>
        <ModalOrDrawerBody
          mt="10px"
          px={4}
          minH="320px"
          color="primary.white"
          zIndex={1}
        >
          {status === TxStatus.Default ? (
            <>
              {type === SupplyAndBorrowType.Selection && <DefaultContent />}
              {type === SupplyAndBorrowType.Supply && <Supply />}
              {type === SupplyAndBorrowType.Borrow && <Borrow />}
              {type === SupplyAndBorrowType.BorrowAndSupply && (
                <BorrowAndSupply />
              )}
            </>
          ) : (
            getTxContent()
          )}
        </ModalOrDrawerBody>
        <ModalOrDrawerFooter
          bg={
            type !== SupplyAndBorrowType.Selection
              ? 'primary.background'
              : undefined
          }
          mt={4}
          position={{ base: 'fixed', desktop: 'unset' }}
          bottom={0}
          zIndex={10}
          w={'100%'}
        >
          {type !== SupplyAndBorrowType.Selection && (
            <Stack w={'full'} spacing={4}>
              {status === TxStatus.Default && <Paymaster type={type} />}
              <Button
                onClick={handleCTAClick}
                isLoading={status === TxStatus.Loading}
                isDisabled={!!errorMsg || isPaymasterError}
              >
                {getCTAText(type, status)}
              </Button>
            </Stack>
          )}
        </ModalOrDrawerFooter>
      </ModalOrDragDrawerContent>
    </ModalOrDrawer>
  );
};

export default SupplyAndBorrowModal;

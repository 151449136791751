import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { RefetchInterval } from '@ui/config/ui';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

export const useTokenSupply = (
  aTokenAddress?: string,
): UseQueryResult<Fraction> => {
  const sdk = getSDK();
  return useQuery(
    ['useTokenSupply', aTokenAddress],
    async () => {
      const tokenSupply = await sdk.getAToken(aTokenAddress!).totalSupply();
      const tokenDecimal = await sdk.getAToken(aTokenAddress!).decimals();

      return new Fraction(tokenSupply).shr(Number(tokenDecimal));
    },
    { enabled: !!aTokenAddress, refetchInterval: RefetchInterval.Normal },
  );
};

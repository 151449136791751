import { create } from 'zustand';

interface OnboardingModalStore {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const useOnboardingModal = create<OnboardingModalStore>((set) => ({
  isOpen: false,
  openModal: () => {
    set({
      isOpen: true,
    });
  },
  closeModal: () => {
    set({
      isOpen: false,
    });
  },
}));

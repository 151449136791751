import { currentWallet } from '@amply-app/wallet';
import { useQuery } from '@tanstack/react-query';
import { HEALTH_FACTOR_UNIT, LTV_UNIT } from '@ui/config/sc';
import { RefetchInterval } from '@ui/config/ui';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

export interface UserAccountData {
  totalCollateralBase: Fraction;
  totalDebtBase: Fraction;
  availableBorrowsBase: Fraction;
  currentLiquidationThreshold: Fraction;
  maxLTV: Fraction;
  healthFactor: Fraction;
}

export const useUserAccountData = () => {
  const sdk = getSDK();
  const account = currentWallet.useAccount();

  return useQuery(
    ['useUserAccountData', account],
    async () => {
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();
      const data = await sdk
        .getL2Pool(poolAddress)
        .getUserAccountData(account!);

      const baseCurrencyUint = await sdk.getAmplyOracle().BASE_CURRENCY_UNIT();
      const decimals = new Fraction(baseCurrencyUint);

      const formattedData: UserAccountData = {
        totalCollateralBase: new Fraction(data[0]).div(decimals),
        totalDebtBase: new Fraction(data[1]).div(decimals),
        availableBorrowsBase: new Fraction(data[2]).div(decimals),
        currentLiquidationThreshold: new Fraction(data[3]).shr(LTV_UNIT),
        maxLTV: new Fraction(data[4]).shr(LTV_UNIT),
        healthFactor: new Fraction(data[5]).shr(HEALTH_FACTOR_UNIT),
      };

      return formattedData;
    },
    { enabled: !!account, refetchInterval: RefetchInterval.Normal },
  );
};

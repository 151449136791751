import type {
  ModalController,
  ModalOverride,
  TxStatusController,
  TxStatusOverride,
} from '@ui/commonStateController';
import {
  createModalController,
  createTxStatusController,
  TxStatus,
} from '@ui/commonStateController';
import type { UseAllPoolsData } from '@ui/hooks/data';
import { useTokenBalance } from '@ui/hooks/data';
import { MAX_WITHDRAWAL_HF } from '@ui/hooks/data/constants';
import { useUserAccountData } from '@ui/hooks/data/useUserAccountData';
import { isHealthFactorInWarning } from '@ui/hooks/data/utils';
import { Fraction } from 'bi-fraction';
import { useEffect, useMemo } from 'react';
import type { StateCreator } from 'zustand';
import { create } from 'zustand';

import { type UseWithdrawAndRepayModal, WithdrawOrRepay } from './types';

const createOverride: StateCreator<
  UseWithdrawAndRepayModal & ModalOverride & TxStatusOverride
> = (set) => ({
  isCollateral: false,
  isMaxAmount: false,
  type: WithdrawOrRepay.Withdraw,

  setMaxAmount(isMaxAmount) {
    set({ isMaxAmount });
  },
  setTokenSymbol(token) {
    set({ tokenSymbol: token });
  },
  setTokenAddress(tokenAddress) {
    set({ tokenAddress });
  },
  setAmountStr(amountStr) {
    set({ amountStr, amount: new Fraction(amountStr) });
  },
  setIsCollateral(isCollateral) {
    set({ isCollateral });
  },
  setType(type) {
    set({ type });
  },
  setTokenBalance(tokenBalance) {
    set({ tokenBalance });
  },
  setTokenUSDPrice(tokenUSDPrice) {
    set({ tokenUSDPrice });
  },
  setInputError(inputError) {
    set({ inputError });
  },
  setInputWarning(inputWarning) {
    set({ inputWarning });
  },

  isOpen: false,
  txStatus: TxStatus.Default,
  close() {
    set({
      txStatus: TxStatus.Default,
      isOpen: false,
      txError: undefined,
      txHash: undefined,
      inputError: undefined,
      amountStr: undefined,
      isAcknowledged: undefined,
    });
  },
});

export const useWithdrawAndRepayModal = create<
  ModalController & TxStatusController & UseWithdrawAndRepayModal
>()((...a) => ({
  ...createModalController(...a),
  ...createTxStatusController(...a),
  ...createOverride(...a),
}));

export const useMaxWithdrawTokens = (
  isCollateral: boolean,
  aTokenBalance?: Fraction,
  tokenUSDPrice?: Fraction,
) => {
  const { data } = useUserAccountData();

  const maxTokens = useMemo(() => {
    const totalCollateralBase = data?.totalCollateralBase;
    const totalDebtBase = data?.totalDebtBase;
    const currentLTV = data?.currentLiquidationThreshold;
    if (
      totalDebtBase?.eq(Fraction.ZERO) ||
      !currentLTV ||
      !totalDebtBase ||
      !totalCollateralBase ||
      !tokenUSDPrice ||
      !aTokenBalance
    )
      return aTokenBalance;

    // HF = SUM[(Collateral USD * current LTV)] / SUM(debt USD)
    // MAX = max withdrawal amount that makes HF = 1.0
    const maxWithdrawTokensUSD = currentLTV.gt(0)
      ? totalCollateralBase.sub(
          totalDebtBase.mul(MAX_WITHDRAWAL_HF).div(currentLTV),
        )
      : Fraction.ZERO;
    const maxWithdrawTokens = maxWithdrawTokensUSD.gte(
      aTokenBalance.mul(tokenUSDPrice),
    )
      ? aTokenBalance
      : maxWithdrawTokensUSD.div(tokenUSDPrice);

    return isCollateral ? maxWithdrawTokens : aTokenBalance;
  }, [data, tokenUSDPrice, aTokenBalance, isCollateral]);
  return maxTokens;
};

export const useWithdrawAndRepayMsg = ({
  isWithdraw,
  isCollateral,
  healthFactorAfterWithdraw,
  healthFactorAfterRepay,
  amount,
  aAndVTokenBalance,
  maxWithdrawTokens,
  tokenData,
}: {
  isWithdraw: boolean;
  isCollateral: boolean;
  healthFactorAfterWithdraw: Fraction;
  healthFactorAfterRepay: Fraction;
  amount?: string;
  aAndVTokenBalance?: Fraction;
  maxWithdrawTokens?: Fraction;
  tokenData?: UseAllPoolsData;
}) => {
  const [tokenAddress, setErrorMsg, setWarningMsg] = useWithdrawAndRepayModal(
    (s) => [s.tokenAddress, s.setInputError, s.setInputWarning],
  );
  const { data: userWalletBalance } = useTokenBalance(tokenAddress);

  const isGtMaxWithdraw = new Fraction(amount ?? Fraction.ZERO).gt(
    maxWithdrawTokens ?? Fraction.ZERO,
  );

  useEffect(() => {
    if (!amount) {
      setWarningMsg(undefined);
      setErrorMsg(undefined);
      return;
    }

    // set the warning msg
    if (
      (isWithdraw && isHealthFactorInWarning(healthFactorAfterWithdraw)) ||
      (!isWithdraw && isHealthFactorInWarning(healthFactorAfterRepay))
    ) {
      setWarningMsg(
        'To keep your Health Factor above 3, we recommend lowering your debt or supplying more',
      );
    } else {
      setWarningMsg(undefined);
    }

    if (isWithdraw) {
      if (!isCollateral && isGtMaxWithdraw) {
        setErrorMsg('Lower withdrawal amount to proceed');
        return;
      }
      if (isCollateral && healthFactorAfterWithdraw.lte(Fraction.ONE)) {
        setErrorMsg(
          'Borrow less or supply more to keep your Health Factor above 1',
        );
        return;
      }
      if (
        new Fraction(amount).gt(tokenData?.availableLiquidity ?? Fraction.ZERO)
      ) {
        setErrorMsg(
          `exceeds max market liquidity: ${tokenData?.availableLiquidity.toFormat(
            { decimalPlaces: 4 },
          )}`,
        );
        return;
      }
    } else {
      if (userWalletBalance && new Fraction(amount).gt(userWalletBalance)) {
        setErrorMsg('Top up your balance to repay the stated amount');
        return;
      }
    }

    if (aAndVTokenBalance && new Fraction(amount).gt(aAndVTokenBalance)) {
      setErrorMsg(
        isWithdraw
          ? 'Lower withdrawal amount to proceed'
          : 'Your amount exceeds the debt to be repaid',
      );
      return;
    }

    setErrorMsg(undefined);
  }, [
    aAndVTokenBalance,
    amount,
    healthFactorAfterRepay,
    healthFactorAfterWithdraw,
    isCollateral,
    isGtMaxWithdraw,
    isWithdraw,
    setErrorMsg,
    setWarningMsg,
    tokenData?.availableLiquidity,
    userWalletBalance,
  ]);
};

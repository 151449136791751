import type { ContractAddressMap } from '@amply-app/sdk';
import { getContractAddress } from '@amply-app/sdk';
import { currentWallet } from '@amply-app/wallet';
import { useMutation } from '@tanstack/react-query';
import { usePaymaster } from '@ui/components/SupplyAndBorrowModal/Paymaster';
import { tokenMap } from '@ui/components/TokenInput/constants';
import { getGasOverrides, getPaymasterParams, getSDK } from '@ui/utils';
import { BrowserProvider } from 'zksync-ethers';

import { useAllPoolsData } from './useAllPoolsData';

const { useAccount, useProvider, useChainId, getConnector } = currentWallet;

export const useSetUserUseReserveAsCollateral = () => {
  const account = useAccount();
  const provider = useProvider();
  const chainId = useChainId();
  const connectorProvider = getConnector().provider;
  const { data: allPoolsData } = useAllPoolsData();
  const gasTokenSymbol = usePaymaster((s) => s.token);
  const gasTokenAddress =
    allPoolsData?.find((token) => token.symbol === gasTokenSymbol)
      ?.tokenAddress ?? '';
  return useMutation(
    async ({
      tokenSymbol,
      asCollateral,
    }: {
      tokenSymbol: keyof ContractAddressMap | 'zkCRO';
      asCollateral: boolean;
    }) => {
      if (!chainId || !account || !provider || !connectorProvider) return;

      const browserSigner = await new BrowserProvider(
        connectorProvider,
      ).getSigner();
      const sdk = getSDK();

      const assetAddress = getContractAddress(
        chainId,
        tokenSymbol === 'zkCRO' ? 'wzkCRO' : tokenSymbol,
      );
      const poolAddress = await sdk.getPoolAddressesProvider().getPool();

      let overrides = {};
      if (gasTokenSymbol !== tokenMap.zkCRO) {
        const paymasterParams = getPaymasterParams({ gasTokenAddress });
        const gasLimit = await sdk
          .getL2Pool(poolAddress, browserSigner)
          ['setUserUseReserveAsCollateral(address,bool)'].estimateGas(
            assetAddress,
            asCollateral,
          );
        overrides = await getGasOverrides({ gasLimit, paymasterParams });
      }

      const tx = await sdk
        .getL2Pool(poolAddress, browserSigner)
        ['setUserUseReserveAsCollateral(address,bool)'](
          assetAddress,
          asCollateral,
          overrides,
        );

      return await tx.wait();
    },
  );
};

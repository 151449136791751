import { Text } from '@chakra-ui/react';
import { throttle } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const fpsMonitor = (report?: (fps: number, timestamp: number) => void) => {
  let before = Date.now();
  let id = requestAnimationFrame(function loop() {
    const now = Date.now();
    report?.(Math.round(1000 / (now - before)), now);
    before = now;
    id = requestAnimationFrame(loop);
  });
  return () => cancelAnimationFrame(id);
};

const FPS_BREAKPOINTS = {
  SLOW: 24,
  MID: 40,
};

export const FpsMonitor = ({
  slowDebounceDuration = 2000,
  fpsThrottleDuration = 200,
}: {
  slowDebounceDuration?: number;
  fpsThrottleDuration?: number;
}) => {
  const [fps, setFps] = useState(0);
  const throttleSetFps = useMemo(
    () => throttle(setFps, fpsThrottleDuration),
    [fpsThrottleDuration],
  );

  const slowFpsTimestamp = useRef(-1);

  const report = useCallback(
    (_fps: number, timestamp: number) => {
      if (_fps < FPS_BREAKPOINTS.SLOW) {
        if (slowFpsTimestamp.current === -1) {
          slowFpsTimestamp.current = timestamp;
        } else if (
          timestamp - slowFpsTimestamp.current >=
          slowDebounceDuration
        ) {
          slowFpsTimestamp.current = timestamp;
        }
      } else {
        slowFpsTimestamp.current = -1;
      }
      throttleSetFps(_fps);
    },
    [slowDebounceDuration, throttleSetFps],
  );

  useEffect(() => {
    fpsMonitor(report);
  }, [report]);

  return (
    <Text
      color={
        fps < FPS_BREAKPOINTS.SLOW
          ? 'red'
          : fps < FPS_BREAKPOINTS.MID
          ? 'orange'
          : 'lime'
      }
      transition="all .2s ease"
    >
      {fps} {FPS_raw}
    </Text>
  );
};

const FPS_raw = 'FPS';
